import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import Select from 'components/Select';
import Input from 'components/Input/index';
import { Button } from 'components/Button';
import mainStore from '../../stores/store';
import { view, store } from 'react-easy-state';
import DateInput from 'components/Input/DateInput';
import moment from 'moment';
import { trybyPrac } from '../../model/punkt/punktModel';
import { TextArea } from 'components/TextArea';
import { savePunkt, deletePunkt } from '../../model/punkt/savePunkt';
import Icon from '../../components/Icon/index';
import Label from './../../components/Label/index';
import { getFullTree } from '../../model/wykonanie/getFullTree';
import { getCosieks } from '../../model/cosiek/getCosieks';
import { getLudzie } from '../../model/ludzik/getLudzie';
import flattenTree from './../../util/flattenTree';
import getJMFromCosiek from './../../util/getJMFromCosiek';
import { wykonajPunkty } from './../../model/wykonanie/wykonajPunkty';
import { getWykRecord } from './../../model/wykonanie/wykonanieModel';
import { wykonajWykRecords } from './../../model/wykonanie/wykonajWykRecords';
import SmartInput from '../../components/Input/SmartInput';
import { getPozplans } from './../../model/wykonanie/getPozplans';
import RowSet from '../../components/Grid/RowSet';

export function getPT(pozplanIDs){
  return getPozplans(pozplanIDs).then((json) => {
    return json.pozplans.map((pp) => ({
      ...pp,
      children: json.parts.filter((pa) => pa.parent == pp.id).map((pa) => ({
        id: pa.id, 
        nazwas: pa.nazwas,
        nazwa: pa.nazwa,
        oryginal: pa.orginal,
        ileplan: pa.ileplan,
        jm: pa.jm
      }))
    }));
  });
}  


class PozplanView extends Component {

  wykludziefilter = (e) => this.store.pracownicy.find((pr) => pr.id==e.id) === undefined;

  constructor(props) {
    super(props);

    this.state = {
      pozplans: []
    }

    mainStore.bigLoading = true;
    getPT([props.wykNode.pozplan.id]).then((result) => {
      this.setState({
        pozplans: result
      })
      mainStore.bigLoading = false;
    }).catch((err) => {
      mainStore.bigLoading = false;
    })
  }

  static class = 'modal-medium';

  render() {
    //console.log(this.state.pozplans);
    return ( 
      <Container fill>
        {this.state.pozplans.map((pp) => 
          <div key={pp.id}>
            <RowSet caption="ID"><Label>{pp.id}</Label></RowSet>
            <RowSet caption="Nazwa"><Label>{pp.nazwa}</Label></RowSet>
            <RowSet caption="Nazwa skrócona"><Label>{pp.nazwas}</Label></RowSet>
            <RowSet caption="Nr. Raportu"><Label>{pp.raport}</Label></RowSet>
            <RowSet caption="Data Planowana"><Label>{moment(pp.dataplan).format('YYYY-MM-DD')}</Label></RowSet>
            <RowSet caption="Data Wykonania"><Label>{moment(pp.datawyk).format('YYYY-MM-DD')}</Label></RowSet>
            <RowSet caption="Czas"><Label>{pp.extra}</Label></RowSet>
            <RowSet caption="Raport"><Label>{pp.raport}</Label></RowSet>
            <RowSet caption="Info"><pre>{pp.info}</pre></RowSet>
          </div>
        )}
      </Container>
    );
  }
}

export default view(PozplanView);


