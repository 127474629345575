import React from 'react';
import { NavLink } from 'react-router-dom'

import './HeaderButton.css';
import { view } from 'react-easy-state';
import mainStore from '../../stores/store';
import { withRouter } from 'react-router';

function isActive(match, location, to){
  //console.log(location.pathname, to)
  return location.pathname === to;
}

export default withRouter(view((props) => {
  if(props.to){
    return (
      <NavLink 
        to={props.to} 
        className={
          "header-button " +
          (props.left ? 'left ' : 'right ') +
          (props.animated ? 'animated ' : '') +
          (props.className ? props.className : '')
        } 
        exact={props.exact}
        isActive={(match, location) => isActive(match, location, props.to)} >{props.children}</NavLink>
    );
  }
  return (
    <div className={
      "header-button " +
      (props.left ? 'left ' : 'right ') +
      (props.animated ? 'animated ' : '') +
      (props.className ? props.className : '')
    } onClick={props.onClick} >{props.children}</div>
  );
}))
