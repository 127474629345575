import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformWykonanieFromJSON } from './wykonanieModel';
import { applyParts } from '../part/getPart';
import { applyPrace } from '../praca/getPrace';
import moment from 'moment';
import { transformPozPlanFromJSON } from './pozPlanModel';
import { transformStructFromJSON } from './../struct/structModel';
import { transformPunktFromJSON } from './../punkt/punktModel';
import { transformPracaFromJSON } from '../praca/pracaModel';
import { transformPartFromJSON } from './../part/partModel';
import { transformRobolFromJSON } from '../robol';


export function sortNazwas(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}

export function applyDiagram(json){
  const structMap = {};
  const punktMap = {};
  const pracaMap = {};
  const partMap = {};
  const pozplanMap = {};
  const pozplanPunkty = {};

  const pozplans = json.pozplans;

  console.log(json);

  for(let i = 0; i < pozplans.length; i++){
    transformPozPlanFromJSON(pozplans[i]);
    pozplanMap[pozplans[i].id] = pozplans[i];

    if(pozplanPunkty[pozplans[i].idpkt]){
      pozplanPunkty[pozplans[i].idpkt].push(pozplans[i]);
    } else {
      pozplanPunkty[pozplans[i].idpkt] = [pozplans[i]];
    }
  }

  const structs = json.structs;
  for(let i = 0; i < structs.length; i++){
    transformStructFromJSON(structs[i], ['id', 'nazwas', 'typ', 'parent']);
    structMap[structs[i].id] = structs[i];
  }
  const punkty = json.punkty;
  for(let i = 0; i < punkty.length; i++){
    transformPunktFromJSON(punkty[i], ['id', 'nazwas', 'parent', 'dataost', 'coile', 'dt', 'tryb']);
    punktMap[punkty[i].id] = punkty[i];
  }  
  const prace = json.prace;
  for(let i = 0; i < prace.length; i++){
    transformPracaFromJSON(prace[i], ['id', 'nazwas', 'parent', 'czas']);
    pracaMap[prace[i].id] = prace[i];
  }  
  const parts = json.parts;
  for(let i = 0; i < parts.length; i++){
    transformPartFromJSON(parts[i], ['id', 'nazwas', 'parent', 'oryginal', 'uzyto', 'ileplan', 'ilefakt', 'jm']);
    partMap[parts[i].id] = parts[i];
  }    

  const robols = json.robols ? json.robols : [];
  for(let i = 0; i < robols.length; i++){
    transformRobolFromJSON(robols[i], ['id', 'parent', 'start', 'end', 'praca']);
  }    
  const usedparts = json.usedparts ? json.usedparts : [];
  for(let i = 0; i < usedparts.length; i++){
    transformPartFromJSON(usedparts[i], ['id', 'nazwa', 'nazwas', 'parent', 'oryginal', 'uzyto', 'ileplan', 'ilefakt', 'jm']);
  }     

  mainStore.wykonanie.data = {
    structs: structMap,
    punkty: punktMap,
    prace: pracaMap,
    praceArray: prace,
    parts: partMap,
    partArray: parts,
    pozplans: pozplanMap,
    pozplanPunkty: pozplanPunkty,
    robols: robols,
    usedparts: usedparts
  };
}

export function getDiagram(parentStructs, fromDate, toDate, fullWykonanie = false){
  mainStore.wykonanie.loading = true;
  mainStore.wykonanie.query = parentStructs;
  mainStore.wykonanie.list = [];
  return fetchJSON('/api/getDiagram/', { 
    parentStructs: parentStructs, 
    fromDate: fromDate, 
    toDate: toDate, 
    fullWykonanie: fullWykonanie 
  }).then((json) => {

    applyDiagram(json);

    mainStore.wykonanie.loading = false;
  })
  .catch(error => {
    mainStore.wykonanie.loading = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}