import React from 'react';
import { view } from 'react-easy-state';

const TreeChevron = (props) => (
  <i className={ props.open ? "clickable chevron fa fa-minus-square" : "clickable chevron fa fa-plus-square" } onClick={props.onClick}> </i>
)

export default view(TreeChevron)


