import React from 'react';
import './Row.css'
import { view } from 'react-easy-state';

export default view((props) => (
  <div className={
    "row "+ 
    (props.flex ? 'row-flex ' : '')+
    (props.micro ? 'row-micro ' : '')+
    (props.className ? props.className : '')
  }>{props.children}</div>
))