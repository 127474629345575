import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Button } from 'components/Button';
import { view } from 'react-easy-state';
import TimeChart from '../../components/TimeChart/TimeChart';
import flattenTree from '../../util/flattenTree';
import mainStore from '../../stores/store';
import Text from './../../components/Text/index';
import { getLudzie } from '../../model/ludzik/getLudzie';
import { Icon } from 'components/Icon/index';
import { Input } from 'components/Input';
import Label from './../../components/Label/index';

function hoursToTime(hours){
  const minutes = parseInt((hours % 1)*60);
  const hoursN = parseInt(hours);
  return hoursN + ':' + (minutes<10 ? '0'+minutes : minutes);
}

function timeToHours(timeString){
  const a = timeString.split(':');
  if(a.length==1){
    return parseInt(a[0])+':00'
  } else {
    let min = parseInt(a[1]);
    if(isNaN(min)) min = 0;
    return parseInt(a[0])+':'+(min<10 ? '0'+min : min)
  }
}

class WykonanieGodzinne extends Component {

  constructor(props){
    super(props);

    const flatTree = flattenTree(mainStore.wykonanie.treeDoWykonania);
    const treeMap = {};
    flatTree.map((e) => treeMap[e.id] = e);
    //console.log(flatTree);
    mainStore.wykonanie.rgPraceOrdered = [];
    mainStore.wykonanie.rgPrace = flatTree.filter((el) => el.type==='praca').map((el) => {
      if(!el.punkt){ el.punkt = treeMap[el.parent]; }
      if(!el.struct){ el.struct = treeMap[treeMap[el.parent].parent] }
      if(!el.planning){ 
        el.planning = {
          start: 0,
          end: 0,
          people: []
        }; 
      }
      return el;
    });
    //console.log(mainStore.wykonanie.rgPrace);

    //now we need to move prace to praceordered if they have planning object
    mainStore.wykonanie.rgPraceOrdered = mainStore.wykonanie.rgPrace.filter((el) => el.planning && el.planning.start == el.planning.end == 0);
    mainStore.wykonanie.rgPrace = mainStore.wykonanie.rgPrace.filter((el) => el.planning && !(el.planning.start == el.planning.end == 0));
    //console.log(mainStore.wykonanie.rgPrace);
    
    mainStore.bigLoading = true;
    getLudzie().then(() => {
      mainStore.ludzie.wykLudzie = mainStore.ludzie.sortedList.filter((e) => e.typ==1);
      mainStore.bigLoading = false;
    });    
  }

  planTime = (index) => {
    const newElement = mainStore.wykonanie.rgPrace.splice(index, 1)[0];
    mainStore.wykonanie.rgPraceOrdered.push(newElement);
    if(mainStore.wykonanie.rgPraceOrdered.length==1){
      newElement.planning.start = 5;
    } else {
      const lael = mainStore.wykonanie.rgPraceOrdered[mainStore.wykonanie.rgPraceOrdered.length - 2];
      newElement.planning.start = lael.planning.end;
    }
    newElement.planning.end = newElement.planning.start + newElement.extra / 60;

    if(mainStore.wykonanie.selectedRgPraca){
      const selpraca = mainStore.wykonanie.rgPraceOrdered.find((e) => e.id == mainStore.wykonanie.selectedRgPraca)
      if(selpraca) {
        newElement.planning.people = [...selpraca.planning.people]
      }
    }    
  }

  static class = 'modal-fill';

  render() {
    const selMap = {};
    let selpraca = {id: -1, people: []}
    let selpracaIndex = -1;
    if(mainStore.wykonanie.selectedRgPraca){
      selpracaIndex = mainStore.wykonanie.rgPraceOrdered.findIndex((e) => e.id == mainStore.wykonanie.selectedRgPraca);
      selpraca = mainStore.wykonanie.rgPraceOrdered[selpracaIndex];
      if(selpraca) {
        for(let i = 0; i < selpraca.planning.people.length; i++){
          selMap[selpraca.planning.people[i]] = i;
        }

        if(isNaN(mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.start)) mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.start = 0;
        if(isNaN(mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.end)) mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.end = 0;
    
      }
    }

    //console.log(mainStore.wykonanie.rgPrace);
    //console.log(mainStore.wykonanie.rgPraceOrdered);

    //fix me up some NaNs 
    return ( 
      <Container fill >
        <Row>
          <Col span="3">
            <h1>Rozplanowanie godzinne:</h1>
          </Col>
          {selpracaIndex >= 0 && [
            <Col key="1" span="2">
              <Row>
                <Col span="5">
                  <Input inline value={parseInt(selpraca.planning.start)} onChange={(e) => {
                    let nv = parseInt(e.target.value);
                    if(isNaN(nv)) nv = 0;
                    const frac = selpraca.planning.start % 1;
                    const duration = (selpraca.planning.end - selpraca.planning.start);
                    mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.start = nv + frac;
                    mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.end = mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.start + duration                
                  }}/>
                </Col>
                <Col span="1">
                  <Label>:</Label>
                </Col>
                <Col span="5">
                  <Input inline value={Math.round((selpraca.planning.start % 1)*60)} onChange={(e) => {
                    let nv = parseInt(e.target.value);
                    if(isNaN(nv)) nv = 0;
                    const hours = parseInt(selpraca.planning.start);
                    const duration = (selpraca.planning.end - selpraca.planning.start);
                    mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.start = hours + nv / 60;
                    mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.end = mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.start + duration                
                  }}/>
                </Col>
              </Row>              
            </Col>,
            <Col key="2d" span="2"> 
              <Input inline value={Math.round((selpraca.planning.end - selpraca.planning.start)*60)} onChange={(e) => {
                let nv = parseInt(e.target.value);
                if(isNaN(nv)) nv = 0;
                mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.end = nv / 60 + mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.start
              }}/>
            </Col>,
            <Col key="3" span="2">
              <Row>
                <Col span="5">
                  <Input inline value={parseInt(selpraca.planning.end)} onChange={(e) => {
                    let nv = parseInt(e.target.value);
                    if(isNaN(nv)) nv = 0;                
                    const frac = selpraca.planning.end % 1;
                    mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.end = nv + frac;
                  }}/>
                </Col>
                <Col span="1">
                  <Label>:</Label>
                </Col>
                <Col span="5">
                  <Input inline value={Math.round((selpraca.planning.end % 1)*60)} onChange={(e) => { 
                    let nv = parseInt(e.target.value);
                    if(isNaN(nv)) nv = 0;                
                    const hours = parseInt(selpraca.planning.end);
                    mainStore.wykonanie.rgPraceOrdered[selpracaIndex].planning.end = hours + nv / 60;
                  }}/>
                </Col>
              </Row>              
            </Col>
          ]}
        </Row>
        <Row>
          <Col span="3">
            {mainStore.wykonanie.rgPraceOrdered.map((e) => {
              if(mainStore.wykonanie.selectedRgPraca == e.id){
                return <Button key={e.id} primary block onClick={() => mainStore.wykonanie.selectedRgPraca = e.id}><Text green>{e.nazwas}</Text> <Text red>[{e.extra}min]</Text><br />{e.struct.nazwas} / {e.punkt.nazwas}</Button> 
              } else {
                return <Button key={e.id} block onClick={() => mainStore.wykonanie.selectedRgPraca = e.id}><Text green>{e.nazwas}</Text> <Text red>[{e.extra}min]</Text><br />{e.struct.nazwas} / {e.punkt.nazwas}</Button>
              }
            } )}
            <hr />
            {mainStore.wykonanie.rgPrace.map((e, index) => 
              <Button key={e.id} block onClick={() => this.planTime(index)}>
                <Text green>{e.nazwas}</Text> <Text red>[{e.extra}min]</Text><br />
                {e.struct.nazwas} / {e.punkt.nazwas}
                <div className="ButtonRightChevron"><Icon icon="fa fa-arrow-up" /></div>
              </Button>
            )}
          </Col>
          <Col span="6">
            <TimeChart start={5} end={21} plannedNodes={mainStore.wykonanie.rgPraceOrdered} accessor={(e) => ({id: e.id, start: e.planning.start, end: e.planning.end, caption: e.nazwas})} selected={mainStore.wykonanie.selectedRgPraca} onClick={(o) => mainStore.wykonanie.selectedRgPraca = o.id}/>
          </Col>
          <Col span="3">
            {mainStore.ludzie.wykLudzie.map((e) => {
              if(selMap[e.id]!==undefined){
                return <Button key={e.id} green block onClick={() => selpraca.planning.people.splice(selMap[e.id], 1)}>{e.podpis}</Button>;
              } else {
                return <Button key={e.id} block onClick={() => selpraca.planning.people.push(e.id)}>{e.podpis}</Button>
              }
            } )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(WykonanieGodzinne);
