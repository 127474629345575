import moment from 'moment';

export function toInt(v){
  return parseInt(v, 10);
}

export function toBool(v){
  return parseInt(v, 10)>0;
}

export function toFloat(v){
  return parseFloat(v);
}

export function DoubleToTimestamp(v){
  //stored as Double => no of days since 01.01.1900
  //timestamp (used here) is no of seconds since 01.01.1970
  // so we need to multiply be seconds and subtract the difference in seconds.
  return Math.floor(parseFloat(v) * 24 * 60 * 60) - 2208988800;
}

export function DateTimeToTimestamp(v){
  return moment(v, 'YYYY-MM-DD kk:mm:ss').unix();
}

//@TODO: nullable values
export function collateObject(obj, struct, fieldFilter){
  if(fieldFilter){
    for(let f = 0; f < fieldFilter.length; f++){
      const i = fieldFilter[f];
      if(struct[i] === 'int') obj[i] = toInt(obj[i]);
      if(struct[i] === 'bool') obj[i] = toBool(obj[i]);
      if(struct[i] === 'float') obj[i] = toFloat(obj[i]);
      if(struct[i] === 'date') obj[i] = DoubleToTimestamp(obj[i]);
      if(struct[i] === 'datetime') obj[i] = DateTimeToTimestamp(obj[i]);
      //just make sure that strings are strings.
      if(struct[i] === 'string') obj[i] = ''+obj[i];
    }
  } else {
    for(let i in struct){
      if(struct[i] === 'int') obj[i] = toInt(obj[i]);
      if(struct[i] === 'bool') obj[i] = toBool(obj[i]);
      if(struct[i] === 'float') obj[i] = toFloat(obj[i]);
      if(struct[i] === 'date') obj[i] = DoubleToTimestamp(obj[i]);
      if(struct[i] === 'datetime') obj[i] = DateTimeToTimestamp(obj[i]);
      //just make sure that strings are strings.
      if(struct[i] === 'string') obj[i] = ''+obj[i];
    }
  }
  return obj;
}