import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import Select from 'components/Select';
import Input from 'components/Input/index';
import { Button } from 'components/Button';
import mainStore from '../../stores/store';
import { view } from 'react-easy-state';
import DateInput from 'components/Input/DateInput';
import moment from 'moment';
import { trybyPrac } from '../../model/punkt/punktModel';
import { TextArea } from 'components/TextArea';
import { savePunkt, deletePunkt } from '../../model/punkt/savePunkt';
import Icon from '../../components/Icon/index';
import { changePassword } from './../../model/login/changePassword';

class ChangePassword extends Component {

  constructor(props){
    super(props);
    this.state = {
      oldPass: '',
      newPass: '',
      newPass2: ''
    }
    mainStore.changePasswordSuccess = undefined;
  }

  static class = 'modal-small';

  savePassword = () => {
    let tuid = undefined;
    if(this.props.targetLudzikID){
      tuid = this.props.targetLudzikID;
    }
    changePassword(this.state.oldPass, this.state.newPass, tuid).then((result) => {
      if(result.success){
        this.props.closeAction();
      }
    });
  }

  render() {
    const oldPassError = (mainStore.changePasswordSuccess === false) || this.state.oldPass.length <6;
    const newPassError = this.state.newPass.length < 6 || this.state.newPass != this.state.newPass2 ;
    const newPassError2 = this.state.newPass2.length < 6 || this.state.newPass != this.state.newPass2;
    return ( 
      <Container fill>
        <h1> Zmiana hasła </h1>
        {!this.props.targetLudzikID && <Row>
          <Col span="4">
            Stare hasło
          </Col>
          <Col span="8">
            <Input className={ (oldPassError ? "border-red" : "") } password placeholder="Stare hasło" value={this.state.oldPass} onChange={(e) => this.setState({oldPass: e.target.value})} />
          </Col>
        </Row>}
        <Row>
          <Col span="4">
            Nowe hasło
          </Col>
          <Col span="8">
            <Input className={ (newPassError ? "border-red" : "") } password placeholder="Nowe hasło" value={this.state.newPass} onChange={(e) => this.setState({newPass: e.target.value})} />
          </Col>
        </Row>
        <Row>
          <Col span="4">
            Powtórzone nowe hasło
          </Col>
          <Col span="8">
            <Input className={ (newPassError2 ? "border-red" : "") } password placeholder="Powtórzone nowe hasło" value={this.state.newPass2} onChange={(e) => this.setState({newPass2: e.target.value})} />
          </Col>
        </Row>
        { mainStore.changePasswordMessage && 
          <Row>
            <Col span="4">
              Wiadomość z serwera
            </Col>
            <Col span="8">
              {mainStore.changePasswordMessage}
            </Col>
          </Row>
        }
        <Row>
          <Col span="12">
            {((this.state.oldPass.length>=6 || this.props.targetLudzikID) && this.state.newPass.length>=6 && this.state.newPass == this.state.newPass2) ? 
              <Button green block onClick={this.savePassword}><Icon icon="fal fa-save" /> Zapisz nowe hasło</Button>
              :
              <Button block ><Icon icon="fal fa-save" /> Zapisz nowe hasło</Button>
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(ChangePassword);
