export const cennikiTable = [
  { caption: 'Sygnatura', get: (row) => row.name, align: 'left', maximize: true },
  { caption: 'Utworzono', get: (row) => row.created, align: 'left' },
  { caption: 'Obowiązuje', get: (row) => row.effectiveFrom, align: 'left' },
  { caption: 'Firma', get: (row) => row.company.name, align: 'left' },
]

export const cennikiPozycjeTable = [
  { caption: 'Poz', get: (row) => row.id, align: 'right' },
  { caption: 'Cena s', get: (row) => row.sellPrice, align: 'right', mono: true },
  { caption: 'Cena z', get: (row) => row.buyPrice, align: 'right', mono: true },
  { caption: 'material', get: (row) => row.material, align: 'left' },
  { caption: 'nazwa cennikowa', get: (row) => row.material, align: 'left', maximize: true },
]

const poz = {
  id: 0,
  sellPrice: 192.73,
  buyPrice: 82.12,
  material: 'Olej testowy 170',
  name: 'SuperOlejwcennikowy',
  producent: 'LuxOil',
  typ: 'typ',
  opakowanie: 'Mauser',
  towar: 'Olej Testowy 170Bs'
}

export const cennikiData = [
  { 
    id: 0, 
    name: 'MPD-CN/2017/06/01', 
    pozycje: [
      {...poz, id: 0, sellPrice: 1923.29, buyPrice: 1324.22},
      {...poz, id: 1, sellPrice: 923.29, buyPrice: 634.58},
      {...poz, id: 2, sellPrice: 23.29, buyPrice: 13.11}
    ],
    created: '2017-06-23',
    effectiveFrom: '2017-07-01',
    status: 'aktywny',
    uwagi: 'Uwagi',
    zatwierdzil: 'Adam Adamowski',
    spisDla: 'Projekt ZAT',
    company: {
      name: 'WSK Rzeszów'
    }
  },
  { 
    id: 1, 
    name: 'MPD-CN/2017/06/02', 
    pozycje: [
      {...poz, id: 0, sellPrice: 923.29, buyPrice: 324.22},
      {...poz, id: 1, sellPrice: 412.29, buyPrice: 234.58},
      {...poz, id: 2, sellPrice: 42.29, buyPrice: 13.11}
    ],
    created: '2017-06-23',
    effectiveFrom: '2017-07-01',
    status: 'aktywny',
    uwagi: 'Uwagi',
    zatwierdzil: 'Adam Adamowski',
    spisDla: 'Projekt ZAT',
    company: {
      name: 'WSK Rzeszów'
    }
  },
  { 
    id: 2, 
    name: 'MPD-CN/2017/06/03', 
    pozycje: [
      {...poz, id: 0, sellPrice: 423.22, buyPrice: 324.22},
      {...poz, id: 1, sellPrice: 111.52, buyPrice: 112.58},
      {...poz, id: 2, sellPrice: 33.45, buyPrice: 13.11}
    ],
    created: '2017-06-23',
    effectiveFrom: '2017-07-01',
    status: 'aktywny',
    uwagi: 'Uwagi',
    zatwierdzil: 'Adam Adamowski',
    spisDla: 'Projekt ZAT',
    company: {
      name: 'WSK Rzeszów'
    }
  },
]