import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformLudzikFromJSON, sortLudzie } from './ludzikModel';
import { getLudzie } from './getLudzie';

export function saveLudzik(newObject){
  return fetchJSON('/api/saveLudzik/', {ludzik: newObject})
  .then((json) => {
    //yay :D
    return getLudzie(undefined, true);
  })
  .catch(error => {
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}