import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import Select from 'components/Select';
import Input from 'components/Input';
import { Button } from 'components/Button';
import TextArea from 'components/TextArea';
import { materialyTable, materialyData } from 'model/tables/materialy';
import Table from 'components/Table';
import Label from 'components/Label';
import { view } from 'react-easy-state';
import { mainStore } from 'stores/store';
import { Icon } from 'components/Icon';
import { savePraca, deletePraca } from '../../model/praca/savePraca';

class NewPraca extends Component {

  constructor(props){
    super(props);

    let praca;
    if(this.props.praca){
      praca = this.props.praca;
    } else {
      praca = mainStore.currentProject.repoPrac.sorted[0]
    }
    if(praca === undefined){ 
      praca = {
      };
    }
    this.state = {
      ...praca,
      orgRepo: -1
    }
  }  

  savePraca = () => {
    const newObject = {
      id: -1,
      nazwa: this.state.nazwa,
      nazwas: this.state.nazwas,
      parent: mainStore.selectedPunkt.id,
      worker: parseInt(this.state.worker),
      instrukcja: this.state.instrukcja,
      info: this.state.info,
      czas: parseFloat(this.state.czas),
    }
    if(this.props.praca){
      newObject.id = this.props.praca.id;
    }
    savePraca(newObject, this.state.orgRepo).then(() => {
      this.props.closeAction(true);
    });
  }

  deletePraca = () => {
    if(!this.props.praca) return;
    deletePraca(this.props.praca.id).then(() => {
      this.props.closeAction(true);
    });
  }

  chooseRepo = (e) => {
    const praca = mainStore.currentProject.repoPrac.idMap[e.target.value];

    this.setState({
      ...praca,
      orgRepo: praca.id
    });
}

  static class = 'modal-small';

  render() {

    return ( 
      <Container fill >
        <h1> Czynność: {this.props.v}</h1>
        <Row>
          <Col span="4">
            <Label>Czynność</Label>
          </Col>
          <Col span="8">
            <Select options={mainStore.currentProject.repoPrac.sorted} accessor={(row) => ({ key: row.id, value: row.nazwas })} onChange={this.chooseRepo}/>
          </Col>
        </Row>
        <Row>
          <Col span="4">
            Nazwa
          </Col>
          <Col span="8">
            <Input placeholder="Nazwa" value={this.state.nazwa} onChange={(e) => this.setState({nazwa: e.target.value})} />
          </Col>
        </Row>
        <Row>
          <Col span="4">
            Nazwa skrócona 
          </Col>
          <Col span="8">
            <Input placeholder="Nazwa skrócona" value={this.state.nazwas} onChange={(e) => this.setState({nazwas: e.target.value})} />
          </Col>
        </Row>
        <Row>
          <Col span="4">
            Symbol instrukcji
          </Col>
          <Col span="8">
            <Input placeholder="Symbol instrukcji" value={this.state.instrukcja} onChange={(e) => this.setState({instrukcja: e.target.value})} />
          </Col>
        </Row>
{/*        <Row>
          <Col span="4">
            Brygada
          </Col>
          <Col span="8">
            <Select options={mainStore.currentProject.brygady.sorted} accessor={(row) => ({ key: row.id, value: row.nazwas })} value={this.state.worker} onChange={(e) => this.setState({worker: e.target.value})}/>
          </Col>
</Row>*/}
        <Row>
          <Col span="4">
            Czas wykonania(min)
          </Col>
          <Col span="8">
            <Input placeholder="Czas" value={this.state.czas} onChange={(e) => this.setState({czas: e.target.value})} />
          </Col>
        </Row>
        <Row>
          <Col span="4">
            Opis
          </Col>
          <Col span="8">
            <TextArea placeholder="Opis" value={this.state.info} onChange={(e) => this.setState({info: e.target.value})} />
          </Col>
        </Row>
{/*        <h3>Materiały</h3>
<Table cols={materialyTable} data={[]}/>*/}
        <Row>
          <Col span="6">
            {this.props.praca ? <Button secondary block onClick={this.deletePraca}><Icon icon="fal fa-trash" />Usuń Czynność</Button> : " "}
          </Col>
          <Col span="6">
            <Button green block onClick={this.savePraca}><Icon icon="fal fa-save" /> Zapisz Czynność</Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(NewPraca);
