export const materialyTable = [
  { caption: 'Nazwa', get: (row) => row.nazwas, align: 'left', maximize: true },
  { caption: 'P', get: (row) => row.ileplan, align: 'right' },
  { caption: 'R', get: (row) => row.ilefakt, align: 'right' },
]

export const materialyData = [
  { id: 1, name: 'śrubka', ile: 4 },
  { id: 2, name: 'nakrętka', ile: 2 },
]

