const punkt = {
  id: 'int',
  transaction: 'int',
  active: 'bool',
  deleted: 'bool',
  typ: 'int',
  nazwa: 'string',
  nazwas: 'string',
  parent: 'int',
  praca: 'int',
  tryb: 'int',
  coile: 'int',
  dataost: 'datetime',
  datanast: 'datetime',
  dt: 'string',
  prior: 'int',
  info: 'string',
  cenas: 'int',
  extra: 'float',
  device: 'int',
  project: 'int',
  lastmeasure: 'float'
}

module.exports = punkt