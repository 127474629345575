import React from 'react';
import Row from './Row';
import Col from './Col'
import { Label } from 'components/Label';
import { view } from 'react-easy-state';

export default view((props) => (
  <Row>
    <Col span={props.span ? props.span : 4}>
      {typeof(props.caption) === 'string' ? 
        <Label>{props.caption}</Label>
      :
        props.caption
      }
    </Col>
    <Col span={props.span ? (12 - parseInt(props.span, 10)) : 8}>
      {props.children}
    </Col>
  </Row>
))
