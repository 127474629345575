import { exportStyles, repeatThing } from "./WydrukShared";

const ExportKrotki = (data) => {
    const result = window.open("", "Title", "scrollbars=yes,resizable=yes,width=1600,height=800,top=0,left=0)");
   
    const linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    linkElement.setAttribute('href', 'data:text/css;charset=UTF-8,' + encodeURIComponent(exportStyles));

    let lastStruct = undefined;
    let maxStructLength = 1;
    let maxPracownicyLength = 1;
    for(let i = 0; i < data.struktury.length; i++){
        for(let j = 0; j < data.struktury[i].entries.length; j++) {
            let ss = data.struktury[i].entries[j].struct;
            if(!Array.isArray(ss)){
                data.struktury[i].entries[j].struct = [ss];
                ss = [ss];
            }            
            if(maxStructLength < ss.length){
                maxStructLength = ss.length;
            }
            if(maxPracownicyLength < data.struktury[i].entries[j].pracownicy.length){
                maxPracownicyLength = data.struktury[i].entries[j].pracownicy.length;
            }
        }
    }

    result.document.head.innerHTML = `<meta name="viewport" content="width=device-width, initial-scale=1.0">`;
    result.document.body.innerHTML = `
        <table>
        <thead>
            <tr className="border">
            <th>LP</th>
            <th>Tryb</th>
            <th>Data</th>
            <th>Raport</th>
            <th colspan="${maxStructLength}">Struktura</th>
            <th>Materialy</th>
            <th colspan="${maxPracownicyLength}"></th>
            <th>Ile</th>
            </tr>
        </thead>
        <tbody>
        ${data.struktury.map((s) => `
            ${s.entries.map((e, i) => `
                <tr>
                    <td>${i}</td>
                    <td>${e.tryb}</td>
                    <td>${e.data}</td>
                    <td>${e.rap}</td>
                    ${e.struct.map((st) => `<td>${st}</td>`).join('')}
                    ${repeatThing('<td></td>', maxStructLength - e.struct.length).join('')}
                    <td>${e.praca}</td>
                    <td>${e.czynnosc}</td>
                    <td>${e.materialy.map((m) => m.nazwa).join(', ')}</td>
                    <td>${e.pracownicy.length}</td>
                </tr>
                `).join('')}
        `).join('')}
        </tbody>
    </table>
    `;

    result.document.body.append(linkElement);
}

export default ExportKrotki;