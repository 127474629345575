import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Select } from 'components/Select';
import { Input } from 'components/Input';
import Table from 'components/Table';
import { materialyTable, materialyData } from 'model/tables/materialy';
import { Button } from 'components/Button';
import { view } from 'react-easy-state';
import { getCosieks } from '../../model/cosiek/getCosieks';
import Loading from '../../components/Loading/index';
import { mainStore } from 'stores/store';
import Icon from '../../components/Icon/index';
import { savePart, deletePart } from '../../model/part/savePart';
import moment from 'moment';
import { cutoffUnixDate } from './../../components/WeekGraph/WeekGraph';

class ChooseDay extends Component {

  static class = 'modal-small';

  constructor(props) {
    super(props);

    this.state = {
      sel: props.checkedItems
    }
  }

  onClick = (wyk) => {
    this.props.then(wyk);
    this.props.closeAction(true);
  }

  onCheckClick = (wyk, i) => {
    if(this.isFinished(wyk)){
      if(this.props.onFinishedClick){
        this.props.onFinishedClick(this.props.data, this.props.wykNode, this.props.wykNode[i]);
      }
      return;
    }
    const sel = this.props.onCheckChange(wyk);
    this.setState({sel: sel});
  }

  isFinished(e){
    return (e.pozplan !== undefined) && ((e.pozplan.datawyk > cutoffUnixDate) && (e.pozplan.raport !== "0"));
  }

  getIcons(i, e) {
    const now = moment().startOf('day').unix();
    const late = now > e.day;
    const finished = this.isFinished(e);
    
    if(finished){
      return [ <Icon key={i+'done'} icon="fa fa-fw fa-check" className="text-green"/> ]
    } else {
      if(late) {
        return [ <Icon key={i+'late'} icon="fa fa-fw fa-clock" className="text-red"/> ]
      }
    }
    return [];
  }

  render() {
    console.log(this.props.wykNode)
    return (
      <Container fill>
        <h1> Proszę wybrać zaplanowane wykonanie</h1>
        {this.props.wykNode.map((e, i) => (
          <Row key={i}>
            <Col span="1">
              {!this.isFinished(e) &&
                <Button primary block onClick={(event) => this.onCheckClick(e)}>
                  {this.state.sel[this.props.data.punkt.id+'_'+e.day]
                    ?
                      <Icon icon="fa fa-fw fa-check" className="text-green"/>
                    :
                      <Icon icon="fa fa-fw fa-times" />
                  }
                </Button>
            }
            </Col>
            <Col span="11">
              <Button primary block onClick={(event) => this.onCheckClick(e, i)}>{moment.unix(e.day).format('YYYY-MM-DD')}{this.getIcons(i, e)}</Button>
            </Col>
          </Row>
        ))}
      </Container>
    );
  }
}

export default view(ChooseDay);
