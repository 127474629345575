export const brygadyTable = [
  { caption: 'Nazwa', get: (row) => row.name, align: 'left', maximize: true },
  { caption: 'Rozmiar', get: (row) => row.contents.length, align: 'left' },
]

export const brygadyLudzieTable = [
  { caption: 'Typ', get: (row) => row.type, align: 'left' },
  { caption: 'Nazwa/imię', get: (row) => row.name, align: 'left', maximize: true },
]

export const brygadyData = [
  { 
    id: 0, 
    name: 'laborant', 
    contents: [
      {type: 'stanowisko', name: 'Laborant'},
      {type: 'stanowisko', name: 'Asystent Laboranta'}
    ],
    cennik: 'PozCennika',
    uwagi: 'Jakaś uwaga'
  },
  { 
    id: 1, 
    name: 'serwis prewencyjny', 
    contents: [
      {type: 'stanowisko', name: 'Główny prewentor'},
      {type: 'stanowisko', name: 'asystent prewentora'}
    ],
    cennik: 'PozCennika',
    uwagi: 'Jakaś uwaga'
  },
  { 
    id: 2, 
    name: 'cokolwiek', 
    contents: [
      {type: 'osoba', name: 'Anna Abacka'},
      {type: 'osoba', name: 'Bernard Bebacki'}
    ],
    cennik: 'PozCennika',
    uwagi: 'Jakaś uwaga'
  },
]