let allArr = [];

export default function flattenTree(tree) {
  allArr = [];
  if(Array.isArray(tree)){
    for(let i = 0; i < tree.length; i++){
      allArr.push(tree[i]);
      flattenRecursive(tree[i]);
    }
  } else {
    allArr.push(tree);
    flattenRecursive(tree);
  }
  return allArr;
}

function flattenRecursive(tree){
  for(let i = 0; i < tree.children.length; i++){
    allArr.push(tree.children[i]);
    flattenRecursive(tree.children[i]);
  }
}