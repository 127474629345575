import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformStructFromJSON } from 'model/struct/structModel';
import { sortStructs } from './structModel';

function addChildren(obj){
  obj.children = [];
  return obj;
}

function findStruct(tree, id){
  if(Array.isArray(tree)){
    for(let i = 0; i < tree.length; i++){
      if(tree[i].id === id) return tree[i];
      const res = findStruct(tree[i], id)
      if(res) return res;
    }
  } else {
    if(id == tree.id) return tree;
    for(let i = 0; i < tree.children.length; i++){
      if(tree.children[i].id === id) return tree.children[i];
      const res = findStruct(tree.children[i], id)
      if(res) return res;
    }
  }
}

//mainStore.currentProject.structs
export function saveStruct(struct, isMove){
  mainStore.bigLoader = true;
  return fetchJSON('/api/saveStruct/', {struct: struct})
  .then((json) => {
    if(isMove){
      //just remove the old location
      mainStore.prace.structChildren = [];
      const olds = findStruct(mainStore.currentProject.structs, struct.id);
      const oldParent = olds.parent;

      const s = findStruct(mainStore.currentProject.structs, oldParent);
      s.children = s.children.filter((el) => el.id!=struct.id);
    }
    mainStore.bigLoader = false;
    if((struct.id==-1) || (isMove)){
      //if moving we removed the old one, now add the new one
      const s = findStruct(mainStore.currentProject.structs, json.parent);
      if(s){
        s.children.push(addChildren(transformStructFromJSON(json)));
        s.children.sort(sortStructs);
      }
    } else {
      const s = findStruct(mainStore.currentProject.structs, json.parent);
      for(let i = 0; i < s.children.length; i++){
        if(s.children[i].id==struct.id){
          const no = transformStructFromJSON(json);
          no.children = s.children[i].children;
          s.children[i] = no;
          s.children.sort(sortStructs);
          break;
        }
      }
      mainStore.selectedStruct = s;
    }
    mainStore.openStructMap = {...mainStore.openStructMap};
  })
  .catch(error => {
    mainStore.bigLoader = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}


//mainStore.currentProject.structs
export function cloneStruct(ids, oldParent, newParent){
  mainStore.bigLoader = true;
  return fetchJSON('/api/cloneStruct/', {ids: ids, newParent: newParent, oldParent: oldParent})
  .then((json) => {
    mainStore.bigLoader = false;
    //find the new Parent.
    //console.log('finding parent');
    const s = findStruct(mainStore.currentProject.structs, newParent);
    if(s){
      //console.log('got parent');
      const parentMap = {};
      for(let i = 0; i < json.length; i++){
        const no = addChildren(transformStructFromJSON(json[i]));
        if(parentMap[no.parent]){
          parentMap[no.parent].push(no);
        } else {
          parentMap[no.parent] = [no];
        }
      }
      //console.log(parentMap);
      const fillChildren = function(node){
        //console.log('fillingChildren', node.id);
        if(parentMap[node.id]){
          const newChildren = parentMap[node.id];
          //console.log('got children', newChildren);
          for(let i = 0; i < newChildren.length; i++){
            node.children.push(newChildren[i]);  
          }
          for(let i = 0; i < node.children.length; i++){
            fillChildren(node.children[i]);
          }
        }
      }
      fillChildren(s);
      //console.log(s);
      //mainStore.selectedStruct = s;    
      s.children.sort(sortStructs);
    } else {
      console.error('PROBLEM! We were cloning a struct tree but the new parent cant be found!!!')
    } 
    mainStore.openStructMap = {...mainStore.openStructMap};
  })
  .catch(error => {
    mainStore.bigLoader = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}

export function deleteStruct(structID){
  mainStore.bigLoader = true;
  return fetchJSON('/api/deleteStruct/'+structID)
  .then((json) => {
    mainStore.bigLoader = false;
    //just remove the old location
    mainStore.prace.structChildren = [];
    const olds = findStruct(mainStore.currentProject.structs, structID);
    const oldParent = olds.parent;

    const s = findStruct(mainStore.currentProject.structs, oldParent);
    s.children = s.children.filter((el) => el.id!=structID);    
    mainStore.openStructMap = {...mainStore.openStructMap};    
  })
  .catch(error => {
    mainStore.bigLoader = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}