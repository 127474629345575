import { collateObject } from "util/collation";

import pozplanCollation from 'model/pozplan.js'

export function transformPozPlanFromJSON(o, fieldFilter) {
  return collateObject(o, pozplanCollation, fieldFilter);      
}

export function sortPozPlan(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}

