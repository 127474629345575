const part = {
  id: 'int',
  transaction: 'int',
  active: 'bool',
  deleted: 'bool',
  typ: 'int',
  nazwa: 'string',
  nazwas: 'string',
  parent: 'int',
  oryginal: 'int',
  uzyto: 'int',
  ileplan: 'float',
  ilefakt: 'float',
  cenas: 'int',
  zamienniki: 'string',
  jm: 'string'
}

module.exports = part