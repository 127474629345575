import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import './DateInput.css';

import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { view } from 'react-easy-state';

const DateInput = (props) => (
  <DayPickerInput 
    onDayChange={(day, modifiers, dayPickerInput) => {
      if(day!==undefined) {
        if(props.onChange){
          props.onChange(day);
        }
      }
    }} 
    value={formatDate(props.value, "YYYY-MM-DD")} 
    format="YYYY-MM-DD"
    dateFormat="YYYY-MM-DD"
    parseDate={(str) => parseDate(str, "YYYY-MM-DD")}
    placeholder="YYYY-MM-DD"
  />
)

export default view(DateInput);
