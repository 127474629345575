import treeData from 'TreeViewExampleData.json';
import Container from 'components/Container';
import { Col, Row } from 'components/Grid';
import React, { Component } from 'react';
import { view } from 'react-easy-state';
import { getStructIcon } from 'util/getStructIcon';
import Table from 'components/Table/Table';
import { praceTable } from 'model/tables/prace';

import messageList from './messageList.json';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: treeData,
      selectedNode: false
    }
  }

  nodeProcessor(sourceNode) {
    return {
      id: sourceNode.id,
      caption: sourceNode.caption,
      icon: getStructIcon(sourceNode),
      children: sourceNode.children
    }
  }

  treeViewNodeSelect = (node) => {
    this.setState({ selectedNode: node })
  }

  render() {
    //const selectedNode = this.state.selectedNode ? this.state.selectedNode : {};
    return (
      <Container>
        <Row>
          <Col span="4">
            <Container panel vfull vscrolled>
              <h3>Czynności do wykonania</h3>
              <Table cols={praceTable} data={[]} onClick={() => {}}/>
            </Container>
          </Col>
          <Col span="8">
            <Container panel vfull vscrolled>
              <h3>Wiadomości</h3>
              <ul>
                {messageList.map((e, index) => 
                  <li key={index}><span className="color-green">{e.date}</span> {e.title}<br/>
                    <sub className="pre">{e.content}</sub>
                  </li>
                )}
              </ul>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(App);
