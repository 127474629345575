import { store } from 'react-easy-state';
import moment from 'moment';
import { newNodeSum } from '../screens/Wykonanie/newNodeSum';

const mainStore = store({
  currentSearch: '',
  searchParams: {},
  loadedBaseData: false,
  baseData: {},
  loggedIn: false,
  projects: [],
  currentProjectID: 0,
  currentProject: undefined,

  bigLoading: false,
  bigError: false,

  //Prace interface:
  openStructMap: {},
  selectedStruct: false,
  currentPunkty: [],
  selectedPunkt: false,
  currentPrace: [],
  selectedPrace: false,
  currentParts: [],
  selectedPart: false,
  currentMaterialy: [],

  //Admin interface:
  selectedMaterial: {},
  currentMaterial: {},

  prace: {
    structChildren: [],
    loading: false
  },

  wykonanie: {
    list: [],
    query: [],
    hideRow: {},
    treeDoWykonania: [],
    openStructMap: {},
    totals: newNodeSum(),
    raport: '',
    info: ''
  },

  diagram: {
    filter: '',
    diagramRows: [],
    filteredDiagramRows: [],
    nameFilter: ''
  },

  //top bar calendar
  showTopFromTo: false,
  topFromTo: {
    from: moment().week(1).toDate(),
    to: moment().week(52).toDate()
  },

  login: {
    progress: false,
    message: ''
  },

  ludzie: {
    list: [],
    sortedList: [],
    wykLudzie: []
  },

  archiwum: {
    selectedPozPlan: undefined,
    data: {
      pozplans: []
    },
    dateRange: {
      from: undefined,
      to: undefined
    },
  },

  cosieks: [],

  forceProcessUpdate: 0
})

export default mainStore;
export { mainStore };
