import React, { Component } from 'react';
import './Table.css'
import { Icon } from 'components/Icon';
import Button from 'components/Button';
import 'components/Mono/Mono.css';
import { view } from 'react-easy-state';

function getAlignClass(align) {
  if(align==='left'){
    return 'text-left';
  }
  if(align==='right'){
    return 'text-right';
  }
  if(align==='center'){
    return 'text-center';
  }
}

function getMaximizeClass(maximize) {
  return maximize ? ' table-column-maximize' : '';
}

function getMonoClass(mono) {
  return mono ? ' font-monospace' : '';
}

class Table extends Component {

  constructor(props){
    super(props);

    this.state = {
      limitSteps: 0
    }
  }
 
  rowClicked(row, index) {
    if((this.props.selected && row===this.props.selected) ||
      (this.props.selectedID && (typeof(row) === 'object') && this.props.selectedID === row.id)
    ) return;
    if(this.props.onClick){
      this.props.onClick(row);
    }
  }

  colClicked(colHeader, index) {

  }

  newRowClick = () => {
    if(this.props.newRowClick){
      this.props.newRowClick();
    }
  }

  editRowClick = (row) => {
    if(this.props.editRowClick){
      this.props.editRowClick(row);
    }
  }

  getCaption(caption){
    if(typeof(caption)==='function'){
      return caption();
    } else {
      return caption;
    }
  }

  render() {
    const props = this.props;
    let data = props.data ? props.data : [];
    let loadMore = false;
    if(props.loadMore){
      if(data.length > props.limit * (this.state.limitSteps + 1)){
        loadMore = true;
      }
    }
    if(props.limit){
      data = data.slice(0, props.limit * (this.state.limitSteps + 1));
    }
    return (
      <table className={ "table " +
        (props.className ? props.className : '')
      }  cellPadding="0" cellSpacing="0"> 
        <thead>
          <tr>
            {props.cols.map((colHeader, index) => (
              <th key={index} onClick={() => this.colClicked(colHeader, index)} className={getAlignClass(colHeader.align)+getMaximizeClass(colHeader.maximize)+getMonoClass(colHeader.mono)}>{this.getCaption(colHeader.caption)}</th>
            ))}
            {props.edit && <th/>}
          </tr>
        </thead>
        <tbody>
          {(data.length > 0) && data.map((row, index) => (
            <tr key={row.id} onClick={() => this.rowClicked(row, index)} className={ (props.onClick ? 'clickable ' : ' ') + ((props.selected === row || (props.selectedID && (typeof(row) === 'object') && props.selectedID === row.id)) ? ' selected' : '') }>
              {props.cols.map((colHeader, colIndex) => (
                <td key={colIndex} className={getAlignClass(colHeader.align)+getMonoClass(colHeader.mono)+(colHeader.getClass ? ' '+colHeader.getClass(row, index) : '')}>{colHeader.get(row, index)}</td>
              ))}
              {props.edit && <td className="fill">
                <Button small tetriary fill onClick={() => this.editRowClick(row)}><Icon icon="fal fa-pen" /></Button>
              </td>}
            </tr>
          ))}
          {(data.length === 0) && (
            <tr>
              <td colSpan={props.cols.length} className="no-data-message">
                {props.noDataMessage ? props.noDataMessage : 'No data.'}
              </td>
            </tr>
          )}
          {props.edit && (
            <tr>
              <td className="fill" colSpan={props.cols.length}><Button fill small tetriary block onClick={this.newRowClick}><Icon icon="fa fa-plus" /></Button></td>
              <td className="fill"><Button small block><Icon fill icon="fa" /></Button></td>
            </tr>
          )}
          {props.addmore && (
            <tr>
              <td className="fill" colSpan={props.cols.length}><Button fill small tetriary block onClick={this.newRowClick}><Icon icon="fa fa-plus" /></Button></td>
            </tr>
          )}          
          {loadMore && (
            <tr>
              <td className="fill" colSpan={props.cols.length}><Button tetriary block onClick={() => this.setState({limitSteps: this.state.limitSteps + 1})}><Icon icon="fa fa-plus" />Load More</Button></td>
            </tr>
          )}          
        </tbody>
      </table>
    )
  }
}

export default view(Table);