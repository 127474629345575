import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformWykonanieFromJSON } from './wykonanieModel';
import { applyParts } from '../part/getPart';
import { applyPrace } from '../praca/getPrace';
import moment from 'moment';

export function sortNazwas(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}

function getClosestPastExecution(punkt){
  const date = moment();
  date.set({hour:23, minute:59, second:59, milisecond:0});
  
  const last = moment.unix(punkt.dataost);
  last.set({hour:0, minute:0, second:0, milisecond:0});

  if(punkt.tryb == 4){
    const lastweekday = last.weekday();
    //day==0 => mon
    //day==6 => sun
    let nextwdIndex = 0;
    for(let i = 0; i < 7; i++){
      const s = punkt.dt.charAt((lastweekday+i+1) % 7);
      if(s == '1'){
        nextwdIndex = lastweekday+i+1;
        break;
      }
    }
    if(nextwdIndex === 0) return last;

    last.add(nextwdIndex, 'days');
    //DT
  } else {
    last.add(punkt.coile, 'weeks');
  }
  return last;
}

export function applyWykonanie(json){
  const structIDMap = {};
  const structsMap = {};
  const punktyMap = {};
  const praceMap = {};
  const partsMap = {};

  const structs = json.structs;
  for(let i = 0; i < structs.length; i++){
    structs[i].id = parseInt(structs[i].id);
    structs[i].typ = parseInt(structs[i].typ);
    structIDMap[structs[i].id] = structs[i];
    structs[i].parent = parseInt(structs[i].parent);
    if(structsMap[structs[i].parent]) {
      structsMap[structs[i].parent].push(structs[i]);
    } else {
      structsMap[structs[i].parent] = [ structs[i] ];
    }
    structs[i].type = 'struct';
    structs[i].children = [];
  }
  const punkty = json.punkty;
  for(let i = 0; i < punkty.length; i++){
    punkty[i].id = parseInt(punkty[i].id);
    punkty[i].parent = parseInt(punkty[i].parent);
    if(punktyMap[punkty[i].parent]) {
      punktyMap[punkty[i].parent].push(punkty[i]);
    } else {
      punktyMap[punkty[i].parent] = [ punkty[i] ];
    }
    punkty[i].type = 'punkt';
    punkty[i].coile = parseInt(punkty[i].coile);
    punkty[i].dataost = moment(punkty[i].dataost).unix();
    punkty[i].dataplan = getClosestPastExecution(punkty[i]).unix();
    punkty[i].children = [];
  }  
  const prace = json.prace;
  for(let i = 0; i < prace.length; i++){
    prace[i].id = parseInt(prace[i].id);
    prace[i].parent = parseInt(prace[i].parent);
    prace[i].czas = parseFloat(prace[i].czas);
    prace[i].extra = parseFloat(prace[i].czas);
    if(praceMap[prace[i].parent]) {
      praceMap[prace[i].parent].push(prace[i]);
    } else {
      praceMap[prace[i].parent] = [ prace[i] ];
    }
    prace[i].type = 'praca';
    prace[i].children = [];
  }  
  const parts = json.parts;
  for(let i = 0; i < parts.length; i++){
    parts[i].id = parseInt(parts[i].id);
    parts[i].parent = parseInt(parts[i].parent);
    if(partsMap[parts[i].parent]) {
      partsMap[parts[i].parent].push(parts[i]);
    } else {
      partsMap[parts[i].parent] = [ parts[i] ];
    }
    parts[i].type = 'part';
    parts[i].children = [];
  }    

  const result = [];

  //coalesce pls
  for(let si = 0; si < structs.length; si++){
    if(!structIDMap[structs[si].parent]){
      result.push(structs[si]);
    }
    const structyForStruct = structsMap[structs[si].id];
    if(structyForStruct) {
      structyForStruct.sort(sortNazwas);
      structs[si].children = structyForStruct;
    }

    const punktyForStruct = punktyMap[structs[si].id];
    if(punktyForStruct){
      punktyForStruct.sort(sortNazwas);
      structs[si].children.push(...punktyForStruct);
    } else {
      continue;
    }
    for(let pi = 0; pi < punktyForStruct.length; pi++){
      const praceForPunkt = praceMap[punktyForStruct[pi].id];
      if(praceForPunkt){
        praceForPunkt.sort(sortNazwas);
        punktyForStruct[pi].children = praceForPunkt;
      } else {
        continue;
      }
      for(let pui = 0; pui < praceForPunkt.length; pui++){
        const partsForPraca = partsMap[praceForPunkt[pui].id];
        if(partsForPraca){
          partsForPraca.sort(sortNazwas);
          praceForPunkt[pui].children = partsForPraca;
        }
      }
    }
  }

  mainStore.wykonanie.treeDoWykonania.push(...result);
}

export function getWykonanieList(parentStructs, ff){
  mainStore.wykonanie.loading = true;
  mainStore.wykonanie.query = parentStructs;
  mainStore.wykonanie.list = [];
  return fetchJSON('/api/getWykonanie/', { parentStructs: parentStructs } )
  .then((json) => {
    if(ff) {
      json = ff(json);
    }
    applyWykonanie(json);

    mainStore.wykonanie.loading = false;
  })
  .catch(error => {
    mainStore.wykonanie.loading = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}