export default function findInTree(tree, id) {
  if(Array.isArray(tree)){
    for(let i = 0; i < tree.length; i++){
      if(tree[i].id == id) return tree[i];
      const r = findInTree(tree[i], id);
      if(r != undefined) return r;
    }
    return undefined;
  }
  for(let i = 0; i < tree.children.length; i++){
    if(tree.children[i].id == id) return tree.children[i];
    const r = findInTree(tree.children[i], id);
    if(r != undefined) return r;
  }
  return undefined;
}

