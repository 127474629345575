import treeData from 'TreeViewExampleData.json';
import Container from 'components/Container';
import { Col, Row } from 'components/Grid';
import React, { Component } from 'react';
import { view } from 'react-easy-state';
import { getStructIcon } from 'util/getStructIcon';
import Table from 'components/Table/Table';
import { praceTable } from 'model/tables/prace';
import { logout } from '../../model/login/verifyLogin';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon/index';
import ChangePassword from './../../modals/ChangePassword/index';
import { addModal } from 'stores/modal';
import NewStruct from './../../modals/NewStruct/index';
import fetchJSON from 'util/fetchJSON';


import messageList from './messageList.json';
import mainStore from '../../stores/store';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: treeData,
      selectedNode: false
    }
  }

  nodeProcessor(sourceNode) {
    return {
      id: sourceNode.id,
      caption: sourceNode.caption,
      icon: getStructIcon(sourceNode),
      children: sourceNode.children
    }
  }

  treeViewNodeSelect = (node) => {
    this.setState({ selectedNode: node })
  }

  moveOprhans = (target) => {
    if(window.confirm('Czy na pewno przenieść wszystkie sieroty jako dzieci '+target.nazwas+'?')){
      mainStore.wykonanie.loading = true;
      return fetchJSON('/api/przeniesSieroty/', {target: target.id}).then((json) => {
        //wykonanie chyba nie ma efektów?
        mainStore.wykonanie.loading = false;
        window.location.reload();
      })
      .catch(error => {
        mainStore.wykonanie.loading = false;
        mainStore.bigError = {
          message: error
        };
        console.log(error);
      });
    }
  }

  render() {
    //const selectedNode = this.state.selectedNode ? this.state.selectedNode : {};
    return (
      <Container>
        <Row>
          <Col span="4">
            <Container panel>
              <Button block big tetriary onClick={() => logout()}><Icon icon="fa fa-sign-out-alt" /> Wyloguj</Button>
              <Button block big tetriary onClick={() => addModal(ChangePassword)}><Icon icon="fa fa-key" /> Zmiana hasła</Button>
              {mainStore.currentUser.privs===127 && <Button block big tetriary onClick={() => addModal(NewStruct, {node: mainStore.currentProject.structs})} ><Icon icon="fa fa-pen" /> Edycja oddziału</Button>}
              {mainStore.currentUser.privs===127 && <Button block big tetriary onClick={() => this.moveOprhans(mainStore.selectedStruct)} ><Icon icon="fa fa-pen" /> Przenieś sieroty do {mainStore.selectedStruct.nazwas}</Button>}
            </Container>
          </Col>
          <Col span="8">
            <Container panel>
              <h3>Wiadomości</h3>
              <ul>
                {messageList.map((e, index) => 
                  <li key={index}><span className="color-green">{e.date}</span> {e.title}<br/><br/>
                    <sub>{e.content.map((es, index) => 
                      <div key={index}>{es}</div>
                    )}</sub><br/>
                  </li>
                )}
              </ul>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(App);
