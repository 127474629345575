import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import Select from 'components/Select';
import Input from 'components/Input/index';
import { Button } from 'components/Button';
import mainStore from '../../stores/store';
import { view } from 'react-easy-state';
import DateInput from 'components/Input/DateInput';
import moment from 'moment';
import { trybyPrac } from '../../model/punkt/punktModel';
import { TextArea } from 'components/TextArea';
import { savePunkt, deletePunkt } from '../../model/punkt/savePunkt';
import Icon from '../../components/Icon/index';
import { getStructIcon } from '../../util/getStructIcon';
import Tab from '../../components/Tab/Tab';
import TreeView from '../../components/TreeView/TreeView';
import { getStructIconColor } from 'util/getStructIconColor';
import { sortStructs } from 'model/struct/structModel';
import { saveStruct, deleteStruct, cloneStruct } from '../../model/struct/saveStruct';
import { SmartInput } from '../../components/Input';
import { getCosieks } from '../../model/cosiek/getCosieks';

function replaceAt(string, index, replace) {
  return string.substring(0, index) + replace + string.substring(index + 1);
}

export const structTypes = [
  {id: '19_2', nazwas: 'repozytorium prac', typ: 19, rstruct: 2},
  {id: '19_1', nazwas: 'repozytorium czynności', typ: 19, rstruct: 1},
  {id: '20_0', nazwas: 'magazyn', typ: 20, rstruct: 0},
  {id: '3_0', nazwas: 'oddział', typ: 3, rstruct: 0},
  {id: '0_0', nazwas: 'inny', typ: 0, rstruct: 0},
  {id: '1_0', nazwas: 'projekt', typ: 1, rstruct: 0},
  {id: '101_0', nazwas: 'maszyna', typ: 101, rstruct: 0},
  {id: '102_0', nazwas: 'sekcja', typ: 102, rstruct: 0},
  {id: '103_0', nazwas: 'układ', typ: 103, rstruct: 0},
  {id: '104_0', nazwas: 'podsystem', typ: 104, rstruct: 0},
  {id: '105_0', nazwas: 'układ', typ: 105, rstruct: 0},
];

const structMap = {};
for(let i = 0; i < structTypes.length; i++){
  structMap[structTypes[i].id] = structTypes[i];
}

class NewStruct extends Component {

  constructor(props) {
    super(props);

    this.state = {
      node: {...this.props.node},
      newNode: {
        id: -1,
        nazwa: '',
        nazwas: '',
        nrinw: '',
        opis: '',
        parent: -1,
        typ: 0,
        rstruct: 0
      },
      mode: "edit",
      selectedStructID: 0
    }

    mainStore.bigLoading = true;
    getCosieks().then(() => {
      this.state.newPartID = mainStore.cosieks[0].id;
      mainStore.bigLoading = false;
    }).catch((err) => {
      mainStore.bigLoading = false;
    })    
  }

  nodeProcessor(sourceNode) {
    return {
      id: sourceNode.id,
      caption: sourceNode.nazwas,
      icon: getStructIcon(sourceNode),
      iconColor: getStructIconColor(sourceNode),
      children: sourceNode.children.sort(sortStructs)
    }
  }

  treeViewNodeSelect = (node) => {
    this.setState({
      selectedStructID: node.id
    })
  }

  nodeExpandClick = (nodeID) => {
    if(mainStore.openStructMap[nodeID]){
      mainStore.openStructMap[nodeID] = false;
    } else {
      mainStore.openStructMap[nodeID] = true;
    }
  }  

  CosiekAccessor = (c) => ({key:c.id, value:c.nazwas});

  deleteStruct = () => {
    deleteStruct(this.props.node.id).then(() => {
      this.props.closeAction(true);
    });
  }

  saveStruct = () => {
    const newObject = {
      id: this.props.node.id,
      typ: this.state.node.typ,
      rstruct: this.state.node.rstruct,
      nazwa: this.state.node.nazwa,
      nazwas: this.state.node.nazwas,
      opis: this.state.node.opis,
      nrinw: this.state.node.nrinw, 
      parent: this.props.node.parent,
      rodzm: this.state.node.rodzm,
      grewid: this.state.node.grewid,
    }
    saveStruct(newObject).then(() => {
      this.props.closeAction(true);
    });
  }

  addChild = () => {
    const newObject = {
      id: -1,
      typ: this.state.newNode.typ,
      rstruct: this.state.newNode.rstruct,
      nazwa: this.state.newNode.nazwa,
      nazwas: this.state.newNode.nazwas,
      opis: this.state.newNode.opis,
      nrinw: this.state.newNode.nrinw,
      rodzm: this.state.newNode.rodzm,
      grewid: this.state.newNode.grewid,
      parent: this.props.node.id,
    }
    console.log(this.state.newNode, newObject);
    saveStruct(newObject).then(() => {
      this.props.closeAction(true);
    });
  }

  addSibling = () => {
    const newObject = {
      id: -1,
      typ: this.state.newNode.typ,
      rstruct: this.state.newNode.rstruct,
      nazwa: this.state.newNode.nazwa,
      nazwas: this.state.newNode.nazwas,
      opis: this.state.newNode.opis,
      nrinw: this.state.newNode.nrinw, 
      rodzm: this.state.newNode.rodzm,
      grewid: this.state.newNode.grewid,
      parent: this.props.node.parent
    }
    saveStruct(newObject).then(() => {
      this.props.closeAction(true);
    });
  }

  moveStruct = () => {
    const newObject = {
      id: this.props.node.id,
      parent: this.state.selectedStructID
    }
    saveStruct(newObject, true).then(() => {
      this.props.closeAction(true);
    });
  }

  copyStruct = () => {
    //first gather ids
    const structTree = [];
    const fun = function(treeNode){
      structTree.push(treeNode.id);
      for(let i = 0; i < treeNode.children.length; i++){
        fun(treeNode.children[i]);
      }
    }
    fun(this.props.node);
    cloneStruct(structTree, this.props.node.parent, this.state.selectedStructID).then(() => {
      this.props.closeAction(true);
    });
  }

  static class = 'modal-medium';

  render() {
    return ( 
      <Container fill>
        <h1> Struktura: <Icon icon={getStructIcon(this.state.node)} /> {this.props.node.nazwas} <sub>{this.props.node.id}</sub></h1>
        <Row>
          <Col span="4">
            <Button primary={this.state.mode === 'edit'} block onClick={() => this.setState({mode: "edit"})}><Icon icon="fal fa-pen" /> Edycja</Button>
          </Col>
          <Col span="4">
            <Button primary={this.state.mode === 'add'} block onClick={() => this.setState({mode: "add"})}><Icon icon="fal fa-plus" /> Dodaj</Button>
          </Col>
          <Col span="4">
            <Button primary={this.state.mode === 'move'} block onClick={() => this.setState({mode: "move"})}><Icon icon="fal fa-folders" /> Przenieś</Button>
          </Col>
        </Row>
        <Tab value={this.state.mode} name="edit">
          <Row>
            <Col span="4">
              Typ
            </Col>
            <Col span="8">
              <Select options={structTypes} accessor={(row) => ({ key: row.id, value: row.nazwas })} value={this.state.node.typ+'_'+this.state.node.rstruct} onChange={(e) => this.setState({node: {...this.state.node, typ: structMap[e.target.value].typ, rstruct: structMap[e.target.value].rstruct}})} />
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Nazwa struktury
            </Col>
            <Col span="8">
              <Input placeholder="Nazwa" value={this.state.node.nazwa} onChange={(e) => this.setState({node: {...this.state.node, nazwa: e.target.value}})} />
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Nazwa krótka
            </Col>
            <Col span="8">
              <Input placeholder="Nazwa skrócona" value={this.state.node.nazwas} onChange={(e) => this.setState({node: {...this.state.node, nazwas: e.target.value}})} />
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Numer inwentarza
            </Col>
            <Col span="8">
              <Input placeholder="Numer inwentarza" value={this.state.node.nrinw} onChange={(e) => this.setState({node: {...this.state.node, nrinw: e.target.value}})} />
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Opis
            </Col>
            <Col span="8">
              <TextArea placeholder="Opis" value={this.state.node.opis} onChange={(e) => this.setState({node: {...this.state.node, opis: e.target.value}})} />
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Środek smarny
            </Col>
            <Col span="8">
              <SmartInput value={this.state.node.rodzm} onChange={(newValue) => this.setState({node: {...this.state.node, rodzm: newValue}})} options={mainStore.cosieks} accessor={this.CosiekAccessor} resultCount={3}/>
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Pojemność
            </Col>
            <Col span="8">
              <Input value={this.state.node.grewid} onChange={(e) => this.setState({node: {...this.state.node, grewid: e.target.value}})}/>
            </Col>
          </Row>          
          <Row>
            <Col span="6">
              <Button secondary block onClick={this.deleteStruct}><Icon icon="fal fa-trash" />Usuń Strukturę</Button>
            </Col>
            <Col span="6">
              <Button green block onClick={this.saveStruct}><Icon icon="fal fa-save" /> Zapisz Strukturę</Button>
            </Col>
          </Row>
        </Tab>
        <Tab value={this.state.mode} name="add">
          <Row>
            <Col span="4">
              Typ
            </Col>
            <Col span="8">
              <Select options={structTypes} accessor={(row) => ({ key: row.id, value: row.nazwas })} value={this.state.newNode.typ+'_'+this.state.newNode.rstruct} onChange={(e) => this.setState({newNode: {...this.state.newNode, typ: structMap[e.target.value].typ, rstruct: structMap[e.target.value].rstruct}})} />
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Nazwa struktury
            </Col>
            <Col span="8">
              <Input placeholder="Nazwa" value={this.state.newNode.nazwa} onChange={(e) => this.setState({newNode: {...this.state.newNode, nazwa: e.target.value}})} />
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Nazwa krótka
            </Col>
            <Col span="8">
              <Input placeholder="Nazwa skrócona" value={this.state.newNode.nazwas} onChange={(e) => this.setState({newNode: {...this.state.newNode, nazwas: e.target.value}})} />
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Numer inwentarza
            </Col>
            <Col span="8">
              <Input placeholder="Numer inwentarza" value={this.state.newNode.nrinw} onChange={(e) => this.setState({newNode: {...this.state.newNode, nrinw: e.target.value}})} />
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Opis
            </Col>
            <Col span="8">
              <TextArea placeholder="Opis" value={this.state.newNode.opis} onChange={(e) => this.setState({newNode: {...this.state.newNode, opis: e.target.value}})} />
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Środek smarny
            </Col>
            <Col span="8">
              <SmartInput value={this.state.newNode.rodzm} onChange={(newValue) => this.setState({newNode: {...this.state.newNode, rodzm: newValue}})} options={mainStore.cosieks} accessor={this.CosiekAccessor} resultCount={3}/>
            </Col>
          </Row>
          <Row>
            <Col span="4">
              Pojemność
            </Col>
            <Col span="8">
              <Input value={this.state.newNode.grewid} onChange={(e) => this.setState({newNode: {...this.state.newNode, grewid: e.target.value}})}/>
            </Col>
          </Row>          
          <Row>
            <Col span="6">
              <Button green block onClick={this.addChild}><Icon icon="fal fa-arrow-alt-to-right" /> Dodaj jako dziecko zaznaczonego</Button>            
            </Col>
            <Col span="6">
              <Button green block onClick={this.addSibling}><Icon icon="fal fa-arrow-alt-to-bottom" /> Dodaj jako dziecko rodzica</Button>
            </Col>
          </Row>
        </Tab>
        <Tab value={this.state.mode} name="move">
          <Row>
            <Col span="12">
              <TreeView data={mainStore.currentProject.structs} nodeClick={this.treeViewNodeSelect}
                      selected={this.state.selectedStructID} nodeProcessor={this.nodeProcessor}
                      nodeExpandClick={this.nodeExpandClick} openNodeMap={mainStore.openStructMap} />
            </Col>
          </Row>
          <Row>
            <Col span="6">
              <Button green block onClick={this.moveStruct}><Icon icon="fal fa-long-arrow-alt-right" /> Przenieś do elementu</Button>            
            </Col>
            <Col span="6">
              <Button green block onClick={this.copyStruct}><Icon icon="fal fa-copy" /> Skopiuj do elementu</Button>
            </Col>
          </Row>
        </Tab>
      </Container>
    );
  }
}

export default view(NewStruct);
