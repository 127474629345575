import React from 'react';
import './Text.css'
import { view } from 'react-easy-state';

export default view((props) => (
  <span className={
    (props.primary ? 'text-primary ' : '') + 
    (props.secondary ? 'text-secondary ' : '') + 
    (props.tetriary ? 'text-tetriary ' : '') + 
    (props.danger ? 'text-danger ' : '') + 
    (props.green ? 'text-green ' : '') + 
    (props.red ? 'text-red ' : '') + 
    (props.light ? 'text-light ' : '') + 
    (props.lighter ? 'text-lighter ' : '') +
    (props.tallLine ? 'text-tall-line ' : '') +
    (props.className ? props.className : '')
  } onClick={props.onClick}>{props.children}</span>
))