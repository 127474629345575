import { collateObject } from "util/collation";
import moment from "moment";

export function transformWykonanieFromJSON(o, fieldFilter) {
  return collateObject(o, wykonanieCollation, fieldFilter);      
}

export function sortWykonanie(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}

const wykonanieCollation = {
  id: 'int',
  nazwas: 'string',
  type: 'string'
}

export function getWykRecord(pozPlanID, structID, punktID, pracaID, repoPracaID, raport, info, dataplan, czas, workers, start, end, materialy, awaryjna){
  console.log(dataplan, moment.unix(dataplan).format('YYYY-MM-DD hh:mm:ss'), start, end);
  return {
    id: parseInt(pozPlanID),
    structID: parseInt(structID),
    punktID: parseInt(punktID),
    pracaID: parseInt(pracaID),
    repoPracaID: parseInt(repoPracaID),

    dataplan: moment(dataplan).unix(),
    czas: parseInt(czas),
    workers: workers.map((w) => typeof(w) === 'object' ? parseInt(w.id) : parseInt(w)),
    start: parseFloat(start),
    end: parseFloat(end),
    materialy: materialy.map((m) => ({
      id: parseInt(m.id ? m.id : -1),
      cosiek: parseInt(m.cosiek),
      ile: parseFloat(m.ile)
    })),
    raport: raport,
    info: info,
    awaryjna: awaryjna ? 1 : 0
  }
}