const map = {
  9: 'kg',
  8: 'L', 
  2916: 'szt'
}

function getJMFromCosiek(cosiek){
  if(map[cosiek.volumetyp]!==undefined) return map[cosiek.volumetyp];
  return 'błąd';
}

module.exports = getJMFromCosiek;