import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformPunktFromJSON } from './punktModel';
import { getStructChildren } from './../struct/getStructChildren';

function addChildren(obj){
  obj.children = [];
  return obj;
}

export function savePunkt(punkt, orgRepo){
  mainStore.bigLoader = true;
  return fetchJSON('/api/savePunkt/', {punkt: punkt, orgRepo: orgRepo})
  .then((json) => {
    /*if(punkt.id==-1){
      mainStore.prace.structChildren.push(addChildren(transformPunktFromJSON(json)));
    } else {
      const i = mainStore.prace.structChildren.findIndex((e) => e.id == punkt.id);
      const no = addChildren(transformPunktFromJSON(json));
      if(i>=0){
        mainStore.prace.structChildren[i] = no;
      } else {
        mainStore.prace.structChildren.push(no);
      }
      mainStore.selectedPunkt = no;
    }*/
    //we need to redownload the whole tree again.
    return getStructChildren(mainStore.selectedStruct.id)
  }).then( () => {
    mainStore.bigLoader = false;
  })
  .catch(error => {
    mainStore.bigLoader = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}

export function deletePunkt(punktID){
  mainStore.bigLoader = true;
  return fetchJSON('/api/deletePunkt/'+punktID)
  .then((json) => {
    mainStore.bigLoader = false;
    mainStore.prace.structChildren = mainStore.prace.structChildren.filter((v) => v.id!=punktID);
    if(mainStore.selectedPunkt.id == punktID){
      mainStore.selectedPunkt = undefined;
      mainStore.selectedPraca = undefined;
      mainStore.selectedPart = undefined;
    }
  })
  .catch(error => {
    mainStore.bigLoader = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}