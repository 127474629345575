import Container from 'components/Container';
import { Col, Row, RowSet } from 'components/Grid';
import TreeView from 'components/TreeView';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { addModal } from 'stores/modal';
import { getStructIcon } from 'util/getStructIcon';
import Icon from 'components/Icon';
import Text from 'components/Text/Text';
import Table from 'components/Table/Table';
import TextArea from 'components/TextArea/index';
import { punktyTable } from 'model/tables/punkty';
import { praceTable } from 'model/tables/prace';
import { materialyTable } from 'model/tables/materialy';
import NewPunkt from 'modals/NewPunkt';
import NewPraca from 'modals/NewPraca';
import NewMaterial from 'modals/NewMaterial/NewMaterial';
import { store, view } from 'react-easy-state';
import { mainStore } from 'stores/store';
import { getStructIconColor } from 'util/getStructIconColor';
import { sortStructs } from 'model/struct/structModel';
import { getPunktForStruct } from 'model/punkt/getPunktForStruct';
import SmallLoader from '../../components/Loading/SmallLoader';
import { getPrace } from '../../model/praca/getPrace';
import { getPart } from './../../model/part/getPart';
import moment from 'moment';
import findInTree from '../../util/findInTree';
import updateURL from '../../util/updateURL';
import { getStructChildren } from '../../model/struct/getStructChildren';
import Button from './../../components/Button/index';
import Loading from './../../components/Loading/index';
import NewStruct from './../../modals/NewStruct/index';
import { structTypes } from '../../modals/NewStruct/NewStruct';
import { Label } from 'components/Label/index';
import { getCosieks } from '../../model/cosiek/getCosieks';
import { saveOSMToLS } from '../../util/localStorageSetting';

function getStructTypeCaption(struct) {
  const st = structTypes.find((e) => e.id == struct.typ+'_'+struct.rstruct);
  if(st) return st.nazwas;
  return 'Błędny typ struktury';
}

function SortNazwas(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}

class Prace extends Component {

  constructor(props){
    super(props);
    this.state = {
      pos: [0, 0],
      node: undefined
    }
    getCosieks().then(() => {
      this.state.newPartID = mainStore.cosieks[0].id;
      mainStore.bigLoading = false;
    }).catch((err) => {
      mainStore.bigLoading = false;
    })
  }

  nodeProcessor(sourceNode) {
    return {
      id: sourceNode.id,
      caption: sourceNode.nazwas,
      icon: getStructIcon(sourceNode),
      iconColor: getStructIconColor(sourceNode),
      children: sourceNode.children.sort(sortStructs)
    }
  }

  updateURL = () => {
    updateURL(this.props, undefined, {
      pid: mainStore.currentProjectID,
      structid: mainStore.selectedStruct.id,
      punktid: mainStore.selectedPunktID,
      pracaid: mainStore.selectedPracaID,
      partid: mainStore.selectedPartID
    });
  }

  treeViewNodeSelect = (node) => {
    getStructChildren(node.id).then(() => {
      this.selectFirstPunkt();
      mainStore.selectedStruct = node;
    });
  }

  nodeExpandClick = (nodeID) => {
    if(mainStore.openStructMap[nodeID]){
      mainStore.openStructMap[nodeID] = false;
    } else {
      mainStore.openStructMap[nodeID] = true;
    }
    saveOSMToLS();
    this.forceUpdate();
  }

  selectFirstPunkt = () => {
    if(mainStore.prace.structChildren.length > 0){
      mainStore.selectedPunkt = mainStore.prace.structChildren[0];
      //mainStore.selectedPunkt.children.sort(SortNazwas);
    } else {
      mainStore.selectedPunkt = undefined;
    }
    this.selectFirstPraca();
  }

  selectFirstPraca = () => {
    if(!mainStore.selectedPunkt) {
      mainStore.selectedPraca = undefined;
      return;
    }
    if(mainStore.selectedPunkt && mainStore.selectedPunkt.children.length > 0){
      mainStore.selectedPraca = mainStore.selectedPunkt.children[0];
      //mainStore.selectedPraca.children.sort(SortNazwas);
    } else {
      mainStore.selectedPraca = undefined;
    }
    this.selectFirstPart();
  }

  selectFirstPart = () => {
    if(!mainStore.selectedPraca) {
      mainStore.selectedPart = undefined;
      return;
    }

    if(mainStore.selectedPraca && mainStore.selectedPraca.children.length > 0){
      mainStore.selectedPart = mainStore.selectedPraca.children[0];
    } else {
      mainStore.selectedPart = undefined;
    }
  }

  editNode = (e, node) => {
    if(node.id==mainStore.selectedStruct.id){
      addModal(NewStruct, {node: node});
    } else {
      getStructChildren(node.id).then(() => {
        this.selectFirstPunkt();
        mainStore.selectedStruct = node;
        addModal(NewStruct, {node: node});
      });
    }
  }

  render() {
    const selectedNode = mainStore.selectedStruct ? mainStore.selectedStruct : {};

    let spDataOst = moment();
    let spDataNast = moment();
    if(mainStore.selectedPunkt!==undefined) {
      spDataOst = moment.unix(mainStore.selectedPunkt.dataost);
      spDataNast = moment.unix(mainStore.selectedPunkt.datanast);
    }
    const now = moment();
    const datediff = spDataNast.diff(now, 'days');
    
    let sc = mainStore.cosieks.find((el) => el.id == selectedNode.rodzm);
    if(sc===undefined){
      sc = {nazwas: ''};
    }
    console.log(mainStore);

    return (
      <Container>
        {mainStore.prace.loading && <Loading /> }
        <Row flex>
          <Col span="3">
            <Container panel vfull vscrolled>
              {mainStore.currentProject && 
                <TreeView data={mainStore.currentProject.structs} nodeClick={this.treeViewNodeSelect}
                      selected={selectedNode.id} nodeProcessor={this.nodeProcessor}
                      nodeExpandClick={this.nodeExpandClick} openNodeMap={mainStore.openStructMap}
                      rightClick={this.editNode} />
              }
            </Container>
          </Col>
          <Col span="4" >
            <Container panel vfull vscrolled relative>
              <Row>
                <Col span="12">
                  <h3> Prace </h3>
                  <Table 
                    cols={praceTable} 
                    data={mainStore.prace.structChildren} 
                    onClick={(el) => {
                      mainStore.selectedPunkt = el;
                      this.selectFirstPraca();
                    } }
                    newRowClick={() => mainStore.selectedStruct && addModal(NewPunkt, undefined)}
                    editRowClick={(row) => addModal(NewPunkt, {punkt: row})}
                    selectedID={mainStore.selectedPunkt ? mainStore.selectedPunkt.id : -1}
                    edit={mainStore.selectedStruct !== undefined}
                  />
                  <h3> Czynności </h3>
                  <Table 
                    cols={praceTable} 
                    data={mainStore.selectedPunkt ? mainStore.selectedPunkt.children : []} 
                    onClick={(el) => {
                      mainStore.selectedPraca = el;
                      this.selectFirstPart();
                    } }
                    newRowClick={() => mainStore.selectedPunkt && addModal(NewPraca)}  
                    editRowClick={(row) => addModal(NewPraca, {praca: row})}                    
                    selectedID={mainStore.selectedPraca ? mainStore.selectedPraca.id : -1}
                    edit                                     
                  />
                  <h3> Materiały </h3>
                  <Table 
                    cols={materialyTable} 
                    data={mainStore.selectedPraca ? mainStore.selectedPraca.children : []} 
                    onClick={(el) => {
                      mainStore.selectedPart = el;
                    } } 
                    newRowClick={() => mainStore.selectedPunkt && addModal(NewMaterial)}  
                    editRowClick={(row) => addModal(NewMaterial, {material: row})}                    
                    selectedID={mainStore.selectedPart ? mainStore.selectedPart.id : -1}
                    edit                    
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col span="5">          
            <Container panel vfull vscrolled relative>
              <Row>
                <Col span="12">
                  <h3> Struktura </h3>
                  {selectedNode &&
                    <Container panel>
                      <RowSet caption="Typ:"><Label>{ getStructTypeCaption(selectedNode) }</Label></RowSet>
                      <RowSet caption="Nazwa:"><Label>{selectedNode.nazwa}</Label></RowSet>
                      <RowSet caption="Nazwa Skrócona:"><Label>{selectedNode.nazwas}</Label></RowSet>
                      <RowSet caption="Nrinw:"><Label>{selectedNode.nrinw}</Label></RowSet>
                      <RowSet caption="Opis:"><Label>{selectedNode.opis}</Label></RowSet>
                      <RowSet caption="Środek Smarny:"><Label>{sc.nazwas}</Label></RowSet>
                      <RowSet caption="Pojemność:"><Label>{selectedNode.grewid}</Label></RowSet>
                    </Container>
                  }
                  <h3> Praca </h3>
                    {mainStore.selectedPunkt && 
                      <Container panel>
                        <Row>
                          <Col span="12">
                            <Text light>ID:</Text> <Text primary>{mainStore.selectedPunkt ? mainStore.selectedPunkt.id : 'Brak zaznaczonego punktu'}</Text> <br />
                            {(mainStore.selectedPunkt.tryb === 1) && 
                              <span><Text light>Obsługa:</Text> Co {mainStore.selectedPunkt.coile} tygodni</span>
                            }
                            {(mainStore.selectedPunkt.tryb === 4) && 
                              <Row>
                                <Col span="5">
                                  <Text light>Obsługa:</Text> Dni tygodnia
                                </Col>
                                <Col span="1">
                                  { mainStore.selectedPunkt.dt.charAt(0) === '1' ? <Button green block nolrpadding>Pon</Button> : <Button red block nolrpadding>Pon</Button> }
                                </Col>
                                <Col span="1">
                                  { mainStore.selectedPunkt.dt.charAt(1) === '1' ? <Button green block nolrpadding>Wt</Button> : <Button red block nolrpadding>Wt</Button> }
                                </Col>
                                <Col span="1">
                                  { mainStore.selectedPunkt.dt.charAt(2) === '1' ? <Button green block nolrpadding>Śr</Button> : <Button red block nolrpadding>Śr</Button> }
                                </Col>
                                <Col span="1">
                                  { mainStore.selectedPunkt.dt.charAt(3) === '1' ? <Button green block nolrpadding>Czw</Button> : <Button red block nolrpadding>Czw</Button> }
                                </Col>
                                <Col span="1">
                                  { mainStore.selectedPunkt.dt.charAt(4) === '1' ? <Button green block nolrpadding>Pt</Button> : <Button red block nolrpadding>Pt</Button> }
                                </Col>
                                <Col span="1">
                                  { mainStore.selectedPunkt.dt.charAt(5) === '1' ? <Button green block nolrpadding>Sob</Button> : <Button red block nolrpadding>Sob</Button> }
                                </Col>
                                <Col span="1">
                                  { mainStore.selectedPunkt.dt.charAt(6) === '1' ? <Button green block nolrpadding>Nd</Button> : <Button red block nolrpadding>Nd</Button> }
                                </Col>
                              </Row>
                            }
                            {(mainStore.selectedPunkt.tryb === 202) && 
                              <span><Text light>Obsługa:</Text> Jednorazowa </span>
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col span="4">
                            <Text light>Start obsługi:</Text>
                          </Col>
                          <Col span="1">
                            {spDataOst.year()}
                          </Col>
                          <Col span="2">
                            {spDataOst.week()}
                          </Col>
                          <Col span="3">
                            {spDataOst.format('YYYY-MM-DD')}
                          </Col>
                          <Col span="2">
                          </Col>
                        </Row>                        
                        {/*<Row>
                          <Col span="4">
                            <Text light>Następna obsługa:</Text>
                          </Col>
                          <Col span="1">
                            {spDataNast.year()}
                          </Col>
                          <Col span="2">
                            {spDataNast.week()}
                          </Col>
                          <Col span="3">
                            {spDataNast.format('YYYY-MM-DD')}
                          </Col>
                          <Col span="2">
                          </Col>
                        </Row>*/}
                        {datediff < 0 && 
                          <Row>
                            <Col span="4">
                              <Text light danger >Praca przeterminowana:</Text>
                            </Col>
                            <Col span="8">
                              {-datediff} dni
                            </Col>
                          </Row>
                        }
                        {datediff >= 0 && 
                          <Row>
                            <Col span="4">
                              <Text light >Praca zaplanowana za:</Text>
                            </Col>
                            <Col span="8">
                              {datediff} dni
                            </Col>
                          </Row>
                        }
                        <TextArea rows="4" placeholder="Dyspozycje" value={mainStore.selectedPunkt.info}/>
                      </Container>
                    }
                  <h3> Czynność </h3>
                    {mainStore.selectedPraca && 
                      <Container panel>
                        <Row>
                          <Col span="12">
                            <RowSet caption="ID:"><Label>{ mainStore.selectedPraca.id }</Label></RowSet>
                            <RowSet caption="Nazwa:"><Label>{ mainStore.selectedPraca.nazwa }</Label></RowSet>
                            <RowSet caption="Nazwa skrócona:"><Label>{ mainStore.selectedPraca.nazwas }</Label></RowSet>
                            <RowSet caption="Symbol Instrukcji:"><Label>{ mainStore.selectedPraca.instrukcja }</Label></RowSet>
                            <RowSet caption="Czas wykonania(m):"><Label>{ mainStore.selectedPraca.czas }</Label></RowSet>
                            <RowSet caption="Opis:"><Label>{ mainStore.selectedPraca.info }</Label></RowSet>
                          </Col>
                        </Row>
                      </Container>
                    }                    
                  </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(Prace);
