import Container from 'components/Container';
import React, { Component } from 'react';
import { Button } from 'components/Button';
import { Row, Col } from 'components/Grid';
import Icon from 'components/Icon/index';
import { Route } from 'react-router-dom';
import Brygady from 'screens/Administracja/Brygady';
import Kontrahenci from 'screens/Administracja/Kontrahenci';
import Pracownicy from 'screens/Administracja/Pracownicy';
import Towary from 'screens/Administracja/Towary';
import Stanowiska from 'screens/Administracja/Stanowiska';
import Cenniki from 'screens/Administracja/Cenniki';
import Roll20 from './Roll20/index';

class Second extends Component {

  render() {
    return (
      <Container>
        <Row>
          <Col span="3">
            <Container halfSpace panel vfull vscrolled>
              <h1> Administracja </h1>
              <Button spaced primary block to="/admin/towar"><Icon icon="fa fa-box" /> Materiały</Button>
              <Button spaced primary block to="/admin/pracownicy"><Icon icon="fa fa-user" /> Pracownicy</Button>
              {/*<Button spaced primary block to="/admin/roll20"><Icon icon="fa fa-dice" /> Roll20</Button>*/}
              {/*<Button spaced primary block to="/admin/brygady"><Icon icon="fa fa-users" /> Brygady</Button>*/}
              {/*<Button spaced primary block to="/admin/stanowiska"><Icon icon="fa fa-id-badge" /> Stanowiska</Button>*/}
{/*              <Button spaced primary block to="/admin/cenniki"><Icon icon="far fa-list-alt" /> Cenniki</Button>
              <Button spaced primary block><Icon icon="far fa-clipboard-list" /> Cenniki prototypowe</Button>
              <Button spaced primary block><Icon icon="fal fa-ruler" /> Jednostki</Button>
              <Button spaced primary block><Icon icon="fal fa-receipt" /> Fakturowanie</Button>
    <Button spaced primary block><Icon icon="fal fa-wrench" /> Tryby obsługi</Button>*/}
            </Container>
          </Col>
          <Col span="9">
            <Route path="/admin/towar" component={Towary} />
            <Route path="/admin/roll20" component={Roll20} />
            <Route path="/admin/kontrahenci" component={Kontrahenci} />
            <Route path="/admin/pracownicy" component={Pracownicy} />
            <Route path="/admin/brygady" component={Brygady} />
            <Route path="/admin/stanowiska" component={Stanowiska} />
            <Route path="/admin/cenniki" component={Cenniki} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Second;
