import { collateObject } from "util/collation";

import pracaCollation from 'model/praca.js'

export function transformPracaFromJSON(o, fieldFilter) {
  return collateObject(o, pracaCollation, fieldFilter);      
}

export function sortPrace(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}