import mainStore from "../stores/store";

export const creds = {
  key: undefined,
  id: undefined
}

function checkResponseCode(response){
  if(!response.ok){
    //some kind of bad response.
    return Promise.reject(Error('Invalid response status: '+response.status))
  }
  return response;
}

export default function fetchJSON(url, data, sendAPIKey = true) {
  if(sendAPIKey){
    if(!data) data = {};
    data.__key = creds.key;
    data.__id = creds.id;
  }
  
  data = JSON.stringify(data);
  const options = {
    method: 'POST',
    headers: {
       'Content-Type': 'application/json',
       'Accept': 'application/json'
       },
    body: data
  };
  return fetch(url, options)
    .then(checkResponseCode)
    .then((response) => response.json())
    .then((json) => {
      if( (typeof(json) === 'object') && (json.error)){
        return Promise.reject(json);
      }
      if( (typeof(json) === 'object') && (json.invalidAPIKey)){
        mainStore.loggedIn = false;
        mainStore.message = 'API Key invalid, please log in.';
        return Promise.reject(json);
      }      
      else return json;
    });
}
