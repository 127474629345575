import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Input } from 'components/Input';
import Table from 'components/Table/index';
import { stanowiskaTable, stanowiskaData } from 'model/tables/stanowiska';
import RowSet from 'components/Grid/RowSet';

class Stanowiska extends Component {

  state = {
    currentRow: undefined
  }

  

  render() {
    return (
      <Container fill>
        <h1>Stanowiska</h1>
        <Row>
          <Col span="4">
            <Table cols={stanowiskaTable} data={stanowiskaData} onClick={(row) => this.setState({currentRow: row})} edit />
          </Col>
        { this.state.currentRow && (
          <Col span="8">
            <RowSet caption="Nazwa:"><Input value={this.state.currentRow.name} /></RowSet>
            <RowSet caption="Skrót nazwy:"> <Input value={this.state.currentRow.skname} /></RowSet>
            <RowSet caption="Pozycja cennika:"> <Input value={this.state.currentRow.grupaname} /></RowSet>
            <RowSet caption="Nazwa cennikowa:"> <Input value={this.state.currentRow.grupaname} /></RowSet>
            <RowSet caption="Stawka:"> <Input value={this.state.currentRow.grupaname} /></RowSet>
          </Col>
        ) }
        </Row>
      </Container>
    );
  }
}

export default Stanowiska;
