import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';

export function getPozplans(pozplanIDs){
  return fetchJSON('/api/getPozplans/', { 
    pozplanIDs: pozplanIDs
  } ).then((json) => {
    return json;
  })
  .catch(error => {
    mainStore.bigError = {
      message: error
    };
    console.log(error);
    throw error;
  });
}