import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import Select from 'components/Select';
import Input from 'components/Input/index';
import { Button } from 'components/Button';
import mainStore from '../../stores/store';
import { view } from 'react-easy-state';
import DateInput from 'components/Input/DateInput';
import moment from 'moment';
import { trybyPrac } from '../../model/punkt/punktModel';
import { TextArea } from 'components/TextArea';
import { savePunkt, deletePunkt } from '../../model/punkt/savePunkt';
import Icon from './../../components/Icon/index';

function replaceAt(string, index, replace) {
  return string.substring(0, index) + replace + string.substring(index + 1);
}

class NewPunkt extends Component {

  constructor(props){
    super(props);

    let punkt;
    if(this.props.punkt){
      punkt = {...this.props.punkt};
      punkt.dataost = moment.unix(punkt.dataost).toDate();
      punkt.datanast = moment.unix(punkt.datanast).toDate();
    } else {
      punkt = mainStore.currentProject.repoPunkt.sorted[0];
      punkt.dataost = new Date();
      punkt.datanast = this.getNextDate(new Date(), punkt.coile);
    }
    if(punkt === undefined){ 
      punkt = {
        dt: '0000000'
      };
    }
    this.state = {
      punkt: {
        ...punkt
      },
      orgRepo: -1
    }
  }

  static class = 'modal-small';

  savePunkt = () => {
    const newObject = {
      id: -1,
      nazwa: this.state.punkt.nazwa,
      nazwas: this.state.punkt.nazwas,
      dataost: moment(this.state.punkt.dataost).unix(),
      datanast: moment(this.state.punkt.datanast).unix(),
      tryb: parseInt(this.state.punkt.tryb),
      dt: this.state.punkt.dt,
      coile: parseInt(this.state.punkt.coile),
      prior: parseInt(this.state.punkt.prior),
      info: this.state.punkt.info,
      parent: mainStore.selectedStruct.id
    }
    if(this.props.punkt){
      newObject.id = this.props.punkt.id;
    }
    savePunkt(newObject, this.state.orgRepo).then(() => {
      this.props.closeAction(true);
    });
  }

  deletePunkt = () => {
    if(!this.props.punkt) return;
    deletePunkt(this.props.punkt.id).then(() => {
      this.props.closeAction(true);
    });
  }

  getNextDate(date, tyg){
    return moment(date).add(parseInt(tyg), 'weeks').toDate()
  }

  chooseRepo = (e) => {
    const punkt = mainStore.currentProject.repoPunkt.idMap[e.target.value];

    this.setState({
      punkt: {
        ...punkt, 
        dataost: new Date(),
        datanast: this.getNextDate(new Date(), punkt.coile),
      },
      orgRepo: punkt.id
    })
  }

  render() {
    return ( 
      <Container fill>
        <h1> Praca: {this.props.v}</h1>
        {!this.props.punkt && <Row>
          <Col span="4">
            Praca(repo)
          </Col>
          <Col span="8">
            <Select options={mainStore.currentProject.repoPunkt.sorted} accessor={(row) => ({ key: row.id, value: row.nazwas })} onChange={this.chooseRepo}/>
          </Col>
        </Row> }
        <Row>
          <Col span="4">
            Nazwa pracy
          </Col>
          <Col span="8">
            <Input placeholder="Proszę wpisać nazwę pracy" value={this.state.punkt.nazwa} onChange={(e) => this.setState({punkt: {...this.state.punkt, nazwa: e.target.value}})} />
          </Col>
        </Row>
        <Row>
          <Col span="4">
            Nazwa krótka
          </Col>
          <Col span="8">
            <Input placeholder="Nazwa skrócona" value={this.state.punkt.nazwas} onChange={(e) => this.setState({punkt: {...this.state.punkt, nazwas: e.target.value}})} />
          </Col>
        </Row>
        <Row>
          <Col span="4">
            Tryb
          </Col>
          <Col span="8">
            <Select options={trybyPrac} value={this.state.punkt.tryb} accessor={(row) => ({ key: row.id, value: row.nazwas + ': ' + row.nazwa })} onChange={(e) => this.setState({punkt: {...this.state.punkt, tryb: e.target.value}})}/>
          </Col>
        </Row>
        { //Dni Tygodnia
          (this.state.punkt.tryb == 4) && <Row>
            <Col span="4">
              Dni Tygodnia
            </Col>
            <Col span="1">
              {
                (this.state.punkt.dt.charAt(0)=='1') ?
                <Button nolrpadding block green onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 0, '0') } } ) } } >Pon</Button>
                :
                <Button nolrpadding block secondary onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 0, '1') } } ) } } >Pon</Button>
              }
            </Col>
            <Col span="1">
            {
                (this.state.punkt.dt.charAt(1)=='1') ?
                <Button nolrpadding block green onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 1, '0') } } ) } } >Wt</Button>
                :
                <Button nolrpadding block secondary onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 1, '1') } } ) } } >Wt</Button>
              }
            </Col>
            <Col span="1">
            {
                (this.state.punkt.dt.charAt(2)=='1') ?
                <Button nolrpadding block green onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 2, '0') } } ) } } >Śr</Button>
                :
                <Button nolrpadding block secondary onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 2, '1') } } ) } } >Śr</Button>
              }
            </Col>
            <Col span="1">
            {
                (this.state.punkt.dt.charAt(3)=='1') ?
                <Button nolrpadding block green onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 3, '0') } } ) } } >Czw</Button>
                :
                <Button nolrpadding block secondary onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 3, '1') } } ) } } >Czw</Button>
              }
            </Col>
            <Col span="1">
            {
                (this.state.punkt.dt.charAt(4)=='1') ?
                <Button nolrpadding block green onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 4, '0') } } ) } } >Pt</Button>
                :
                <Button nolrpadding block secondary onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 4, '1') } } ) } } >Pt</Button>
              }
            </Col>
            <Col span="1">
            {
                (this.state.punkt.dt.charAt(5)=='1') ?
                <Button nolrpadding block green onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 5, '0') } } ) } } >Sob</Button>
                :
                <Button nolrpadding block secondary onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 5, '1') } } ) } } >Sob</Button>
              }
            </Col>
            <Col span="1">
            {
                (this.state.punkt.dt.charAt(6)=='1') ?
                <Button nolrpadding block green onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 6, '0') } } ) } } >Nd</Button>
                :
                <Button nolrpadding block secondary onClick={(e) => { this.setState({punkt: {...this.state.punkt, dt: replaceAt(this.state.punkt.dt, 6, '1') } } ) } } >Nd</Button>
              }
            </Col>
          </Row> 
        }
        { //nie dni tygodnia
          (this.state.punkt.tryb == 1 || this.state.punkt.tryb == 4) && <Row>
            <Col span="4">
              Co ile tygodni
            </Col>
            <Col span="8">
              <Input placeholder="Co ile tygodni" value={this.state.punkt.coile} onChange={(e) => this.setState({punkt: {...this.state.punkt, coile: e.target.value, datanast: this.getNextDate(this.state.punkt.dataost, e.target.value)}})} />
            </Col>
          </Row>
        }
        {/*<Row>
          <Col span="4">
            Priorytet
          </Col>
          <Col span="8">
            <Input placeholder="Priorytet" value={this.state.punkt.prior} onChange={(e) => this.setState({punkt: {...this.state.punkt, prior: e.target.value}})} />
          </Col>
        </Row>*/}
        <Row>
          <Col span="4">
            Data startu obsługi
          </Col>
          <Col span="8">
            <DateInput value={this.state.punkt.dataost} onChange={(newValue) => this.setState({punkt: {...this.state.punkt, dataost: newValue, datanast: this.getNextDate(newValue, this.state.punkt.coile)}})}/>
          </Col>
        </Row>
        {(this.state.punkt.tryb == 1 || this.state.punkt.tryb == 4) && <Row>
          <Col span="4">
            Data następnej obsługi
          </Col>
          <Col span="8">
            <Input disabled value={moment(this.state.punkt.datanast).format('YYYY-MM-DD')} />
          </Col>
        </Row>}
        <Row>
          <Col span="4">
            Informacje dodatkowe
          </Col>
          <Col span="8">
            <TextArea value={this.state.punkt.info} onChange={(e) => this.setState({punkt: {...this.state.punkt, info: e.target.value}})} />
          </Col>
        </Row>
        <Row>
          <Col span="6">
            {this.props.punkt ? <Button secondary block onClick={this.deletePunkt}><Icon icon="fal fa-trash" />Usuń Pracę</Button> : " "}
          </Col>
          <Col span="6">
            <Button green block onClick={this.savePunkt}><Icon icon="fal fa-save" /> Zapisz Pracę</Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(NewPunkt);
