import mainStore from "../stores/store";

function saveOSMToLS(){
    const ids = Object.keys(mainStore.openStructMap);
    const outArr = [];
    for(let i = 0; i < ids.length; i++){
      const id = ids[i];
      if(mainStore.openStructMap[id] === true){
        outArr.push(id);
      }
    }
    localStorage.setItem('ExpandedStructs', outArr.join(','));
}

function loadOSMFromLS(){
    const es = localStorage.getItem('ExpandedStructs');
    if(!es) {
        mainStore.openStructMap = {};
        return;
    }
    const arr = es.split(',');
    const map = {};
    for(let i = 0; i < arr.length; i++){
        map[arr[i]] = true;
    }
    mainStore.openStructMap = map;
}

export {
    saveOSMToLS,
    loadOSMFromLS
}