const struct = {
  id: 'int',
  transaction: 'int',
  active: 'bool',
  deleted: 'bool',
  imie: 'string',
  nazwisko: 'string',
  podpis: 'string',
  telefon: 'string',
  mails: 'string',
  maild: 'string',
  typ: 'int'
}

module.exports = struct;