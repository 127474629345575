import React, { Component } from 'react'
import TreeViewNode from './TreeViewNode';

import './TreeView.css'
import { view } from 'react-easy-state';

export default view((props) => {
  if(!props.openNodeMap){
    return(<span>NodeMap not provided.</span>);
  }
  const settings = {
    openNodeMap: props.openNodeMap,
    nodeExpandClick: props.nodeExpandClick,
    nodeClick: props.nodeClick,
    iconClick: props.iconClick,
    selected: props.selected,
    nodeProcessor: props.nodeProcessor,
    rightClick: props.rightClick,
    defaultExpand: props.defaultExpand ? props.defaultExpand : false
  }
  let data = props.data;
  if(!Array.isArray(props.data)){
    const node = settings.nodeProcessor ? settings.nodeProcessor(data) : data;
    data = node.children;
  }
  return (
    <div className="treeView">
      <ul>
        {data.map( (node) => (
          <TreeViewNode node={node} key={node.id} settings={settings}/>
        ))}
      </ul>
    </div>
  )
})

