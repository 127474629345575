import stringToCode from "./stringToCode";

const list = [
  'fal fa-ambulance',
  'fal fa-anchor',
  'fal fa-apple-crate',
  'fal fa-archway',
  'fal fa-archive',
  'fal fa-atom',
  'fal fa-axe',
  'fal fa-award',
  'fal fa-backpack',
  'fal fa-balance-scale',
  'fal fa-band-aid',
  'fal fa-barcode-alt',
  'fal fa-bath',
  'fal fa-battery-bolt',
  'fal fa-bell-school',
  'fal fa-bicycle',
  'fal fa-binoculars',
  'fal fa-box',
  'fal fa-box-alt',
  'fal fa-box-check',
  'fal fa-box-usd',
  'fal fa-boxes',
  'fal fa-boxes-alt',
  'fal fa-briefcase',
  'fal fa-briefcase-medical',
  'fal fa-broadcast-tower',
  'fal fa-building',
  'fal fa-bullhorn',
  'fal fa-bus',
  'fal fa-bus-alt',
  'fal fa-bus-school',
  'fal fa-business-time',
  'fal fa-cabinet-filing',
  'fal fa-calendar-alt',
  'fal fa-camera-alt',
  'fal fa-campground',
  'fal fa-car',
  'fal fa-car-battery',
  'fal fa-car-garage',
  'fal fa-car-mechanic',
  'fal fa-car-wash',
  'fal fa-chair-office',
  'fal fa-charging-station',
  'fal fa-chart-area',
  'fal fa-city',
  'fal fa-code-branch',
  'fal fa-code-merge',
  'fal fa-comments-alt',
  'fal fa-conveyor-belt',
  'fal fa-credit-card',
  'fal fa-desktop',
  'fal fa-dolly-flatbed',
  'fal fa-dolly-flatbed-empty',
  'fal fa-door-closed',
  'fal fa-fill-drip',
  'fal fa-flask-potion',
  'fal fa-forklift',
  'fal fa-gas-pump',
  'fal fa-handshake',
  'fal fa-helicopter',
  'fal fa-headphones',
  'fal fa-hospital',
  'fal fa-hospital-symbol',
  'fal fa-hotel',
  'fal fa-house-damage',
  'fal fa-industry-alt',
  'fal fa-inventory',
  'fal fa-inhaler',
  'fal fa-lightbulb-on',
  'fal fa-location',
  'fal fa-luggage-cart',
  'fal fa-medkit',
  'fal fa-megaphone',
  'fal fa-microscope',
  'fal fa-motorcycle',
  'fal fa-oil-can',
  'fal fa-oil-temp',
  'fal fa-pallet',
  'fal fa-passport',
  'fal fa-people-carry',
  'fal fa-person-dolly',
  'fal fa-phone-office',
  'fal fa-plane-departure',
  'fal fa-plane-arrival',
  'fal fa-prescription-bottle-alt',
  'fal fa-road',
  'fal fa-scanner-keyboard',
  'fal fa-server',
  'fal fa-shipping-fast',
  'fal fa-shipping-timed',
  'fal fa-ship',
  'fal fa-shopping-cart',
  'fal fa-shower',
  'fal fa-shuttle-van',
  'fal fa-skull-crossbones',
  'fal fa-subway',
  'fal fa-suitcase',
  'fal fa-suitcase-rolling',
  'fal fa-thermometer-half',
  'fal fa-traffic-light',
  'fal fa-tractor',
  'fal fa-truck-container',
  'fal fa-truck-loading',
  'fal fa-truck-monster',
  'fal fa-truck-moving',
  'fal fa-truck-pickup',
  'fal fa-users',
  'fal fa-user-cog',
  'fal fa-warehouse-alt',
  'fal fa-wrench',
]

/*export function getStructIcon(string){
  return list[ Math.abs(stringToCode(string)) % list.length];
}*/

//typ-icon map
const map = {
  20: 'fal fa-warehouse',
  3: 'fa fa-project-diagram',
  0: 'fa fa-dot-circle',
  1: 'fa fa-folder',
  19: 'fal fa-folders',
  101: 'fa fa-box-ballot',
  102: 'fa fa-conveyor-belt-alt',
  103: 'fa fa-cog',
  104: 'fa fa-cogs',
  105: 'fa fa-cog',
}

export function getStructIcon(struct){
  const r = map[struct.typ];
  if(r){
    return r;
  }
  return map[0];
}