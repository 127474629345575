import React from 'react';
import './Loading.css'
import { view } from 'react-easy-state';

export default view((props) => (
  <div className="loading">
    <div className="loading-knob loading-knob-bg">
      <i className="fas fa-cog fa-spin fa-spin-ease-other" />
    </div>
    <div className="loading-knob loading-knob-fg">
      <i className="fas fa-sync fa-spin fa-spin-ease" />
    </div>
    <div className="loading-knob loading-knob-text">
      Proszę czekać...
    </div>
  </div>
));