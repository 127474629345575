import { view } from "react-easy-state";
import React, { Component } from "react";
import './TimeChart.css'

function formatTime(time) {
  const h = parseInt(time);
  let m = parseInt((time % 1) * 60);
  if(m<10){
    m = '0'+m;
  }
  return h+':'+m;
}

class TimeChart extends Component {
  render() {
    const fullHeight = this.props.end - this.props.start + 1;
    const timeTable = [];
    const els = [];

    const orderedItems = [];

    for(let i = 0; i < this.props.plannedNodes.length; i++){
      const node = this.props.accessor(this.props.plannedNodes[i]);
      orderedItems.push({
        id: node.id,
        start: node.start,
        end: node.end,
        caption: node.caption,
        column: 0
      })
    }
    console.log(orderedItems);

    orderedItems.sort((a, b) => a.start > b.start);

    let lastTimeInColumns = [0];

    for(let i = 0; i < orderedItems.length; i++){
      let fits = false;
      for(let c = 0; c < lastTimeInColumns.length; c++){
        //console.log('col', c, lastTimeInColumns[c], orderedItems[i].start)
        if(lastTimeInColumns[c] <= orderedItems[i].start){
          orderedItems[i].column = c;
          if(orderedItems[i].end - orderedItems[i].start < 0.75){
            lastTimeInColumns[c] = orderedItems[i].start + 0.75;
          } else {
            lastTimeInColumns[c] = orderedItems[i].end;
          }
          fits = true;
        }
      }
      if(!fits){
        //console.log('nofit', orderedItems[i].start)
        orderedItems[i].column = lastTimeInColumns.length;
        if(orderedItems[i].end - orderedItems[i].start < 0.75){
          lastTimeInColumns.push(orderedItems[i].start + 0.75);
        } else {
          lastTimeInColumns.push(orderedItems[i].end);
        }
      }
    }

    for(let i = this.props.start; i <= this.props.end; i+=0.5){
      let halves = i % 1;
      let fulls = parseInt(i);
      if(fulls<10){
        fulls = '0'+fulls;
      }
      if(halves>0){
        halves = '30';
      } else {
        halves = '00'
      }
      timeTable.push(<div key={i} className="entry">{fulls}:{halves}</div>);
    }

    const percent = 100 / lastTimeInColumns.length;
    for(let i = 0; i < orderedItems.length; i++){
      const o = orderedItems[i];
      const h = 1.5*(o.end-o.start)*2;
      const th = h < 1 ? h : 1;
      els.push(<div className={"content clickable " + (this.props.selected == o.id ? ' selected' : '')} style={ { top: 1.5*(o.start - this.props.start)*2+'rem', height: h+'rem', textSize: th+'rem', width:percent*0.95+'%', left: percent * o.column+'%' } } onClick={(e) => this.props.onClick(o)}>
        {o.caption}
        <div className="startTime">{formatTime(o.start)}</div>
        <div className="endTime">{formatTime(o.end)}</div>
      </div>);
    }

    return <div className="timeChart" style={ {height: 1.5*fullHeight*2+'rem'} }>
      {timeTable}
      <div className="container">
        {els}
      </div>
    </div>;
  }
}

export default view(TimeChart);