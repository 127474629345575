const struct = {
  id: 'int',
  transaction: 'int',
  active: 'bool',
  deleted: 'bool',
  typ: 'int',
  nazwa: 'string',
  nazwas: 'string',
  parent: 'int',
  rstruct: 'int',
  rodzaj: 'int',
  opis: 'string',
  nrinw: 'string',
  rodzm: 'int',
  producent: 'int',
  grewid: 'int',
  status: 'int',
}

module.exports = struct;