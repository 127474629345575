import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';

export function wykonajPunktyNew(payload){
  mainStore.wykonanie.loading = true;
  console.log(payload);
  return fetchJSON('/api/wykonajPunktyNew/', payload).then((json) => {
    //wykonanie chyba nie ma efektów?
    mainStore.wykonanie.loading = false;
  })
  .catch(error => {
    mainStore.wykonanie.loading = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}