import Container from 'components/Container';
import { Col, Row } from 'components/Grid';
import TreeView from 'components/TreeView';
import React, { Component } from 'react';
import { getStructIcon } from 'util/getStructIcon';
import { view } from 'react-easy-state';
import { mainStore } from 'stores/store';
import { getStructIconColor } from 'util/getStructIconColor';
import { sortStructs } from 'model/struct/structModel';
import WeekGraph, { firstWeekEver } from '../../components/WeekGraph/WeekGraph';
import moment from 'moment';
import Input from 'components/Input';
import { getDiagram } from '../../model/wykonanie/getDiagram';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon/index';
import { getWykonanieList } from 'model/wykonanie/getWykonanieList';
import findInTree from './../../util/findInTree';
import WykonajScreen from './../../modals/WykonajScreen/index';
import { addModal } from './../../stores/modal';
import PozplanView from './../../modals/PozplanView/index';
import ChooseDay from './../../modals/ChooseDay/index';
import { saveOSMToLS } from '../../util/localStorageSetting';

function advancePunkt(time, punkt){
  if(punkt.tryb===202){
    //jednorazowy, skip. 
    return time;
  } 
  if(punkt.tryb===4){
    //dni tygodnia
    if(punkt.coile == 0) {
      time.add(100, 'weeks');
    } else {
      time.add(punkt.coile, 'weeks');
    }
    return time;
  } else {
    //tygodniowy
    if(punkt.coile == 0) {
      time.add(100, 'weeks');
    } else {
      time.add(punkt.coile, 'weeks');
    }
    return time;
  }
  return time;
}

function getPossiblePlanDates(_momentFromDate, _momentToDate, tryb, coile, momentLastWyk, dt = '0000000'){
  const result = [];
  const momentFromDate = moment(_momentFromDate).startOf('day');
  const momentToDate = moment(_momentToDate).endOf('day');
  const momentFromWeek = moment(_momentFromDate).startOf('week');
  const momentToWeek = moment(_momentToDate).endOf('week');

  if(tryb === 'jednorazowy'){
    const data = moment(momentLastWyk).startOf('day');
    
    if(!data.isBefore(momentFromDate) && !data.isAfter(momentToDate)){
      const wykNode = {
        data: data,
        day: data.unix(),
        week: moment(data).startOf('week').unix(),
        pozplan: undefined
      }
      result.push(wykNode);
    }
  }

  if(tryb === 'tygodniowy'){
    const firstAvailableData = moment(momentLastWyk);
    if(coile <= 0) {
      return result;
    }

    while(firstAvailableData.isBefore(momentFromWeek)){
      firstAvailableData.add(coile, 'weeks');
    }

    while(!firstAvailableData.isAfter(momentToWeek)){
      const nd = moment(firstAvailableData);
      
      const wykNode = {
        data: nd,
        day: moment(nd).startOf('day').unix(),
        week: moment(nd).startOf('week').unix(),
        pozplan: undefined
      }

      if(!nd.isBefore(momentFromDate) && !nd.isAfter(momentToDate)){
        result.push(wykNode);
      }
  
      firstAvailableData.add(coile, 'weeks');
    }
  }

  if(tryb === 'dni tygodnia'){
    const firstAvailableData = moment(momentLastWyk);
    if(coile <= 0) {
      return result;
    }

    //console.log('w', momentFromWeek.format('YYYY-MM-DD HH:mm:ss'), momentToWeek.format('YYYY-MM-DD HH:mm:ss'))
    //console.log('s', momentFromDate.format('YYYY-MM-DD HH:mm:ss'), momentToDate.format('YYYY-MM-DD HH:mm:ss'))
    while(firstAvailableData.isBefore(momentFromWeek)){
      firstAvailableData.add(coile, 'weeks');
      //console.log('a', firstAvailableData.format('YYYY-MM-DD HH:mm:ss'), momentFromWeek.format('YYYY-MM-DD HH:mm:ss'))
    }

    while(!firstAvailableData.isAfter(momentToWeek)){
      const nd = moment(firstAvailableData).startOf('week');
      //console.log('nd', nd.format('YYYY-MM-DD HH:mm:ss'))
      
      for(let i = 0; i < 7; i++){
        if(dt.charAt(i)!=='1') continue;
        const dayData = moment(nd).add(i, 'days');
        //console.log('dd', dayData.format('YYYY-MM-DD HH:mm:ss'))
        if(dayData.isBefore(momentFromDate)) continue;
        if(dayData.isAfter(momentToDate)) continue;

        //console.log('good');

        const wykNode = {
          data: dayData,
          day: moment(dayData).startOf('day').unix(),
          week: nd.unix(),
          pozplan: undefined
        }

        result.push(wykNode);
      }

      firstAvailableData.add(coile, 'weeks');
    }
  }  

  //console.log(result);
  return result;
}

class Plan extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sel: {}
    }
  }

  nodeProcessor(sourceNode) {
    return {
      id: sourceNode.id,
      caption: sourceNode.nazwas,
      icon: getStructIcon(sourceNode),
      iconColor: getStructIconColor(sourceNode),
      children: sourceNode.children.sort(sortStructs)
    }
  }

  getStructLabel(punkt) {
    let struct = mainStore.wykonanie.data.structs[punkt.parent];
    //if(struct) struct = mainStore.wykonanie.data.structs[struct.parent];
    const structStack = [];
    while(struct){
      structStack.push(struct);
      struct = mainStore.wykonanie.data.structs[struct.parent];
    }
    structStack.pop();
    return structStack.map((e) => e.nazwas).reverse().join(' / ');
  }

  getStructLabelSort(punkt) {
    let struct = mainStore.wykonanie.data.structs[punkt.parent];
    const structStack = [];
    while(struct){
      structStack.push(struct);
      struct = mainStore.wykonanie.data.structs[struct.parent];
    }
    return structStack.map((e) => e.nazwas+'|'+e.id).reverse().join(' / ')+' !!! '+punkt.nazwas;
  }

  treeViewNodeSelect = (node) => {
    mainStore.selectedStruct = node;
    if(!node) {
      return;
    }

    const nodeIDs = [];
    const nodes = [];

    nodes.push(node);
    while(nodes.length > 0){
      const n = nodes.pop();
      nodeIDs.push(n.id);
      for(let i = 0; i < n.children.length; i++){
        nodes.push(n.children[i]);
      }
    }

    const fromDate = moment(mainStore.topFromTo.from, 'YYYY-MM-DD').unix();
    const toDate = moment(mainStore.topFromTo.to, 'YYYY-MM-DD').unix();
    return getDiagram(nodeIDs, fromDate, toDate).then(() => {
      //now we process this to put into the diagram
      //console.log(mainStore.wykonanie.data);
      const list = [];
      for(let i in mainStore.wykonanie.data.punkty){
        //listujemy prace
        const punkt = mainStore.wykonanie.data.punkty[i];
        const fromDate = moment(mainStore.topFromTo.from, 'YYYY-MM-DD');
        const toDate = moment(mainStore.topFromTo.to, 'YYYY-MM-DD');
        const dataStart = moment.unix(punkt.dataost);
        const tryb = punkt.tryb === 202 ? 'jednorazowy' : (punkt.tryb === 4 ? 'dni tygodnia' : 'tygodniowy');

        const wykNodes = getPossiblePlanDates(moment(mainStore.topFromTo.from, 'YYYY-MM-DD'), moment(mainStore.topFromTo.to, 'YYYY-MM-DD'), tryb, punkt.coile, moment.unix(punkt.dataost), punkt.dt);
        //console.log(wykNodes);

        const struct =  mainStore.wykonanie.data.structs[punkt.parent];
        const row = {
          structLabel: this.getStructLabel(punkt),
          structLabelSort: this.getStructLabelSort(punkt),
          punkt: punkt,
          struct: struct,
          wykonanie : {}
        };
        list.push(row);

        for(let j = 0; j < wykNodes.length; j++){
          const wykNode = wykNodes[j];
          if(row.wykonanie[wykNode.week] === undefined){
            row.wykonanie[wykNode.week] = [];
          }
          row.wykonanie[wykNode.week].push(wykNode);
        }

        const ppp = mainStore.wykonanie.data.pozplanPunkty[punkt.id];
        if(ppp){
          for(let i = 0; i < ppp.length; i++){
            const data = moment.unix(ppp[i].datawyk).startOf('day');

            const sod = moment(data).startOf('day').unix();
            const sow = moment(data).startOf('week').unix();

            if(row.wykonanie[sow] === undefined){
              row.wykonanie[sow] = [];
            }

            const el = row.wykonanie[sow].find((e) => e.day === sod);
            if(!el){
              const wykNode = {
                data: data,
                day: moment(data).startOf('day').unix(),
                week: moment(data).startOf('week').unix(),
                pozplan: ppp[i]
              }
  
              row.wykonanie[sow].push(wykNode);
            } else {
              el.pozplan = ppp[i];
            }
          }
        }

        const weekUnixes = Object.keys(row.wykonanie);
        for(let i = 0; i < weekUnixes.length; i++){
          row.wykonanie[weekUnixes[i]].sort((a, b) => a.day - b.day);
        }
      }

      list.sort((sa, sb) => sa.structLabelSort.localeCompare(sb.structLabelSort, 'pl', {sensitivity: 'accent'}));
      //sortujemy po strukturach
      mainStore.diagram.diagramRows = list;
      //console.log('list', list);
      this.updateFilter(true);
    });    
  }

  nodeExpandClick = (nodeID) => {
    if(mainStore.openStructMap[nodeID]){
      mainStore.openStructMap[nodeID] = false;
    } else {
      mainStore.openStructMap[nodeID] = true;
    }
    saveOSMToLS();
  }

  componentDidMount = () => {
    mainStore.showTopFromTo = true;
  }

  componentWillUnmount = () => {
    mainStore.showTopFromTo = false;
  }

  changeFilter = (e) => {
    if(e){
      //from UI
      mainStore.diagram.nameFilter = e.target.value;
      this.updateFilter();
    }
  }

  wykonaj = (e) => {
    const rows = mainStore.diagram.filteredDiagramRows;
    //console.log(rows);

    const availableRows = {};
    
    //flatten the thing
    // but we need to filter the prace as well
    // to just select the ones we selected
    for(let i = 0; i < rows.length; i++){
      const row = rows[i];
      //tygodniowy
      if((row.punkt.tryb == 4)){
        const rowkeys = Object.keys(row.wykonanie);
        for(let j = 0; j < rowkeys.length; j++){
          const wykArray = row.wykonanie[rowkeys[j]];
          for(let k = 0; k < wykArray.length; k++) {
            if(wykArray[k].pozplan === undefined || wykArray[k].pozplan.raport === "0") availableRows[row.punkt.id+'_'+wykArray[k].day] = row;
          }
        }
      } else {
        const rowkeys = Object.keys(row.wykonanie);
        for(let j = 0; j < rowkeys.length; j++){
          const wykArray = row.wykonanie[rowkeys[j]];
          if(wykArray.length == 0){
            console.log('empty wyk o.0');
            continue;
          }
          if(wykArray[0].pozplan === undefined || wykArray[0].pozplan.raport === "0") availableRows[row.punkt.id+'_'+wykArray[0].week] = row;
        }
      }
    }
    //console.log(availableRows);
    //console.log('e', availableRows);;

    const entries = Object.keys(this.state.sel);
    //console.log(entries);
    const selectedRows = entries.filter((e) => (availableRows[e] !== undefined) && (this.state.sel[e]) ).map((e) => {
      const x = e.split('_');
      const ae = availableRows[e];
      //console.log(ae, x);
      let aktwyk = ae.wykonanie[x[1]];
      if(aktwyk === undefined){
        const sow = moment.unix(x[1]).startOf('week').unix();
        //console.log(sow);
        for(let i = 0; i < ae.wykonanie[sow].length; i++){
          if(ae.wykonanie[sow][i].day == x[1]){
            aktwyk = ae.wykonanie[sow][i];
            break;
          }
        }
      } else {
        aktwyk = aktwyk[0];
      }
      return {
        row: availableRows[e],
        wykDate: aktwyk.day
      }
    });

    //console.log(selectedRows);
    
    addModal(WykonajScreen, {
      rows: selectedRows,
    }, () => {
        this.setState({sel: {}})
        this.treeViewNodeSelect(mainStore.selectedStruct);
        this.forceUpdate();
      }
    );

    //mainStore.bigLoading = true;
    /*return getWykonanieList(nodeIDs, (json) => {
      // filter json.prace by removing entries that are a)not visible and b) not selected
      //console.log(json.prace);
      //console.log(selectedPrace);

      json.prace = json.prace.filter( 
        (n) => (this.filter == '') || (n.nazwas.toLocaleLowerCase().includes(this.filter))
      ).filter(
        (n) => selectedPrace[n.id] !== undefined
      );

      //console.log(json.prace);

      const punktMap = {};
      json.prace.map((e) => {
        punktMap[e.parent] = true;
      });

      json.punkty = json.punkty.filter((e) => punktMap[e.id] === true);

      const structMap = {};
      json.punkty.map((e) => {
        structMap[e.parent] = true;
      });

      json.structs = json.structs.filter((e) => structMap[e.id] === true);

      //console.log('gwl', json)
      return json
    }).then(() => {
      //we need to add the wykonanie dates properly here as well.
      
      mainStore.bigLoading = false;
    });*/
  }

  updateFilter = (force) => {
    if(mainStore.diagram.nameFilter != this.filter || force){
      //console.log('updatingfilter');
      //console.log(this.filter, mainStore.diagram.nameFilter)
      this.filter = mainStore.diagram.nameFilter.toLocaleLowerCase();
      if(this.filter==''){
        mainStore.diagram.filteredDiagramRows = mainStore.diagram.diagramRows;
        return;
      }
      const out = [];
      for(let i = 0; i <mainStore.diagram.diagramRows.length; i++){
        const row = mainStore.diagram.diagramRows[i];
        //console.log(row);
        if(row.structLabelSort.toLocaleLowerCase().includes(this.filter)){
          out.push(row);
        }
      }
      mainStore.diagram.filteredDiagramRows = out;
    }
  } 

  wykonajStruct = (e, node) => {
    addModal(WykonajScreen, {
        struct: node
      }, () => {
        this.treeViewNodeSelect(mainStore.selectedStruct)
      }
    );
  }

  onSelChange = (node, cb) => {
    let weekSelected = 0;
    //console.log(node);
    if(node.row.punkt.tryb === 1) {
      weekSelected = node.wykNode[0].week;
    } else if ( node.row.punkt.tryb === 202) {
      weekSelected = node.wykNode[0].week;
    } else {
      addModal(ChooseDay, {
        wykNode: node.wykNode,
        data: node.row,
        checkedItems: this.state.sel,
        onCheckChange: (item) => {
          const nsel = {...this.state.sel};
          const id = node.row.punkt.id+'_'+item.day;
          if(nsel[id]){
            nsel[id] = false;
          } else {
            nsel[id] = true;
          }
          this.setState({sel: nsel});
          return nsel;
        },
        onFinishedClick: (row, wykNodes, wykNode) => {
          console.log('ofc', row, wykNodes, wykNode);
          addModal(PozplanView, {
            struct: row.struct,
            punktID: row.punkt.id, 
            wykNode: wykNode
          });
        },
      }, () => {
        cb(this.state.sel);
      });
      return;
    }

    const newSelected = {...this.state.sel};

    const sk = node.row.punkt.id+'_'+weekSelected;

    //console.log(localID, node, newSelected);
    if(this.state.sel[sk]){
      newSelected[sk] = false;
    } else {
      newSelected[sk] = true;
    }

    this.setState({sel: newSelected})
    return newSelected;
  }

  onClick = (row, wykNode) => {
    console.log(row, wykNode);
    if(wykNode.length==1){
      wykNode = wykNode[0];
      if(!wykNode.pozplan || (wykNode.pozplan.datwyk < wykNode.pozplan.dataplan )){
        addModal(WykonajScreen, {
          struct: row.struct,
          punktID: row.punkt.id, 
          time: wykNode.data,
        }, () => {
            this.treeViewNodeSelect(mainStore.selectedStruct)
          }
        );
      } else {
        addModal(PozplanView, {
          struct: row.struct,
          punktID: row.punkt.id, 
          wykNode: wykNode
        });
      }
    } else {
      addModal(ChooseDay, {
        wykNode: wykNode,
        data: row,
        checkedItems: this.state.sel,
        onCheckChange: (item) => {
          const nsel = {...this.state.sel};
          const id = row.punkt.id+'_'+item.day;
          if(nsel[id]){
            nsel[id] = false;
          } else {
            nsel[id] = true;
          }
          this.setState({sel: nsel});
          return nsel;
        },
        then: (wykNode) => {
          if(!wykNode.pozplan || (wykNode.pozplan.datwyk < wykNode.pozplan.dataplan )){
            addModal(WykonajScreen, {
              struct: row.struct,
              punktID: row.punkt.id, 
              time: wykNode.data,
            }, () => {
                this.treeViewNodeSelect(mainStore.selectedStruct)
              }
            );
          } else {
            addModal(PozplanView, {
              struct: row.struct,
              punktID: row.punkt.id, 
              wykNode: wykNode
            });
          }
        }
      });
    }
    //console.log(row, wykNode);
  }

  render() {
    this.updateFilter();
    const selectedNode = mainStore.selectedStruct ? mainStore.selectedStruct : {};
    const fromDate = moment(mainStore.topFromTo.from, 'YYYY-MM-DD').unix();
    const toDate = moment(mainStore.topFromTo.to, 'YYYY-MM-DD').unix();
    return (
      <Container>
        <Row flex>
          <Col span="3">
            <Container panel vfull vscrolled>
              {mainStore.currentProject && 
                <TreeView data={mainStore.currentProject.structs} nodeClick={this.treeViewNodeSelect}
                      selected={selectedNode.id} nodeProcessor={this.nodeProcessor} 
                      nodeExpandClick={this.nodeExpandClick} openNodeMap={mainStore.openStructMap}/>
              }
            </Container>
          </Col>
          <Col span="9" panel>
              <Container panel vfull vscrolled>
                <Row className="relative">
                  <Col span="6">
                    <Input value={mainStore.diagram.nameFilter} placeholder={"Filtr"}onChange={(e) => this.changeFilter(e)} />
                  </Col>
                  <Col span="3">
                    <Button primary block onClick={this.wykonaj}><Icon icon="fa fa-check fa-fw" /> Wykonaj</Button>
                  </Col>
                  <Col span="3">
                    
                  </Col>
                </Row>
                <WeekGraph rows={mainStore.diagram.filteredDiagramRows} startDate={fromDate} endDate={toDate} onSelChange={this.onSelChange} onClick={this.onClick}/>
              </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(Plan);
export {getPossiblePlanDates}