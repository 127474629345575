import { collateObject } from "util/collation";

export const robolCollation = {
  id: 'int',
  transaction: 'int',
  active: 'bool',
  deleted: 'bool',
  parent: 'int',
  start: 'datetime',
  end: 'datetime',
  brygada: 'int',
  praca: 'int',
  kolejnosc: 'int',
  stawka: 'int',
  arch: 'int',
  lost: 'int',
}

export function transformRobolFromJSON(o, fieldFilter) {
  return collateObject(o, robolCollation, fieldFilter);      
}

export function sortRobol(sa, sb){
  return sa.praca - sb.praca;
}
