import Container from 'components/Container';
import { Col, Row } from 'components/Grid';
import TreeView from 'components/TreeView';
import React, { Component } from 'react';
import { getStructIcon } from 'util/getStructIcon';
import { view } from 'react-easy-state';
import { mainStore } from 'stores/store';
import { getStructIconColor } from 'util/getStructIconColor';
import { sortStructs } from 'model/struct/structModel';
import WeekGraph from '../../components/WeekGraph/WeekGraph';
import moment from 'moment';
import Input from 'components/Input';
import { getDiagram } from '../../model/wykonanie/getDiagram';
import { saveOSMToLS } from '../../util/localStorageSetting';

class Diagram extends Component {

  nodeProcessor(sourceNode) {
    return {
      id: sourceNode.id,
      caption: sourceNode.nazwas,
      icon: getStructIcon(sourceNode),
      iconColor: getStructIconColor(sourceNode),
      children: sourceNode.children.sort(sortStructs)
    }
  }

  treeViewNodeSelect = (node) => {
    mainStore.selectedStruct = node;
    if(!node) {
      return;
    }

    const nodeIDs = [];
    const nodes = [];

    nodes.push(node);
    while(nodes.length > 0){
      const n = nodes.pop();
      nodeIDs.push(n.id);
      for(let i = 0; i < n.children.length; i++){
        nodes.push(n.children[i]);
      }
    }

    const fromDate = moment(mainStore.topFromTo.from, 'YYYY-MM-DD').unix();
    const toDate = moment(mainStore.topFromTo.to, 'YYYY-MM-DD').unix();
    return getDiagram(nodeIDs, fromDate, toDate);    
  }

  nodeExpandClick = (nodeID) => {
    if(mainStore.openStructMap[nodeID]){
      mainStore.openStructMap[nodeID] = false;
    } else {
      mainStore.openStructMap[nodeID] = true;
    }
    saveOSMToLS();
  }

  componentDidMount = () => {
    mainStore.showTopFromTo = true;
  }

  componentWillUnmount = () => {
    mainStore.showTopFromTo = false;
  }

  changeFilter = () => {

  }

  render() {
    const selectedNode = mainStore.selectedStruct ? mainStore.selectedStruct : {};
    const fromDate = moment(mainStore.topFromTo.from, 'YYYY-MM-DD').unix();
    const toDate = moment(mainStore.topFromTo.to, 'YYYY-MM-DD').unix();
    return (
      <Container>
        <Row flex>
          <Col span="3">
            <Container panel vfull vscrolled>
              {mainStore.currentProject && 
                <TreeView data={mainStore.currentProject.structs} nodeClick={this.treeViewNodeSelect}
                      selected={selectedNode.id} nodeProcessor={this.nodeProcessor}
                      nodeExpandClick={this.nodeExpandClick} openNodeMap={mainStore.openStructMap}/>
              }
            </Container>
          </Col>
          <Col span="9" panel>
              <Container panel vfull vscrolled>
                <Row>
                  <Col span="12">
                    <Input value={mainStore.diagram.nameFilter} onChange={(e) => this.changeFilter(e)} />
                  </Col>
                </Row>
                <Row>
                  <Col span="12">
                    <WeekGraph rows={mainStore.currentPunkty} startDate={fromDate} endDate={toDate} />
                  </Col>
                </Row>
              </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(Diagram);
