export default function updateURL (props, location, variables) {
  if(!location){
    location = props.location.pathname;
  }
  let newURL = location+'#';
  const keys = Object.keys(variables);
  keys.sort();
  let und = false;
  for(let i = 0; i < keys.length; i++){
    if(variables[keys[i]]!=undefined){
      if(und){
        newURL += '&';
      }
      und = true;
      newURL += keys[i]+'='+variables[keys[i]];
    }
  }
  props.history.push(newURL);
}