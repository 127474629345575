import { collateObject } from "util/collation";

import structCollation from 'model/struct.js'

export function transformStructFromJSON(o, fieldFilter) {
  return collateObject(o, structCollation, fieldFilter);      
}

export function sortStructs(sa, sb){
  if(sa.typ != sb.typ){
    return sa.typ - sb.typ;
  }
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}