import React, { Component } from 'react';
import './ModalWrapper.css';
import modalStore from 'stores/modal';
import Container from 'components/Container';
import Icon from 'components/Icon';
import { view } from 'react-easy-state';

class ModalWrapper extends Component {
  render() {
    if(modalStore.modalWindows.length === 0){
      document.body.className = '';
    } else {
      document.body.className = 'modal-body';
    }
    //console.log(modalStore.modalWindows);
    return (
      <div>
        { (modalStore.modalWindows.length > 0) && modalStore.modalWindows.map((el, index) => {
          const Component = el.component;
          const closeFunction = (data) => {
            modalStore.modalWindows.splice(index, 1);
            //console.log(el);
            if(el.onClose){
              el.onClose(data);
            }
          }
          const addClass = Component.class ? Component.class : '';
          return (
            <div key={index} className="modal-wrapper" style={ { zIndex: 100 + index} } >
              <div className="modal-wrapper-bg" />
              <Container panel relative className={addClass}>
                <Icon icon="fa fa-times-circle" className="modal-close-button clickable" onClick={(e) => closeFunction()}/>
                <Component {...el.props} closeAction={closeFunction}/>
              </Container>
            </div>
          )
        })}
      </div>
    )
  }
}

export default view(ModalWrapper);