import fetchJSON from 'util/fetchJSON';
import { transformStructFromJSON } from 'model/struct/structModel';
import { mainStore } from 'stores/store';
import { sortNazwas } from '../wykonanie/getDiagram';
import arrayToMap from './../../util/arrayToMap';

const cache = {
  projects: {}
}

export function selectProject(project){
  const pid = project.id;
  if(cache.projects[pid]){
    mainStore.currentProject = cache.projects[pid];
    mainStore.currentProjectID = pid;
    return Promise.resolve();
  } 
  mainStore.bigLoading = true;
  return fetchJSON('/api/loadProjectData/'+pid)
    .then((json) => {
    const structs = json.structs;  
    const map = {};
    let root = {...project};
    root.children = [];
    map[root.id] = root;
    for(let i = 0; i < structs.length; i++){
      const newStruct = structs[i];
      transformStructFromJSON(newStruct);
      if(map[newStruct.id]){
        //object exists, created when there was just parent.
        const mo = map[newStruct.id];
        for(let i in newStruct.keys()){
          mo[i] = newStruct[i];
        }
      } else {
        newStruct.children = [];
        map[newStruct.id] = newStruct;
      }
      if(!map[newStruct.parent]){
        map[newStruct.parent] = { 
          id: newStruct.parent, 
          children: [] 
        };
      }
      map[newStruct.parent].children.push(map[newStruct.id]);
      if(newStruct.parent === 0) {
        root = map[newStruct.id];
      }
    }

    json.repoPunkt.sort(sortNazwas);
    json.repoPrac.sort(sortNazwas);


    const newProject = {
      structs: root,
      id: pid,
      name: root.nazwas,
      structMap: map,
      
      repoPunkt: {
        sorted: json.repoPunkt,
        idMap: arrayToMap(json.repoPunkt)
      },
      
      repoPrac: {
        sorted: json.repoPrac,
        idMap: arrayToMap(json.repoPrac)
      },

      brygady: {
        sorted: json.brygady,
        idMap: arrayToMap(json.brygady)
      },      
    };

    mainStore.currentProject = newProject;
    mainStore.currentProjectID = pid;
    cache.projects[pid] = newProject;

    mainStore.bigLoading = false;
  })
  .catch(error => {
    mainStore.bigLoading = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}