import Header from 'components/Header';
import HeaderButton from 'components/HeaderButton';
import Logo from 'components/Logo';
import Select from 'components/Select';
import React, { Component } from 'react';
import { view } from 'react-easy-state';
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import Home from './screens/Home';
import ModalWrapper from './components/ModalWrapper';
import Prace from './screens/Prace';
import Administracja from './screens/Administracja';
import Archiwum from './screens/Archiwum';
import mainStore from 'stores/store';
import Wykonanie from './screens/Wykonanie';
import Plan from './screens/Plan';
import LoginScreen from './screens/Login/LoginScreen';
import { loadBaseData } from './model/loadBaseData';
import AppContainer from './AppContainer';
import Loading from './components/Loading/index';
import { selectProject } from './model/struct/selectProject';
import Diagram from './screens/Diagram/Diagram';
import DateInputRange from './components/Input/DateInputRange';
import updateURL from './util/updateURL';
import { verifyLoginByKey, verifyAPILogin } from './model/login/verifyLogin';
import { getPracaTree } from './model/praca/getPracaTree';
import User from './screens/User/index';
import { Icon } from 'components/Icon/index';
import { loadOSMFromLS } from './util/localStorageSetting';

const APIKey = localStorage.getItem('APIKey');
loadOSMFromLS();

class App extends Component {

  constructor(props){
    super(props);
    if(APIKey){
      const apiV = APIKey.split('|');
      mainStore.APIKey = apiV[1];
      verifyAPILogin(apiV[0], apiV[1]);
    }
  }

  selectProject = (pid) => {
    const project = mainStore.projects.find((i) => i.id == pid)
    if(!project){
      console.log('project doesnt exist', mainStore.project);
      return;
    }
    return selectProject(project);
  }

  changeProject = (event) => {
    const v = event.target.value;
    this.selectProject(v);
  }

  render() {
    if(!mainStore.loggedIn){
      return (
        <LoginScreen />
      )
    }

    if((!mainStore.appReady) && (!mainStore.loadingBaseData)){
      mainStore.loadingBaseData = true;
      let promise;
      if(!mainStore.loadedBaseData){
        promise = loadBaseData();
      } else {
        promise = Promise.resolve(true);
      }
      //once we load base data, or we have it loaded, we do this:
      promise.then( () => {
        // once we loaded the base data, check if our projectID changed.
        if(mainStore.projects.length>0) return this.selectProject(mainStore.projects[0]);
      }).then( () => {
        mainStore.appReady = true;
        //ok, our project is loaded. now we need to check if we need to load something 
      })
      
      return (
        <Loading />
      )
    }

    return (
      <AppContainer>
        {mainStore.bigLoading && <Loading /> }
        <ModalWrapper />
        <Header>
          <Logo>G·M·M·S</Logo>
          <HeaderButton left className="relative nopadding leftPadding rightPadding">
            <Select inline options={mainStore.projects} accessor={(option) => ({key: option.id, value: option.nazwa})} value={mainStore.currentProjectID} onChange={this.changeProject} />
          </HeaderButton>
          {mainStore.showTopFromTo && 
          <HeaderButton left className="relative nopadding leftPadding rightPadding noLeftBorder">
            <DateInputRange value={mainStore.topFromTo} onChange={(newDay) => mainStore.topFromTo = newDay}/>            
          </HeaderButton>
          }

          <HeaderButton to={"/admin/"} animated>Admin</HeaderButton>
          <HeaderButton to={"/archive/"} animated>Archiwum</HeaderButton>
        {/*<HeaderButton to={"/wykonanie/"} animated>Wykonanie</HeaderButton>*/}
          <HeaderButton to={"/plan/"} animated>Plan</HeaderButton>
          <HeaderButton to={"/prace/"} animated>Struktura</HeaderButton>
          <HeaderButton to={"/"} animated exact><Icon icon="fal fa-user fa-lg" /> {mainStore.currentUser.podpis}</HeaderButton>
        </Header>

        <Route path="/" exact component={User} />
        <Route path="/prace/" component={Prace} />
        <Route path="/plan/" component={Plan} />
        <Route path="/diagram/" component={Diagram} />
        <Route path="/wykonanie/" component={Wykonanie} />
        <Route path="/admin/" component={Administracja} />
        <Route path="/archive/" component={Archiwum} />
      </AppContainer>
    );
  }
}

export default withRouter(view(App));
