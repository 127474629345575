import React, { Component } from 'react';
import setInObj from 'util/setInObj';
import { view } from 'react-easy-state';

export const Context = React.createContext();

class Form extends Component {

  updateValue = (field, value) => {
    setInObj(this.props.value, field, value);
    this.forceUpdate();
  }

  render() {
    return (
      <Context.Provider value={ {
        value: this.props.value,
        updateValue: this.updateValue
      } }>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default view(Form);