import { collateObject } from "util/collation";

import ludzikCollation from 'model/ludzik.js'

export function transformLudzikFromJSON(o, fieldFilter) {
  return collateObject(o, ludzikCollation, fieldFilter);      
}

export function sortLudzie(sa, sb){
  return sa.podpis.localeCompare(sb.podpis, 'pl', {sensitivity: 'accent'});
}