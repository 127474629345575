import React, { Component } from 'react';
import { withRouter } from 'react-router';
import mainStore from './stores/store';

class AppContainer extends Component {

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      mainStore.url = location;
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }
  render() {
     return (
         <div>{this.props.children}</div>
      );
  }
}
export default withRouter(AppContainer);