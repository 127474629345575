const pozplan = {
  id: 'int',
  transaction: 'int',
  active: 'bool',
  deleted: 'bool',
  typ: 'int',
  nazwa: 'string',
  nazwas: 'string',
  parent: 'int',
  praca: 'int',
  tryb: 'int',
  coile: 'int',
  dataost: 'datetime',
  datanast: 'datetime',
  dt: 'string',
  prior: 'int',
  info: 'string',
  cenas: 'int',
  extra: 'float',
  device: 'int', 
  project: 'int',
  lastmeasure: 'float',
  idpkt: 'int',
  datawyk: 'datetime',
  dataplan: 'datetime',
  raport: 'string',
  waluta_id: 'int',
  waluta: 'int',
  dataarch: 'datetime',
  arch: 'bool',
  main: 'bool',
  lost: 'bool'
}

module.exports = pozplan
