import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformLudzikFromJSON, sortLudzie } from './ludzikModel';
import arrayToMap from '../../util/arrayToMap';

export function applyLudzie(json){
  const ludzie = json.ludzie;
  for(let i = 0; i < ludzie.length; i++){
    transformLudzikFromJSON(ludzie[i]);
  }

  const map = arrayToMap(ludzie);

  mainStore.ludzie.idMap = map;
  mainStore.ludzie.list = ludzie;
  mainStore.ludzie.sortedList = [...ludzie].sort(sortLudzie);
}

let lastGetDeleted = false;

export function getLudzie(getDeleted = false, force = false){
  if(getDeleted !== undefined) {
    lastGetDeleted = getDeleted;
  } else {
    getDeleted = lastGetDeleted;
  }
  if(mainStore.ludzie.list.length > 0 && force === false){
    return Promise.resolve();
  }
  mainStore.ludzie.list = [];
  mainStore.ludzie.sortedList = [];
  return fetchJSON('/api/getLudzie/', {deleted: getDeleted})
    .then((json) => {

    applyLudzie(json);  
  })
  .catch(error => {
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}