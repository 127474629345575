const cosiek = {
  id: 'int',
  transaction: 'int',
  active: 'bool',
  deleted: 'bool',
  typ: 'int',
  nazwa: 'string',
  nazwas: 'string',
  rodzaj: 'int',
  model: 'string',
  grewid: 'int',
  grupa: 'int',
  podgrupa: 'int',
  volume: 'float',
  volumetyp: 'int',
  uwagi: 'string',
  pkwiu: 'string',
  masa: 'float',
}

module.exports = cosiek;
