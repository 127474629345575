import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformPracaFromJSON } from './pracaModel';
import { getStructChildren } from './../struct/getStructChildren';

function addChildren(obj){
  obj.children = [];
  return obj;
}

export function savePraca(praca, repoPraca){
  mainStore.bigLoader = true;
  return fetchJSON('/api/savePraca/', {praca: praca, repoPraca: repoPraca})
  .then((json) => {
    mainStore.bigLoader = false;
    return getStructChildren(mainStore.selectedStruct.id)
  }).then( () => {
    mainStore.bigLoader = false;
  }).catch(error => {
    mainStore.bigLoader = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}

export function deletePraca(pracaID){
  mainStore.bigLoader = true;
  return fetchJSON('/api/deletePraca/'+pracaID)
  .then((json) => {
    mainStore.bigLoader = false;
    mainStore.selectedPunkt.children = mainStore.selectedPunkt.children.filter((v) => v.id!=pracaID);
    if(mainStore.selectedPraca.id == pracaID){
      mainStore.selectedPraca = undefined;
      mainStore.selectedPart = undefined;
    }
    return json;
  })
  .catch(error => {
    mainStore.bigLoader = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}