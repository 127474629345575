import Container from 'components/Container';
import React, { Component } from 'react';
import { Button } from 'components/Button';
import { Row, Col } from 'components/Grid';
import { Icon } from 'components/Icon';
import { Input, DateInputRange } from 'components/Input';
import { view } from 'react-easy-state';
import { getStructIcon } from 'util/getStructIcon';
import { RowSet } from 'components/Grid';
import { Table } from 'components/Table';
import { wykonanieTable, wykonanieData, wykonanieCzynnosciTable, wykonanieRozpisTable, wykonanieMaterialyTable } from 'model/tables/wykonanie';
import { mainStore } from 'stores/store';
import { getLudzie } from '../../model/ludzik/getLudzie';
import { getCosieks } from '../../model/cosiek/getCosieks';
import { getArchiwumPP } from '../../model/wykonanie/getArchiwumPP';
import Select from './../../components/Select/index';
import moment, { relativeTimeThreshold } from 'moment';
import Label from '../../components/Label';
import SmartInput from '../../components/Input/SmartInput';
import WydrukOsobowy from './Wydruki/WydrukOsobowy';
import WydrukArchiwum from './Wydruki/WydrukArchiwum';
import EksportPracownikow from './Wydruki/EksportPracownikow';
import EksportKrotki from './Wydruki/EksportKrotki';
import EksportCzasowy from './Wydruki/EksportCzasowy';
import ExportPracownikow from './Wydruki/EksportPracownikow';
import ExportKrotki from './Wydruki/EksportKrotki';
import { getPossiblePlanDates } from '../Plan/Plan';
import { getDiagram } from '../../model/wykonanie/getDiagram';
import arrayToMap from '../../util/arrayToMap';


function repeat(a, amount){
  let all = a;
  for(let i = 0; i < amount; i++) all += a;
  return all;
}

class Archiwum extends Component {

  constructor(props){
    super(props);

    mainStore.bigLoading = true;
    getLudzie().then(() => {
      return getCosieks()    
    }).then(() => {
      mainStore.bigLoading = false;
    })
  }

  state = {
    day: {
      from: undefined,
      to: undefined
    },
    nazwas: '',
    cosiek: undefined,
    ludzik: undefined
  } 

  loadArchiwum = () => {
    mainStore.bigLoading = true;
    return getArchiwumPP(moment(mainStore.archiwum.dateRange.from).unix(), moment(mainStore.archiwum.dateRange.to).unix(), this.state.nazwas, this.state.cosiek, this.state.ludzik, mainStore.selectedStruct).then(() => {
      mainStore.bigLoading = false;
    });
  }

  struktury = [
    {
      struct: {nazwas: "12.04.2020: Fabryka klocków"},
      entries: [
        { 
          struct: ["Linia produkcyjna 74", "CB-3", "LP23"],
          praca: "Czyszczenie zębatek",
          czynnosc: "Wymiana oleju",
          tryb: "PL",
          data: "19.03.2020",
          start: "06:00",
          end: "08:30",
          rap: "P122/04/19",
          czas: 6,
          materialy: [
            {nazwa: "Nuto H222", ile: 15, jm: 'l'},
            {nazwa: "Nuto H1", ile: 1, jm: 'l'},
          ],
          pracownicy: ['Kowalski', 'Piłat']
        },
        { 
          struct: ["Linia produkcyjna 74", "CB-3", "LP34"],
          praca: "Czyszczenie zębatek",
          czynnosc: "Szczotkowanie",
          tryb: "PL",
          data: "19.03.2020",
          start: "06:00",
          end: "08:30",
          rap: "P122/04/19",
          czas: 6,
          materialy: [],
          pracownicy: ['Kowalski', 'Piłat']
        }
      ]
    },
    {
      struct: {nazwas: "12.04.2020: Fabryka klocków2"},
      entries: [
        { 
          struct: ["Linia produkcyjna 12", "CB-1", "LP11"],
          praca: "Czyszczenie zębatek",
          czynnosc: "Wymiana oleju",
          tryb: "PL",
          data: "19.03.2020",
          start: "06:00",
          end: "08:30",
          rap: "P122/04/19",
          czas: 6,
          materialy: [
            {nazwa: "Nuto H222", ile: 15, jm: 'l'},
            {nazwa: "Nuto H1", ile: 1, jm: 'l'},
          ],
          pracownicy: ['Kowalski', 'Piłat']
        },
        { 
          struct: ["Linia produkcyjna 12", "CB-1", "LP12"],
          praca: "Czyszczenie zębatek",
          czynnosc: "Wymiana oleju",
          tryb: "PL",
          data: "19.03.2020",
          start: "06:00",
          end: "08:30",
          rap: "P122/04/19",
          czas: 6,
          materialy: [
            {nazwa: "Nuto H222", ile: 15, jm: 'l'},
            {nazwa: "Nuto H1", ile: 1, jm: 'l'},
          ],
          pracownicy: ['Kowalski', 'Piłat']
        },            
        { 
          struct: ["Linia produkcyjna 12", "CB-1", "LP17"],
          praca: "Czyszczenie zębatek",
          czynnosc: "Szczotkowanie",
          tryb: "PL",
          data: "19.03.2020",
          start: "06:00",
          end: "08:30",
          rap: "P122/04/19",
          czas: 6,
          materialy: [],
          pracownicy: ['Kowalski', 'Piłat']
        },
        { 
          struct: ["Linia produkcyjna 12", "CB-1", "LP22"],
          praca: "Czyszczenie zębatek",
          czynnosc: "Szczotkowanie",
          tryb: "PL",
          data: "19.03.2020",
          start: "06:00",
          end: "08:30",
          rap: "P122/04/19",
          czas: 6,
          materialy: [],
          pracownicy: ['Kowalski', 'Piłat']
        },
        { 
          struct: ["Linia produkcyjna 12", "CB-1", "LP51"],
          praca: "Czyszczenie zębatek",
          czynnosc: "Szczotkowanie",
          tryb: "PL",
          data: "19.03.2020",
          start: "06:00",
          end: "08:30",
          rap: "P122/04/19",
          czas: 6,
          materialy: [],
          pracownicy: ['Kowalski', 'Piłat']
        },
        { 
          struct: ["Linia produkcyjna 13", "CB-1", "LP01"],
          praca: "Czyszczenie zębatek",
          czynnosc: "Szczotkowanie",
          tryb: "PL",
          data: "19.03.2020",
          start: "06:00",
          end: "08:30",
          rap: "P122/04/19",
          czas: 6,
          materialy: [],
          pracownicy: ['Kowalski', 'Piłat']
        },
        { 
          struct: ["Linia produkcyjna 13", "CB-1", "LP02"],
          praca: "Czyszczenie zębatek",
          czynnosc: "Szczotkowanie",
          tryb: "PL",
          data: "19.03.2020",
          start: "06:00",
          end: "08:30",
          rap: "P122/04/19",
          czas: 6,
          materialy: [],
          pracownicy: ['Kowalski', 'Piłat']
        }
      ]
    }
  ]

  data = {
    provider: "Serwis Techniczny STMIS Polska<br />Adres tutaj<br />i coś jeszcze",
    maintitle: "Plan dnia dla Adama Adackiego",
    title: "Wykonanie dla BT-1",
    client: "Rockwool dębica opony i izolacje<br />Adres tutaj<br />i coś jeszcze",
    struktury: [
      ...this.struktury,
      ...this.struktury,
      ...this.struktury,
      ...this.struktury,
      ...this.struktury,
      ...this.struktury,
      ...this.struktury,
      ...this.struktury,
      ...this.struktury,
    ]
  }

  getStructLabel(punkt) {
    let struct = mainStore.wykonanie.data.structs[punkt.parent];
    //if(struct) struct = mainStore.wykonanie.data.structs[struct.parent];
    const structStack = [];
    while(struct){
      structStack.push(struct);
      struct = mainStore.wykonanie.data.structs[struct.parent];
    }
    structStack.pop();
    return structStack.map((e) => e.nazwas).reverse();
  }

  getStructLabelSort(punkt) {
    let struct = mainStore.wykonanie.data.structs[punkt.parent];
    const structStack = [];
    while(struct){
      structStack.push(struct);
      struct = mainStore.wykonanie.data.structs[struct.parent];
    }
    return structStack.map((e) => e.nazwas+'|'+e.id).reverse().join(' / ')+' !!! '+punkt.nazwas;
  }

  getAllData = async (nodeIDs, fromDate, toDate, wykOnly) => {
    await getDiagram(nodeIDs, fromDate, toDate, true)

    //now we process this to put into the diagram
    //console.log(mainStore.wykonanie.data);
    const list = [];
    const rowByPunktIDMap = {};
    for(let i in mainStore.wykonanie.data.punkty){
      //listujemy prace
      const punkt = mainStore.wykonanie.data.punkty[i];
      const dataStart = moment.unix(punkt.dataost);
      const tryb = punkt.tryb === 202 ? 'jednorazowy' : (punkt.tryb === 4 ? 'dni tygodnia' : 'tygodniowy');

      let wykNodes = [];
      if(!wykOnly) {
        wykNodes = getPossiblePlanDates(moment.unix(fromDate).format('YYYY-MM-DD'), moment.unix(toDate).format('YYYY-MM-DD'), tryb, punkt.coile, moment.unix(punkt.dataost), punkt.dt);
      }
      //console.log('wn', wykNodes);

      const struct =  mainStore.wykonanie.data.structs[punkt.parent];
      const row = {
        structLabel: this.getStructLabel(punkt),
        structLabelSort: this.getStructLabelSort(punkt),
        punkt: punkt,
        struct: struct,
        wykonanie : {},
        prace: []
      };
      rowByPunktIDMap[punkt.id] = row;
      list.push(row);

      for(let j = 0; j < wykNodes.length; j++){
        const wykNode = wykNodes[j];
        row.wykonanie[wykNode.day] = wykNode;
      }

      const ppp = mainStore.wykonanie.data.pozplanPunkty[punkt.id];
      if(ppp){
        for(let i = 0; i < ppp.length; i++){
          const data = moment.unix(ppp[i].datawyk).startOf('day');

          const sod = moment(data).startOf('day').unix();
          const sow = moment(data).startOf('week').unix();

          const el = row.wykonanie[sod];
          if(!el){
            const wykNode = {
              data: data,
              day: moment(data).startOf('day').unix(),
              week: moment(data).startOf('week').unix(),
              pozplans: {}
            }

            wykNode.pozplans[ppp[i].praca] = ppp[i];

            row.wykonanie[sod] = wykNode;
          } else {
            if(!el.pozplans){
              el.pozplans = {};
            }
            el.pozplans[ppp[i].praca] = ppp[i];
          }
        }
      }
    }

    //console.log('aaa', mainStore.wykonanie.data.prace);
    for(let i = 0; i < mainStore.wykonanie.data.praceArray.length; i++){
      const praca = mainStore.wykonanie.data.praceArray[i];
      praca.parts = [];
      if(!rowByPunktIDMap[praca.parent]){
        console.log('didnt find punkt for', praca, rowByPunktIDMap);
        continue;
      }
      rowByPunktIDMap[praca.parent].prace.push(praca);
    }      

    for(let i = 0; i < mainStore.wykonanie.data.partArray.length; i++){
      const part = mainStore.wykonanie.data.partArray[i];
      if(!mainStore.wykonanie.data.prace[part.parent]){
        console.log('didnt find praca for', part, mainStore.wykonanie.data.prace);
        continue;
      }
      mainStore.wykonanie.data.prace[part.parent].parts.push(part);
    }
    return list;
  }

  getData = async (wykOnly, filter = (a) => true) => {
    const adresData = mainStore.currentProject.structs.opis.split('\n\n');
    let prov = [];
    let cli = [];
    if(adresData.length>0) prov = adresData[0].split('\n');
    if(adresData.length>1) cli = adresData[1].split('\n');
    
    let fromDate, toDate;
    let mfromDate, mtoDate;
    if(mainStore.archiwum.dateRange.from === undefined){
      mfromDate = moment().startOf('day');
      mtoDate = moment().endOf('day');

      fromDate = mfromDate.unix();
      toDate = mtoDate.unix();
    } else {
      mfromDate = moment(mainStore.archiwum.dateRange.from, 'YYYY-MM-DD');
      mtoDate = moment(mainStore.archiwum.dateRange.to, 'YYYY-MM-DD');

      fromDate = mfromDate.unix();
      toDate = mtoDate.unix();
    }

    const data = {
      provider: prov.join('<br \\>'),
      maintitle: (mfromDate.format('YYYY-MM-DD') !== mtoDate.format('YYYY-MM-DD')) ? "Plan od "+mfromDate.format('YYYY-MM-DD')+" do "+mtoDate.format('YYYY-MM-DD') : "Plan na dzień "+mfromDate.format('YYYY-MM-DD'),
      title: "",
      client: cli.join('<br \\>'),
      struktury: [],
    }

    if(this.state.ludzik && mainStore.ludzie.idMap[this.state.ludzik]){
      data.maintitle += ' dla '+mainStore.ludzie.idMap[this.state.ludzik].podpis;
    }

    if(mainStore.selectedStruct){
      const structList = [];
      let curStruct = mainStore.selectedStruct;
      while(curStruct.parent != 0){
        structList.push(curStruct);
        curStruct = mainStore.currentProject.structMap[curStruct.parent];
        if(curStruct === undefined) break;
      }
      data.title = 'Dla struktury '+structList.reverse().map((s) => s.nazwas).join(' / ');
    }

    let node = mainStore.currentProject.structs;
    if(mainStore.selectedStruct){
      node = mainStore.selectedStruct;
    }

    const nodeIDs = [];
    const nodes = [];

    nodes.push(node);
    while(nodes.length > 0){
      const n = nodes.pop();
      nodeIDs.push(n.id);
      for(let i = 0; i < n.children.length; i++){
        nodes.push(n.children[i]);
      }
    }

    const allData = await this.getAllData(nodeIDs, fromDate, toDate, wykOnly);
    console.log(allData);

    //transform data to matching days:
    const dayMap = {};

    for(let i = 0; i < allData.length; i++){
      const wyk = allData[i];
      //console.log(wyk);
      const days = Object.keys(wyk.wykonanie);
      for(let j = 0; j < days.length; j++){
        const day = days[j];
        //console.log(day);
        const wykDate = wyk.wykonanie[day].day;
        if(!dayMap[wykDate]) dayMap[wykDate] = [];
        dayMap[wykDate].push(wyk);
      }
    }
    console.log(dayMap);

    const robolMap = {};
    for(let i = 0; i < mainStore.wykonanie.data.robols.length; i++){
      const robol = mainStore.wykonanie.data.robols[i];
      if(!robolMap[robol.praca]){
        robolMap[robol.praca] = [];
      }
      robolMap[robol.praca].push(robol);
    }

    const dates = Object.keys(dayMap);
    dates.sort();
    for(let i = 0; i < dates.length; i++){
      const date = parseInt(dates[i]);
      //console.log(date);
      const list = dayMap[date];
      const m = moment.unix(date);
      const newStruct = {
        struct: { nazwas: m.format('YYYY.MM.DD') },
        entries: []
      }

      for(let j = 0; j < list.length; j++){
        const item = {...list[j]};
        item.prace = [...item.prace];
        const praceMap = arrayToMap(item.prace);

        console.log('i, pm', item, praceMap);
        if(item.wykonanie[date] && item.wykonanie[date].pozplans){
          Object.keys(item.wykonanie[date].pozplans).forEach((pp) => {
            if(!praceMap[pp]){

              item.prace.push({
                czas: item.wykonanie[date].pozplans[pp].extra,
                id: pp,
                nazwas: item.wykonanie[date].pozplans[pp].nazwas,
                parent: item.punkt.id,
                dod: true
              });
            console.log('pp', item.wykonanie[date].pozplans[pp]);
            }
          });
        }

        for(let k = 0; k < item.prace.length; k++){
          const praca = item.prace[k];
          const weekDate = moment(m).startOf('week').unix();
          const wyk = item.wykonanie[date] ? item.wykonanie[date] : undefined;
          const pozplan = (wyk && wyk.pozplans) ? wyk.pozplans[praca.id] : undefined;

          let materialy = [];
          if(!pozplan){
            if(praca.parts){
              materialy = praca.parts.map((pa) => {
                return {
                  uzyto: pa.oryginal,
                  nazwa: pa.nazwas,
                  ile: pa.ileplan,
                  jm: pa.jm
                }
              })
            }
          } else {
            materialy = mainStore.wykonanie.data.usedparts.filter((upa) => upa.parent === pozplan.id).map((pa) => {
              return {
                uzyto: pa.uzyto,
                nazwa: pa.nazwas,
                ile: pa.ileplan,
                jm: pa.jm
              }
            })
          }

          const newEntry = {
            struct: item.structLabel,
            praca: item.punkt.nazwas,
            czynnosc: praca.nazwas,
            tryb: praca.dod ? ( pozplan && pozplan.typ === 1 ? "AW" : "PR") : "PL",
            data: m.format('YYYY-MM-DD'),
            start: pozplan ? moment.unix(pozplan.datawyk).format('HH:mm') : '',
            end: pozplan ? moment.unix(pozplan.datawyk).add(pozplan.extra, 'minutes').format('HH:mm') : '',
            rap: pozplan ? pozplan.raport : '',
            czas: pozplan ? pozplan.extra : '',
            lost: pozplan ? pozplan.lost : false,
            materialy: materialy,
            pracownicy: (pozplan && robolMap[pozplan.id]) ? robolMap[pozplan.id].map((r) => mainStore.ludzie.idMap[r.parent]) : []
          };

          if(filter(newEntry)){
            newStruct.entries.push(newEntry);
          }
        }
      }

      data.struktury.push(newStruct);
    }
    return data;
  }

  drukujOsobowy = async () => {
    const data = await this.getData();
    WydrukOsobowy(data);
  }

  drukujArchiwum = async () => {
    const cosiekFilter = this.state.cosiek > 0 ? this.state.cosiek : 0;
    const ludzikFilter = this.state.ludzik > 0 ? this.state.ludzik : 0;
    const nazwaFilter = this.state.nazwas !='' ? this.state.nazwas : false;
    const data = await this.getData(true, (e) => {
      console.log(cosiekFilter, ludzikFilter, nazwaFilter)
      if(cosiekFilter){
        if(!e.materialy.find((mat) => mat.uzyto == cosiekFilter)){
          return false;
        }
      }
      if(ludzikFilter){
        if(!e.pracownicy.find((pr) => pr.id == ludzikFilter)){
          return false;
        }
      }
      if(nazwaFilter){
        if(!e.praca.toLowerCase().includes(nazwaFilter.toLowerCase())){
          return false;
        }
      }
      return true;
    });

    data.maintitle.replace('Plan', 'Raport prac')

    WydrukArchiwum(data);
  }

  exportPracownika = async () => {
    const data = await this.getData();
    EksportPracownikow(data);
  }

  exportKrotki = async () => {
    const data = await this.getData();
    EksportKrotki(data);
  }

  exportCzasowy = async () => {
    const data = await this.getData();
    EksportCzasowy(data);
  }

  cosiekAccessor = (e) => ({key: e.id, value: e.nazwas});
  ludzikAccessor = (e) => ({key: e.id, value: e.podpis});

  setCosiek = (newValue) => {
    this.setState({cosiek: newValue})
  }

  setLudzik = (newValue) => {
    this.setState({ludzik: newValue})
  }

  render() {
    //console.log(mainStore);
    //console.log(mainStore.archiwum.data.pozplans);
    if(mainStore.selectedStruct === undefined){
      return "Wybierz strukturę bazową do wyszukiwania"
    }
    let pp = {}
    if(mainStore.archiwum.selectedPozPlan){
      pp = mainStore.archiwum.selectedPozPlan;
    }
    //console.log(mainStore.archiwum.data.pozplans);
    //console.log('a');
    return (
      <Container>
        <Row>
          <Col span="3">
            <Container panel vfull vscrolled>
              <h1> Archiwum </h1>
              <Row> 
                <Col span="12">
                  <DateInputRange value={mainStore.archiwum.dateRange} onChange={(newDay) => mainStore.archiwum.dateRange = newDay}/>
                </Col>
              </Row>
              Dla struktury: <Icon icon={getStructIcon(mainStore.selectedStruct)}>{mainStore.selectedStruct.nazwas}</Icon>
              <hr />
                <SmartInput value={this.state.cosiek} options={mainStore.cosieks} accessor={this.cosiekAccessor} onChange={this.setCosiek}/>
                <SmartInput value={this.state.ludzik} options={mainStore.ludzie.sortedList} accessor={this.ludzikAccessor} onChange={this.setLudzik}/>
                <Input placeholder="Filtr nazwy pracy" value={this.state.nazwas} onChange={(e) => this.setState({nazwas: e.target.value})} />
                <hr />
              <div>
                <Button block onClick={this.loadArchiwum}><Icon icon="fa fa-download"/> Pobierz</Button>
                <Button block onClick={this.drukujOsobowy}><Icon icon="fa fa-print" /> Drukuj Osobowy</Button>
                <Button block onClick={this.drukujArchiwum}><Icon icon="fa fa-print" /> Drukuj Archiwum</Button>
                <Button block onClick={this.exportPracownika}><Icon icon="fa fa-print" /> Export Pracowników</Button>                
                <Button block onClick={this.exportKrotki}><Icon icon="fa fa-print" /> Export Krótki</Button>                
                <Button block onClick={this.exportCzasowy}><Icon icon="fa fa-print" /> Export Czasowy</Button>                
              </div>
            </Container>
          </Col>
          <Col span="6">
            <Container panel vfull vscrolled>
              <Table 
                cols={wykonanieTable} 
                data={mainStore.archiwum.data.pozplans} 
                onClick={(row) => mainStore.archiwum.selectedPozPlan = row}
                selectedID={mainStore.archiwum.selectedPozPlan ? mainStore.archiwum.selectedPozPlan.id : -1}
              />
            </Container>
          </Col>
          <Col span="3">
            <Container panel vfull vscrolled>
              <RowSet caption="Nazwa skrócona:"><Label>{pp.nazwas}</Label></RowSet>
              <RowSet caption="Nr.Raportu:"><Label>{pp.raport}</Label></RowSet>
              <RowSet caption="Pracownicy:">{pp.robols && pp.robols.map((e) => <Label><sub>{moment(e.start).format('HH:mm')}-{moment(e.stop).format('HH:mm')}</sub> {mainStore.archiwum.data.ludziks && mainStore.archiwum.data.ludziks[e.parent] ? mainStore.archiwum.data.ludziks[e.parent].podpis : e.parent}</Label>)}</RowSet>
              <RowSet caption="Części:">{pp.parts && pp.parts.map((e) => <Label>{e.ilefakt}{e.jm} <b>{e.nazwas}</b></Label>)}</RowSet>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(Archiwum);
