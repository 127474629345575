import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Input } from 'components/Input';
import { Table } from 'components/Table';
import { TextArea } from 'components/TextArea';
import { firmyTable, firmyData } from 'model/tables/firmy';
import { RowSet } from 'components/Grid'; 
import { view } from 'react-easy-state';

class Roll20 extends Component {

  state = {
    currentRow: {
      nrKont: [],
      adres: {},
      adresKorespondencyjny: {}
    }
  }

  render() {
    const crow = this.state.currentRow;
    return (
      <Container fill panel vfull vscrolled>
        <h1>Roll20</h1>
<pre>
        {`System is based on d20 rolls

Every aspect of a character is divided into 3 base statistics and one health statistic.

Aspects (example):

Physical
Mental
(optionally more including listed and homebrewed aspects. Aspects usually attack and defend against themselves so dont add too many)
Spiritual
Magical
Psionic
Divine
Demonic


Attributes:

Strength - Offense. how strong you are in the aspect.
  Physical Strength - how much you can lift, how strong can you hit, how big weapons you can use. How well you can climb, how well you run, how much you can bench press.
  Mental Strength - how fast you think, how much mind-over-body you have, how much you can remember, How much you can push yourself by focusing your will. Willpower.
  Spiritual Strength - strength of spirit, how hard are your spiritual blows, divine spells, spirit walking etc...
  
Resilience - Defense. how hard is it to hurt you, how much damage can you sustain.
  Physical Resilience - how much damage can you ignore before you start losing effectiveness, how well you absorb hits, how long can you run, how long can you climb, how long can you train.
  Mental Resilience - how much trauma you can sustain, now much exhaustion you can sustain and still go at 100%, detecting deception. How much mental damage can you shrug off.
  Spiritual Resilience - how much spiritual damage can you sustain. Your spiritual defenses.
  
Acuity - Precision. How hard is it to hit you, how well you hit others, how well you shoot.
  Physical Acuity - How well you open locks with lockpicks, how precisely can you hit, how well you shoot, how well you steal, balance, sneakiness, reflexes.
  Mental Acuity - How precise are you in your thinking, planning, executing complex plans. How well you spot/investigate things, how well you lie, deceive, act. How well you jugde value and attributes of people/objects.
  Spiritual Acuity - how well you detect spiritual effects, how well you can analyze and find weaknesses in spiritual weaves. How undetected can you be in penetrating defenses.
  
+ Health.
  Health is a measure of the amount of health you have in a particular aspect.
  Physical health measures how well your body is doing, if it goes below physical resilience, you get disadvantage on all physical actions. If it reaches 0, you die.
  Mental health measures how well your mind is doing, if it goes below mental resilience, you get disadvantage on all mental actions. If it reaches 0, you die.
  Spiritual Health - how well your spirit is doing (...)
  
Skills:
	Physical
  Ahtletics (depends)
  Acrobatics (depends)
  Weapon fighting
  Shooting
  Unarmed fighting
  Sneak
  Steal
  Pick locks
  Dancing
  
	Mental
  Spot (acuity)
  Investigate (acuity)
  Deception (acuity)
  Acting (acuity)
  Insight (acuity)
  Science (poisons, warp engines, etc...) (strength)
  History (Faerun, biotech, ...) (strength)
  Religion (Dunmer, Caldera orcs, ...) (strength)
  
	Spiritual
  Detect spirits
  Identify spirit aspect
  Charms
  Astral projection
  (...)

Armor(shields included):
  Armor gives two values:
    Resilience: Amount added to your resilience for the purpose of defense against a physical attack
	Acuity: Amount added to your Acuity for the purpose of to-hit DC.
	
Weapon:
  Weapons give two values:
	Strength: Amount added to your strength for the purpose of damaging an opponent
	Acuity: Amount added to your Physical Acuity for the purpose of hitting an opponent
	
Dice:
  When you attack with a sword, against an aware and active fighter, you roll a to-hit:
    d20 + weapon fighting + Physical acuity vs 11 + Weapon fighting + Physical acuity
	
  If You hit, you roll:
    d20 + physical strength vs 11 + physical resilience
	
  For each 2 points above hit you remove one health point from target.
  
  For a skill check, you roll 
    d20 + skill vs DC where
	
DC of 
5 is easy
10 is average
15 is hard
20 is very hard
25 is legendary
30 is impossible

everyone starts with 0 in all attributes

increasing attributes costs 1 for each level cumulative
0 to 1 = 1 point cost
1 to 2 = 2 points
2 to 3 = 3 points
etc...

increasing skills costs 1 for each two levels cumulative
0 to 1 = 1 point cost
1 to 2 = 1 point cost
2 to 3 = 2 point cost
3 to 4 = 2 point cost
4 to 5 = 3 point cost

Everyone gets 10 health points in all aspects
`}</pre>

      </Container>
    );
  }
}

export default view(Roll20);
