import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import Select from 'components/Select';
import Input from 'components/Input';
import { Button } from 'components/Button';
import mainStore from '../../stores/store';
import { view, store } from 'react-easy-state';
import DateInput from 'components/Input/DateInput';
import moment from 'moment';
import { trybyPrac } from '../../model/punkt/punktModel';
import { TextArea } from 'components/TextArea';
import { savePunkt, deletePunkt } from '../../model/punkt/savePunkt';
import Icon from '../../components/Icon/index';
import Label from '../../components/Label/index';
import { getFullTree } from '../../model/wykonanie/getFullTree';
import { getCosieks } from '../../model/cosiek/getCosieks';
import { getLudzie } from '../../model/ludzik/getLudzie';
import flattenTree from '../../util/flattenTree';
import getJMFromCosiek from '../../util/getJMFromCosiek';
import { wykonajPunkty } from '../../model/wykonanie/wykonajPunkty';
import { getWykRecord } from '../../model/wykonanie/wykonanieModel';
import { wykonajWykRecords } from '../../model/wykonanie/wykonajWykRecords';
import SmartInput from '../../components/Input/SmartInput';
import arrayToMap from '../../util/arrayToMap';
import { wykonajPunktyNew } from '../../model/wykonanie/wykonajPunktyNew';
import idProvider from '../../util/idProvider';


export function getPT(pid, prid, paid){
  return getFullTree(pid, prid, paid).then((json) => {
    //console.log(json);
    let punkt = {};
    if(json.punkty.length>0){
      const jpunkt = json.punkty[0];
      punkt = {
        dataplan: new Date(),
        id: pid[0],
        nazwa: jpunkt.nazwa,
        nazwas: jpunkt.nazwas,
      }
  
    }
    return {
      punkt: punkt,
      prace: json.prace.map((p) => ({
        id: p.id,
        czas: p.czas,
        nazwa: p.nazwa,
        nazwas: p.nazwas,
        children: json.parts.filter((pa) => pa.parent == p.id).map((pa) => ({
          id: pa.id, 
          nazwas: pa.nazwas,
          nazwa: pa.nazwa,
          oryginal: pa.orginal,
          ileplan: pa.ileplan,
          ilefakt: pa.ileplan,
          jm: pa.jm
        }))
      }))
    } 
  });
}  

class WykonajScreen extends Component {

  wykludziefilter = (e) => {
    //console.log(e);
    return (e.maild.includes('|'+mainStore.currentProject.id+'|'))
  };

  constructor(props) {
    super(props);

    //if we get a punkt, just add the contents from DB 
    // and transform to current format.

    this.store = store({
      data: new Date(),
      starth: 6,
      startm: 0,
      endh: 14,
      endm: 0,
      nrrap: '',
      info: '',
      pracownicy: [],
      newPracownikID: -1,
      data: {}
    })

    let promise = Promise.resolve();

    const punktIDs = [];
    const punktIDMap = {};
    const structMap = {};
    for(let i = 0; i < props.rows.length; i++){
      const row = props.rows[i];
      const pid = row.row.punkt.id;
      if(!punktIDMap[pid]){
        punktIDs.push(pid);
      }
      if(!structMap[row.row.struct.id]){
        structMap[row.row.struct.id] = row.row.struct;
      }
    }

    promise = promise.then(() => getFullTree(punktIDs)).then((result) => {
      console.log(result);
      //now we do the execution:
      //lets create the execution records now:
      const punktyMap = {};
      const praceMap = {};
      result.punkty.map((p) => {
        punktyMap[p.id] = p;
        p.prace = [];
      });
      result.prace.map((p) => {
        praceMap[p.id] = p;
        p.parts = [];
        if(!punktyMap[p.parent].prace){
          punktyMap[p.parent].prace = [];
        }
        punktyMap[p.parent].prace.push(p);
      });
      result.parts.map((p) => {
        if(!praceMap[p.parent].parts){
          praceMap[p.parent].parts = [];
        }
        praceMap[p.parent].parts.push(p);
        p.ilefakt = p.ileplan;
      });
      
      const wykDates = {};

      for(let i = 0; i < props.rows.length; i++){
        const row  = props.rows[i];
        if(!wykDates[row.wykDate]){
          wykDates[row.wykDate] = [];
        }
        const adate = moment.unix(row.wykDate).format('YYYY-MM-DD');
        const punkt = {...punktyMap[row.row.punkt.id]};
        punkt.prace = [...punkt.prace];
        for(let j = 0; j < punkt.prace.length; j++){
          punkt.prace[j] = {...punkt.prace[j]};
          const praca = punkt.prace[j];
          praca.parts = [...praca.parts];
            for(let k = 0; k < praca.parts.length; k++){
            praca.parts[k] = {...praca.parts[k]};
            }
        }

        wykDates[row.wykDate].push({
          punkt: punkt,
          struct: row.row.struct,
          planDate: adate,
          wykDate: adate,
        })
      }      

      console.log(wykDates);
      this.store.data = wykDates;

      //this.store.prace = result.prace.filter((e) => e.id == props.pracaID);
    });

    mainStore.bigLoading = true;
    getCosieks().then(getLudzie).then(() => {
      mainStore.ludzie.wykLudzie = mainStore.ludzie.sortedList.filter((e) => e.typ==1);
      this.store.newPracownikID = mainStore.ludzie.wykLudzie.filter(this.wykludziefilter)[0].id;
      mainStore.bigLoading = false;
    }).catch((err) => {
      mainStore.bigLoading = false;
    })
  }

  newPart = (praca, nextPartID, cosiekArray) => {
    const pa = cosiekArray.find((c) => c.id === nextPartID);
    console.log(pa);
    if(!pa) return;

    if(pa.volumetyp==9) pa.jm = 'kg';
    if(pa.volumetyp==8) pa.jm = 'l';
    if(pa.volumetyp==2916) pa.jm = 'szt';

    const newPart = {
      id: -idProvider.getNext(), 
      parent: praca.id,
      nazwas: pa.nazwas,
      nazwa: pa.nazwa,
      oryginal: pa.id,
      ileplan: 0,
      ilefakt: 0,
      jm: pa.jm
    }
    return newPart;
  }

  newPraca = (praca) => {
    return getFullTree([], praca.id).then((result) => {
      const pracaProto = result.prace.find((p) => p.id === praca.id);
      const parts = result.parts.filter((pa) => pa.parent === praca.id).map((pa) => {
        
        if(pa.volumetyp==9) pa.jm = 'kg';
        if(pa.volumetyp==8) pa.jm = 'l';
        if(pa.volumetyp==2916) pa.jm = 'szt';
    
        return {
          id: -idProvider.getNext(), 
          parent: praca.id,
          nazwas: pa.nazwas,
          nazwa: pa.nazwa,
          oryginal: pa.id,
          ileplan: pa.ileplan,
          ilefakt: pa.ileplan,
          typ: 0,
          jm: pa.jm
        }
      });
      pracaProto.parts = parts;
      pracaProto.typ = 0;
      return pracaProto;
    });
  }

  wykonaj = () => {
    //gather all the data (execute punkt X for date Y with prace Z and partsy A)
    const punkty = {};
    for(let data in this.store.data){
      const punktyPerData = this.store.data[data];
      console.log(punktyPerData);
      for(let i = 0; i < punktyPerData.length; i++){
        const wyk = punktyPerData[i];
        console.log(wyk);
        if(wyk.punkt.id < 0 && wyk.punkt.strikethrough) {
          //jak jest nowy i usunięty to nic z nim nie robimy.
          continue;
        }
        if(!punkty[wyk.punkt.id]) {
          punkty[wyk.punkt.id] = {
            id: wyk.punkt.id,
            parent: wyk.struct.id,
            wyks: []
          }
        }
        punkty[wyk.punkt.id].wyks.push({
          dataPlan: wyk.planDate,
          dataWyk: wyk.wykDate,
          deleted: wyk.punkt.strikethrough === true,
          prace: wyk.punkt.prace.map((pr) => {
            if(pr.id < 0 && pr.strikethrough) {
              return undefined;
            }
            return {
              id: pr.id,
              typ: pr.typ,
              deleted: pr.strikethrough === true,
              parts: pr.parts.map((pa) => {
                if(pa.id < 0 && pa.strikethrough) {
                  return undefined;
                }
                return {
                  id: pa.id,
                  deleted: pa.strikethrough === true,
                  ilefakt: pa.ilefakt,
                  oryginal: pa.oryginal
                }
              }).filter((pa) => pa !== undefined)
            }
          }).filter((pr) => pr !== undefined)
        })
      }
    }

    const punktArray = [];
    for(let i in punkty){
      punktArray.push(punkty[i]);
    }

    const people = this.store.pracownicy;

    const workerHours = {
      starth: this.store.starth,
      startm: this.store.startm,
      endh: this.store.endh,
      endm: this.store.endm
    }
    
    const pushObject = {
      punkty: punktArray, 
      people: people,
      workerHours: workerHours,
      nrrap: this.store.nrrap,
      info: this.store.info
    }

    mainStore.bigLoading = true;
    wykonajPunktyNew(pushObject).then(() => {
      mainStore.bigLoading = false;
      this.props.closeAction();
    }).catch((e) => {
      mainStore.bigLoading = false;
      mainStore.bigError = e.message;
    });
  }

  CosiekAccessor = (c) => ({key:c.id, value:c.nazwas});

  static class = 'modal-fill';

  render() {
    return ( 
      <Container fill>
        <Row>
          <Col span="4">
            <Row>
              <Col span="4">Nr. Raportu</Col>
              <Col span="8"><Input value={this.store.nrrap} onChange={(event) => this.store.nrrap = event.target.value } /></Col>              
            </Row>
            <Row>
              <Col span="4">Godzina startu</Col>
              <Col span="1"><Input value={this.store.starth} onChange={(e) => this.store.starth = e.target.value }/></Col>
              <Col span="1"><Input value={this.store.startm} onChange={(e) => this.store.startm = e.target.value }/></Col>
            </Row>
            <Row>
              <Col span="4">Godzina zakończenia</Col>
              <Col span="1"><Input value={this.store.endh} onChange={(e) => this.store.endh = e.target.value }/></Col>
              <Col span="1"><Input value={this.store.endm} onChange={(e) => this.store.endm = e.target.value }/></Col>
            </Row>
            <Row>
              <Col span="4">Opis</Col>
              <Col span="8"><TextArea value={this.store.info} onChange={(e) => this.store.info = e.target.value} /></Col>
            </Row>
            <Row>
              <Col span="4">Pracownicy:</Col>
              <Col span="8">
                {mainStore.ludzie.wykLudzie.filter(this.wykludziefilter).map((ludz) => {
                  const pchosen = this.store.pracownicy.find((e) => e === ludz.id);
                  if(pchosen){
                    return <Button key={ludz.id} green block onClick={() => this.store.pracownicy = this.store.pracownicy.filter((p) => ludz.id !== p)}>{ludz.podpis}</Button>
                  } else {
                    return <Button key={ludz.id} red block onClick={() => this.store.pracownicy = [...this.store.pracownicy, ludz.id]}>{ludz.podpis}</Button>
                  }
                })}
              </Col>
            </Row>
          </Col>
          <Col span="8">
            {Object.keys(this.store.data).sort().map((date) => {
              const rows = this.store.data[date];
              return <Row key={date}>
                  Data planowana: {moment.unix(date).format('YYYY-MM-DD')}
                  <hr />
                  {rows.map((row) => <Row micro key={date+'_'+row.punkt.id}>
                      <Col span="3">
                      {row.punkt.strikethrough ? 
                        <Label><strike>{row.struct.nazwas} / {row.punkt.nazwas}</strike></Label>
                        :
                        <Label>{row.struct.nazwas} / {row.punkt.nazwas}</Label>
                      }
                        <Input value={row.wykDate} onChange={(e) => row.wykDate = e.target.value} />
                      </Col>
                      <Col span="1">
                        <Button red onClick={(e) => row.punkt.strikethrough = !row.punkt.strikethrough}><Icon icon="fa fa-fw fa-trash"/></Button>
                      </Col>
                      <Col span="8">
                        {row.punkt.prace.map((praca) => <Row micro key={date+'_'+row.punkt.id+'_'+praca.id}>
                          <Col span="5">
                            {praca.strikethrough ? 
                              <Label><strike>{praca.nazwas}</strike></Label>
                              :
                              <Label>{praca.nazwas}</Label>
                            }
                          </Col>
                          <Col span="2">
                            <Button red onClick={(e) => praca.strikethrough = !praca.strikethrough}><Icon icon="fa fa-fw fa-trash"/></Button>
                            {
                              (praca.typ !== undefined) ?
                              (
                                praca.typ
                                ? 
                                <Button red onClick={(e) => praca.typ = praca.typ ? 0 : 1}><Icon icon="fa fa-fw fa-exclamation-triangle"/></Button>
                                :
                                <Button green onClick={(e) => praca.typ = praca.typ ? 0 : 1}><Icon icon="fa fa-fw fa-wrench"/></Button>
                              )
                              :
                              ''
                            }
                          </Col>
                          <Col span="5">
                            {praca.parts.map((part) => <Row micro key={date+'_'+row.punkt.id+'_'+praca.id+'_'+part.id}>
                              <Col span="5">
                                {part.strikethrough ? 
                                  <Label><strike>{part.nazwas}</strike></Label>
                                  :
                                  <Label>{part.nazwas}</Label>
                                }
                              </Col>
                              <Col span="5">
                                <Input inline value={part.ilefakt} backText={part.ilefakt + " " + part.jm} onChange={(e) => part.ilefakt = e.target.value}/>
                              </Col>
                              <Col span="2">
                                <Button red onClick={(e) => part.strikethrough = !part.strikethrough}><Icon icon="fa fa-fw fa-trash"/></Button>
                              </Col>
                            </Row>)}
                            <Row micro>
                              <Col span="8">
                                <SmartInput value={praca.nextPart} options={mainStore.cosieks} accessor={this.CosiekAccessor} onChange={(newValue) => praca.nextPart = newValue}/>
                              </Col>
                              <Col span="4">
                                <Button block onClick={(e) => {
                                  const np = this.newPart(praca, praca.nextPart, mainStore.cosieks);
                                  if(np) {
                                    praca.parts.push(np);
                                  }
                                }}><Icon icon="fa fa-fw fa-plus"/> Materiał</Button>
                              </Col>
                            </Row>
                            <hr />
                          </Col>
                        </Row>)}
                        <Row micro>
                              <Col span="8">
                                <SmartInput value={row.nextPraca} options={mainStore.currentProject.repoPrac.sorted} accessor={this.CosiekAccessor} onChange={(newValue) => row.nextPraca = newValue}/>
                              </Col>
                              <Col span="4">
                                <Button block onClick={(e) => {
                                  const pp = mainStore.currentProject.repoPrac.idMap[row.nextPraca];
                                  const np = this.newPraca(pp).then((p) => {
                                    console.log(p);
                                    row.punkt.prace.push(p)
                                  });
                                }}><Icon icon="fa fa-fw fa-plus"/> Pracę</Button>
                              </Col>
                            </Row>
                      </Col>
                  </Row>)}
                  <hr />
                </Row>
            })}
          </Col>
        </Row>
        <Row>
          <Col span="10"/>
          <Col span="2">
            {this.store.nrrap == '' ? 
              <Button block ><Icon icon="fal fa-save" /> Wykonaj</Button>
            :
              <Button green block onClick={this.wykonaj}><Icon icon="fal fa-save" /> Wykonaj</Button>
            }
          </Col>
        </Row>            
      </Container>
    );
  }
}

export default view(WykonajScreen);
