import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Input } from 'components/Input';
import { Table } from 'components/Table';
import { brygadyTable, brygadyData, brygadyLudzieTable } from 'model/tables/brygady';
import { TextArea } from 'components/TextArea';

class Brygady extends Component {

  state = {
    currentRow: undefined
  }

  render() {
    return (
      <Container fill>
        <h1>Brygady</h1>
        <Row>
          <Col span="4">
            <Table cols={brygadyTable} data={brygadyData} onClick={(row) => this.setState({currentRow: row})} edit />
          </Col>
        { this.state.currentRow && (
          <Col span="8">
            Nazwa: <Input value={this.state.currentRow.name} /> <br />
            Pozycja cennika: {this.state.currentRow.cennik} <br/>
            Stawka cennikowa: 170 PLN/godz. <br/>
            Suma stawek ludzi: 370 PLN/godz. <br/>
            <TextArea value={this.state.currentRow.uwagi} />
            <Table cols={brygadyLudzieTable} data={this.state.currentRow.contents} edit />
          </Col>
        ) }
        </Row>
      </Container>
    );
  }
}

export default Brygady;
