import { styles } from "./WydrukShared";

const WydrukOsobowy = (data) => {
    console.log(data);
    const result = window.open("", "Title", "scrollbars=yes,resizable=yes,width=1600,height=800,top=0,left=0)");
    
    const linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    linkElement.setAttribute('href', 'data:text/css;charset=UTF-8,' + encodeURIComponent(styles));

    let lastStruct = undefined;

    result.document.head.innerHTML = `<meta name="viewport" content="width=device-width, initial-scale=1.0">`;
    result.document.body.innerHTML = `
        <header>
        <div class="topleft">${data.provider}</div>
        <div class="topcenter">${data.title}</div>
        <div class="topright">${data.client}</div>
        <div class="maintitle">${data.maintitle}</div>
        </header>
    ${data.struktury.map((s) => `
    <hr />
    <h2>${s.struct.nazwas}</h2>
    <table>
        <thead>
        <tr className="border">
            <th>struktura</th>
            <th>praca</th>
            <th>czynność</th>
            <th class="w1cm right">plan</th>
            <th class="w2cm">wyk</th>
            <th class="w3cm">uwagi</th>
        </tr>
        </thead>
        <tbody>
        ${s.entries.map((e) => {
            const localStruct = e.struct.join(', ');
            const out = `
            <tr>
                ${localStruct !== lastStruct ? `<td>${localStruct}</td>` : `<td class="noBorder"></td>`}
                <td>${e.praca}</td>
                <td>${e.czynnosc}</td>
                <td></td>
                <td>${e.rap!=='' ? (e.lost ? 'Porzucono' : 'Wykonano') : ''}</td>
                <td>${e.rap!=='' ? '---' : ''}</td>
            </tr>
            ${e.materialy.map((m) => `
                <tr>
                <td class="noBorder"></td>
                <td class="noBorder right">materiał</td>
                <td>${m.nazwa}</td>
                <td class="right">${m.ile}${m.jm}</td>
                <td>${e.rap!=='' ? 'Zużyto' : ''}</td>
                <td>${e.rap!=='' ? '---' : ''}</td>
                </tr>
            `).join('')}
            `;
            lastStruct = localStruct;
            return out;
        }).join('')}
        </tbody>
    </table>

    `).join('')}
        <br />
        <br />
        <br />
        <hr />
        <div class="float-left">STMIS</div>
        <div class="float-right">Klient</div>
    `;

    result.document.body.append(linkElement);
    const script = document.createElement('script');
    script.innerHTML = "window.print()";
    result.document.body.append(script);
}

export default WydrukOsobowy;