import React, {Component} from 'react';
import moment from 'moment';

import './WeekGraph.css';
import { view } from 'react-easy-state';

export const cutoffUnixDate = new moment("1980-01-01").unix();

const structColumnWidth = 10;

function WeekGraphPreciseElement(props) {
  return <div key={props.key} style={ { left: (props.left*1.5)+'rem', top: (props.top*1.5)+'rem', width: (typeof(props.width)==='number' ? (props.width * 1.5) + 'rem' : props.width), backgroundColor: props.bgColor } } className={'weekGraphElement '+props.className}>{props.caption}</div>;
}

function WeekGraphPreciseElementLeft(props) {
  return <div key={props.key} style={ { left: (props.left*1.5)+'rem', top: (props.top*1.5)+'rem', width: (typeof(props.width)==='number' ? (props.width * 1.5) + 'rem' : props.width), backgroundColor: props.bgColor, textAlign: 'left' } } className={'weekGraphElement '+props.className}>{props.caption}</div>
}

function WeekGraphElement(props) {
  return <div key={props.key} style={ { left: (props.left*1.5)+'rem', top: (props.top*1.5)+'rem' } } className={'weekGraphElement '+props.className} onClick={props.onClick} onContextMenu={props.onRightClick} data-localid={props.localID}>{props.caption}</div>
}

function WeekGraphGridElement(props) {
  return <div key={props.key} style={ { left: (props.left*1.5)+'rem', top: (props.top*1.5)+'rem', height: (props.height*1.5)+'rem'} } className="weekGraphElement weekGraphGridElement">
    <div className={'weekGraphElement clickable '+props.className} onClick={props.onClick} data-week={props.dataWeek}>{props.caption}</div>
  </div>
}

function WeekGraphHorzGridElement(props) {
  return <div key={props.key} style={ { left: (props.left*1.5)+'rem', top: (props.top*1.5)+'rem', width: (props.width*1.5)+'rem'} } className="weekGraphElement weekGraphHorzGridElement"></div>
}

class WeekGraph extends Component {

  constructor(props){
    super(props);

    this.state={
      selected: {}
    }
  }

  onElementClick = (e) => {
    e.preventDefault();
    
    const localID = e.target.dataset.localid;
    const node = this.getRow(localID);

    console.log(node);
    //node.wykNode.pozplan && node.wykNode.pozplan.datawyk > cutoffUnixDate
    let allDone = true;
    node.wykNode.forEach((wn) => allDone = allDone && (wn.pozplan && ((wn.pozplan.datawyk > cutoffUnixDate) && (wn.pozplan.raport !== "0"))));
    if(allDone) {
      if(this.props.onClick){
        const result = this.props.onClick(node.row, node.wykNode);
        if(!result) return;
      }
    }
    if(this.props.onSelChange){
      const newSelected = this.props.onSelChange(node, (sel) => {
        if(sel) {
          this.setState({selected: sel});
        }
      });
      if(!newSelected) return;
      this.setState({selected: newSelected});  
    }
  }

  weekClick = (e) => {
    const week = parseInt(e.currentTarget.dataset.week);
    let startDate = moment.unix(this.props.startDate);
    let startWeek = startDate;
    const newSelected = {...this.state.selected};
    
    let changed = false;

    for(let j = 0; j < this.props.rows.length; j++){
      const row = this.props.rows[j];
      //lets add the struct, punkt and praca labels
      for(let i in row.wykonanie){
        const wykNode = row.wykonanie[i];
        for(let k in wykNode){
          if(wykNode[k].week === week) {
            const selectedKey = row.punkt.id+'_'+week;
            newSelected[selectedKey] = true;
            changed = true;
            break;
          }
        }
      }
    }
    
    if(changed){
      this.setState({selected: newSelected});
      if(this.props.onSelChange){
        this.props.onSelChange(newSelected);
      }
    }
  }
  
  onWykonajElement = (e) => {
    e.preventDefault();

    const localID = e.target.dataset.localid;
    const node = this.getRow(localID);

    if(this.props.onClick){
      this.props.onClick(node.row, node.wykNode);
    }
  }

  getRow(localid) {
    const a = localid.split('_');
    //console.log(this.props.rows[a[0]].wykonanie, a)
    return {
      row: this.props.rows[a[0]],
      wykNode: this.props.rows[a[0]].wykonanie[a[1]]
    }
  }

  getLocalID(rowIndex, wykIndex){
    return rowIndex+'_'+wykIndex;
  }

  render() {
    const props = this.props;
    let nodes = [];
    let startDate = moment.unix(props.startDate).startOf('week');
    let iStartDate = moment(startDate);
    let endDate = moment.unix(props.endDate).endOf('week');
    const now = moment().unix();
    const weeks = [];
    const weekMap = {};

    let week = 0;
    while(iStartDate.isBefore(endDate)){
      const weekObj = {
        unix: iStartDate.unix(),
        date: moment(iStartDate),
        weekNumber: week
      }
      weeks.push(weekObj);
      weekMap[weekObj.unix] = weekObj;
      iStartDate.add(1, 'week');
      week++;
    }

    //policzmy sobie wysokość
    let curHeight = 0;
    let lastStruct = -1;
    let lastPunkt = -1;
    //teraz robimy poszczególne wiersze; 
    for(let j = 0; j < props.rows.length; j++){
      const row = props.rows[j];
      //lets add the struct, punkt and praca labels
      //struct
      if(lastStruct != row.struct.id){
        nodes.push(WeekGraphPreciseElementLeft({ 
          key: 'rowCaptionS_'+row.struct.id, 
          left: 0, 
          top: 3+curHeight, 
          caption: row.structLabel, 
          width: structColumnWidth,
          bgColor: "rgb(216, 207, 204)",
          className: "zCover"
        }));
        lastStruct = row.struct.id;
      }
      //punkt
      if(lastPunkt != row.punkt.id){
        nodes.push(WeekGraphPreciseElementLeft({
          key: 'rowCaptionP_'+row.punkt.id,
          left: structColumnWidth,
          top: 3+curHeight,
          caption: row.punkt.nazwas,
          width: 7,
          bgColor: "rgb(204, 207, 216)",
          className: "zCover"
        })); 
        lastPunkt = row.punkt.id;
      }
      nodes.push(WeekGraphHorzGridElement({
        key: 'rowBottom'+row.punkt.id,
        left: structColumnWidth+7,
        top: 3+curHeight,
        width: weeks.length 
      }));
      const rownodes = [];
      for(let i in row.wykonanie){
        const wykNode = row.wykonanie[i];
        if(!weekMap[i]){
          if(i > startDate.unix() && i < endDate.unix()){
            console.log(i, row, weekMap);
          }
          continue;
        }
        const left = weekMap[i].weekNumber;
        const selectedKey = row.punkt.id+'_'+i;
        let selected = this.state.selected[selectedKey];
        let selN = 0;
        for(let wni = 0; wni < wykNode.length; wni++){
          if(this.state.selected[row.punkt.id+'_'+wykNode[wni].day]){
            selN += 1;
          }
        }

        if(selN === 1 && wykNode.length === 1) selN = 0;
        const late = now > i;
        const planned = wykNode.length;
        const finished = wykNode.reduce(
          (a, e) => (e.pozplan !== undefined) && ((e.pozplan.datawyk > cutoffUnixDate) && (e.pozplan.raport !== "0")) ? a + 1 : a, 0);
        
        let c = '';
        if(planned===finished){
          c = ' finished';
        } else {
          if(finished===0){
            if(late){
              c = ' planned-late';
            } else {
              c = ' planned-notlate';
            }
          } else {
            if(late){
              c = ' planned-finished-late';
            } else {
              c = ' planned-finished-notlate';
            }
          }
        }
        c = c + ' '+planned+'a'+finished+(late?'l':'nl');
        let lostCount = 0;
        for(let i = 0; i < wykNode.length; i++){
          if(wykNode[i].pozplan && wykNode[i].pozplan.lost)
            lostCount ++;
        }
        if(lostCount > 0 && lostCount === wykNode.length){
          c = ' planned-lost';
        }

        rownodes.push(WeekGraphElement({
          key: 'cell_'+selectedKey, 
          left: structColumnWidth+7+left,
          top: 3+curHeight, 
          caption: planned,
          className: "clickable"+(selected ? ' selected' : '')+((selN > 0) ? ' selN' : '')+c,
          onClick: this.onElementClick,
          localID: this.getLocalID(j, i)
        }));
      }
      if(rownodes.length > 0){
        nodes.push(<div key={'rowContainer_'+row.punkt.id}>
          {rownodes}
        </div>)
      }
      curHeight++;
    }

    //rysujemy miesiące, lata i tygodnie.
    for(let i = 0; i < weeks.length; i++) {
      nodes.push(WeekGraphGridElement({
        key: 'weekLabel'+i,
        left: weeks[i].weekNumber+structColumnWidth+7,
        top: 2,
        height: curHeight+1,
        caption: weeks[i].date.week(),
        dataWeek: weeks[i].unix,
        onClick: this.weekClick
      }));
    }
    let date = moment(startDate).date(1);
    while(date.isBefore(endDate)) {
      const leftMonthPos = date.diff(startDate, 'days') / 7;
      const daysInMonth = date.daysInMonth() / 7;
      nodes.push(WeekGraphPreciseElement({
        key: 'monthCaption_'+leftMonthPos,
        left: structColumnWidth+7 + leftMonthPos,
        top: 1,
        width: daysInMonth, 
        caption: date.format('MMM'),
        bgColor: "rgb(204, 204, 230)"
      }))
      if(date.month()===0){
        nodes.push(WeekGraphPreciseElement({
          key: 'yearCaption_'+date.year(),
          left: structColumnWidth+7 + leftMonthPos,
          top: 0, 
          width: daysInMonth,
          caption: date.format('YYYY'),
          bgColor: "rgb(204, 230, 204)"
        }));
      }
      date.add(1, 'month');
    }   

    //console.log(nodes);

    return (
      <div className="week-graph" style={ { height: ((curHeight + 3) * 1.5)+'rem' } }>
        {nodes}
      </div>
    );
  }
};

export default view(WeekGraph);