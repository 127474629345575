import React from 'react';
import './Select.css'
import { view } from 'react-easy-state';
import { Component } from 'react';
import { lstat } from 'fs';

class SelectOptions extends Component {

  shouldComponentUpdate(nextProps){
    if(nextProps.options!==this.props.options) return true;
    if(nextProps.optionLabels!==this.props.optionLabels) return true;
    return false;
  }

  render() {
    this.lastoptions = this.props.options;
    if(this.props.accessor){
      return this.props.options.map((option, index) => {
        const opt = this.props.accessor(option);
        return (<option value={opt.key} key={opt.key}>{opt.value}</option>);
      });
    } else {
      return this.props.options.map((option, index) => (
        <option value={option} key={option}>{this.props.optionLabels ? this.props.optionLabels[index] : option}</option>
      ));
    }
  }
}

export default view((props) => {
  return (  
    <label className={
      "select-wrapper" +
      (props.inline ? ' inline' : '') 
      } >
      <select className={
          (props.className ? props.className : '')
        } 
        onChange={props.onChange} 
        value={props.value}
      >
        <SelectOptions options={props.options} accessor={props.accessor} optionLabels={props.optionLabels}/>
      </select>
    </label>
  )
});
