import React, { Component } from 'react';
import './Input.css'
import { view } from 'react-easy-state';

class Input extends Component {

  constructor(props){
    super(props);

    this.inputRef = React.createRef();
  }

  componentDidUpdate() {
    if(this.inputRef.current && (this.props.cursorPos!==undefined) ){
      this.inputRef.current.selectionStart = this.props.cursorPos;
      this.inputRef.current.selectionEnd = this.props.cursorPos;
    }
  }

  onKeyDown = (e) => {
    if(this.props.onSubmit){
      if(e.key === 'Enter'){
        this.props.onSubmit();
      }
    }
    if(this.props.onKeyDown){
      this.props.onKeyDown(e);
    }
  }

  onFocus = (e) => {
    if(this.props.onFocus){
      this.props.onFocus(e);
    }
  }

  onBlur = (e) => {
    if(this.props.onBlur){
      this.props.onBlur(e);
    }
  }

  render() {
    const props = this.props;

    if(props.disabled){
      return (
        <div className={"input-container input input-disabled " +
          (props.className ? props.className : '') +
          (props.mono ? 'monospace' : '')
        } placeholder={props.placeholder}>{props.backText ? <div className={(props.mono ? 'monospace backtext' : 'backtext')}>{props.backText}</div> : undefined}{props.value}{props.children}</div>
      )
    } 
    if(props.password){
      return (
        <div className="input-container">
          {props.backText ? (
            <div className={(props.mono ? 'monospace backtext' : 'backtext')}>{props.backText}</div>
          ) : undefined}
          <input type="password" className={
            (props.className ? props.className : '') +
            (props.mono ? 'monospace' : '')
          } onChange={props.onChange} 
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={props.value} 
            placeholder={props.placeholder}
            ref={this.inputRef}>{props.children}</input>
        </div>
      )      
    }
    return (
      <div className="input-container">
        {props.backText ? (
          <div className={(props.mono ? 'monospace backtext' : 'backtext')}>{props.backText}</div>
        ) : undefined}
        <input className={
          (props.className ? props.className : '') +
          (props.mono ? 'monospace' : '')
        } onChange={props.onChange} 
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          value={props.value} 
          placeholder={props.placeholder}
          ref={this.inputRef}>{props.children}</input>
      </div>
    )
  }
}

export default view(Input);
