import fetchJSON, { creds } from 'util/fetchJSON';
import { mainStore } from 'stores/store';

export function verifyLogin(user, password){
  mainStore.login.progress = true;
  mainStore.login.message = '';
  return fetchJSON('/api/login/login', {user: user, password: password})
    .then((json) => {
      if(json.loggedIn == true){
        creds.key = json.APIKey;
        creds.id = json.user.id;
        mainStore.APIKey = json.APIKey;
        mainStore.currentUser = json.user;
        mainStore.loggedIn = true;
        localStorage.setItem('APIKey', creds.id+"|"+creds.key);
      } else {
        
      }
      mainStore.login.message = json.message;
      mainStore.login.progress = false;
    })
    .catch(error => {
      mainStore.login.progress = false;
      mainStore.login.message = error;
      console.log(error);
    });
}

export function verifyAPILogin(userID, APIKey){
  console.log('verify API login')
  mainStore.APIKey = undefined;
  mainStore.login.progress = true;
  mainStore.login.message = '';
  return fetchJSON('/api/login/verifyApiKey/', {uid: userID, key: APIKey})
    .then((json) => {
      console.log('done')
      mainStore.login.message = json.message;
      if(json.loggedIn == true){
        console.log('isloggedin')
        creds.key = json.APIKey;
        creds.id = json.user.id;
        localStorage.setItem('APIKey', creds.id+"|"+creds.key);
        mainStore.APIKey = json.APIKey;
        mainStore.currentUser = json.user;
        mainStore.loggedIn = true;
        console.log('setVariables')
      } else {
        mainStore.APIKey = undefined;
      }
    mainStore.login.progress = false;
    console.log('noprogress inlogin')
  })
  .catch(error => {
    mainStore.login.progress = false;
    mainStore.login.message = error;
    mainStore.loggedIn = false;
    mainStore.APIKey = undefined;
    console.log(error);
  });
}

export function logout() {
  mainStore.login.progress = true;
  mainStore.login.message = '';
  return fetchJSON('/api/logout/')
    .then((json) => {
      mainStore.APIKey = undefined;
      mainStore.currentUser = {};
      mainStore.loggedIn = false;
      mainStore.forceProcessUpdate ++;
      creds.key = undefined;
      creds.id = undefined;
      localStorage.removeItem('APIKey');
      mainStore.login.progress = false;
    })
    .catch(error => {
      mainStore.APIKey = undefined;
      mainStore.currentUser = {};
      mainStore.loggedIn = false;
      mainStore.forceProcessUpdate ++;
      creds.key = undefined;
      creds.id = undefined;
      localStorage.removeItem('APIKey');
      mainStore.login.progress = false;
      console.log(error);
    });
}