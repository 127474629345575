import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Button } from 'components/Button';
import { view } from 'react-easy-state';
import TimeChart from '../../components/TimeChart/TimeChart';
import flattenTree from '../../util/flattenTree';
import mainStore from '../../stores/store';
import Text from '../../components/Text/index';
import { getLudzie } from '../../model/ludzik/getLudzie';
import { Icon } from 'components/Icon/index';
import { Input } from 'components/Input';
import Label from '../../components/Label/index';
import moment from 'moment';

function hoursToTime(hours){
  const minutes = parseInt((parseFloat(hours)*60) % 60);
  const hoursN = parseInt(hours);
  return hoursN + ':' + (minutes<10 ? '0'+minutes : minutes);
}

function timeToHours(timeString){
  const a = timeString.split(':');
  if(a.length==1){
    return parseInt(a[0])+':00'
  } else {
    let min = parseInt(a[1]);
    if(isNaN(min)) min = 0;
    return parseInt(a[0])+':'+(min<10 ? '0'+min : min)
  }
}

function parseHour(hourString) {
  if(!hourString) return 0;
  hourString = ''+hourString;
  const a = hourString.split(':');
  if(a.length==1){
    return parseInt(a[0])
  } else {
    let min = parseInt(a[1]);
    if(isNaN(min)) min = 0;
    return parseInt(a[0])+ min/60;
  }
}

class SzybkieWykonanieGodzinne extends Component {

  constructor(props){
    super(props);

    this.state = {
      startGodzina: '6:00',
      endGodzina: '14:00'
    };

    const flatTree = flattenTree(mainStore.wykonanie.treeDoWykonania);
    const treeMap = {};
    flatTree.map((e) => treeMap[e.id] = e);
    //console.log(flatTree);
    mainStore.wykonanie.rgPraceOrdered = [];
    mainStore.wykonanie.rgPrace = flatTree.filter((el) => el.type==='praca').map((el) => {
      if(!el.punkt){ el.punkt = treeMap[el.parent]; }
      if(!el.struct){ el.struct = treeMap[treeMap[el.parent].parent] }
      if(!el.planning){ 
        el.planning = {
          start: parseHour('6:00'),
          end: parseHour('14:00'),
          people: []
        }; 
      }
      return el;
    });
    //console.log(mainStore.wykonanie.rgPrace);

    mainStore.wykonanie.selMap = {};
    //console.log(mainStore.wykonanie.rgPrace);
    
    mainStore.bigLoading = true;
    getLudzie().then(() => {
      mainStore.ludzie.wykLudzie = mainStore.ludzie.sortedList.filter((e) => e.typ==1 && (e.maild.includes('|'+mainStore.currentProject.id+'|')));
      mainStore.bigLoading = false;
    });    
  }

  planTime = (index) => {
    const newElement = mainStore.wykonanie.rgPrace.splice(index, 1)[0];
    mainStore.wykonanie.rgPraceOrdered.push(newElement);
    if(mainStore.wykonanie.rgPraceOrdered.length==1){
      newElement.planning.start = 5;
    } else {
      const lael = mainStore.wykonanie.rgPraceOrdered[mainStore.wykonanie.rgPraceOrdered.length - 2];
      newElement.planning.start = lael.planning.end;
    }
    newElement.planning.end = newElement.planning.start + newElement.extra / 60;

    if(mainStore.wykonanie.selectedRgPraca){
      const selpraca = mainStore.wykonanie.rgPraceOrdered.find((e) => e.id == mainStore.wykonanie.selectedRgPraca)
      if(selpraca) {
        newElement.planning.people = [...selpraca.planning.people]
      }
    }    
  }

  rozplanuj = () => {
    const days = {}
    const dayKeys = [];

    mainStore.wykonanie.rgPrace.map((p) => {
      const midnightThen = moment.unix(p.punkt.dataplan).startOf('day').unix();
      if(!days[midnightThen]) {
        days[midnightThen] = [];
        dayKeys.push(midnightThen);
      }
      days[midnightThen].push(p);
    });

    dayKeys.sort();

    dayKeys.map((dk) => {
      const day = days[dk];
      let h = parseHour(this.state.startGodzina);
      let e = parseHour(this.state.endGodzina);
      let d = (e - h) / day.length;
      const wykLudzie = mainStore.ludzie.wykLudzie.filter((l) => mainStore.wykonanie.selMap[l.id]===true).map((l) => l.id);
      for(let i = 0; i < day.length; i++){
        day[i].planning.start = h;
        day[i].planning.end = h + d;
        day[i].planning.people = [...wykLudzie];
        h += d;
      }
    });

    this.props.closeAction(true);
  }

  static class = 'modal-fill';

  render() {
    //look through pracas, sort them into days
    const days = {}
    const dayKeys = [];

    //console.log(mainStore.wykonanie.rgPrace);
    mainStore.wykonanie.rgPrace.map((p) => {
      //console.log(p);
      const midnightThen = moment.unix(p.punkt.dataplan).startOf('day').unix();
      if(!days[midnightThen]) {
        days[midnightThen] = [];
        dayKeys.push(midnightThen);
      }
      days[midnightThen].push(p);
    });
    //console.log(days, dayKeys);

    dayKeys.sort();

    //fix me up some NaNs 
    return ( 
      <Container fill >
        <Row>
          <Col span="4">
            <h1>Rozplanowanie szybkie:</h1>
          </Col>
          <Col span="2">
            Start: <Input value={this.state.startGodzina} onChange={(e) => this.setState({startGodzina: e.target.value})}/>
          </Col>
          <Col span="2">
            Koniec: <Input value={this.state.endGodzina} onChange={(e) => this.setState({endGodzina: e.target.value})}/>
          </Col>
        </Row>
        <Row>
          <Col span="9">
            <Row>
              <Col span="3">
                <Label>Data</Label>
              </Col>
              <Col span="3">
                <Label>Ilość prac w dniu</Label>
              </Col>
              <Col span="2">
                <Label>Start pracy</Label>
              </Col>
              <Col span="2">
                <Label>Koniec pracy</Label>
              </Col>
              <Col span="2">
                <Label>Czas pracy</Label>
              </Col>
            </Row>
            {dayKeys.map((dk) => {
              const day = days[dk];
              //console.log(days, dk, day);
              return (<Row key={dk}>
                <Col span="3">
                  <Input value={moment.unix(dk).format('YYYY-MM-DD')} />
                </Col>
                <Col span="3">
                  <Input value={day.length} />
                </Col>
                <Col span="2">
                  <Input value={hoursToTime(parseHour(this.state.startGodzina))} />
                </Col>
                <Col span="2">
                  <Input value={hoursToTime(parseHour(this.state.endGodzina))} />
                </Col>
                <Col span="2">
                  <Input value={hoursToTime(parseHour(this.state.endGodzina) - parseHour(this.state.startGodzina))} />
                </Col>
              </Row>)
            })}
          </Col>
          <Col span="3">
            {mainStore.ludzie.wykLudzie.map((e) => {
              if(mainStore.wykonanie.selMap[e.id]===true){
                return <Button key={e.id} green block onClick={() => mainStore.wykonanie.selMap[e.id] = false}>{e.podpis}</Button>;
              } else {
                return <Button key={e.id} block onClick={() => mainStore.wykonanie.selMap[e.id] = true}>{e.podpis}</Button>
              }
            } )}
          </Col>
        </Row>
        <Row>
          <Col span="9"> </Col>
          <Col span="3">
            <Button green block onClick={this.rozplanuj}><Icon icon="fa fa-fw fa-lg fa-clock"/> Rozplanuj</Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(SzybkieWykonanieGodzinne);
