import React from 'react';
import './Button.css'
import { NavLink } from 'react-router-dom';
import { view } from 'react-easy-state';

export default view((props) => props.to ? (
  <NavLink to={props.to} className={
    'navlink-button ' +
    (props.spaced ? 'spaced ' : '') + 
    (props.block ? 'block ' : '') + 
    (props.primary ? 'primary ' : '') + 
    (props.secondary ? 'secondary ' : '') + 
    (props.tetriary ? 'tetriary ' : '') + 
    (props.green ? 'green ' : '') + 
    (props.red ? 'red ' : '') + 
    (props.inverted ? 'inverted ' : '') + 
    (props.small ? 'small ' : '') + 
    (props.big ? 'big ' : '') + 
    (props.fill ? 'fill ' : '') + 
    (props.inputHeight ? 'inputHeight ' : '') + 
    (props.nolrpadding ? 'nolrpadding ' : '') + 
    (props.className ? props.className : '')
  } exact={props.exact} activeClassName="selected" style={props.style}>{props.children}</NavLink>
) : (
  <button className={
    (props.spaced ? 'spaced ' : '') + 
    (props.block ? 'block ' : '') + 
    (props.primary ? 'primary ' : '') + 
    (props.secondary ? 'secondary ' : '') + 
    (props.tetriary ? 'tetriary ' : '') + 
    (props.green ? 'green ' : '') + 
    (props.red ? 'red ' : '') + 
    (props.inverted ? 'inverted ' : '') + 
    (props.small ? 'small ' : '') + 
    (props.big ? 'big ' : '') + 
    (props.fill ? 'fill ' : '') + 
    (props.inputHeight ? 'inputHeight ' : '') + 
    (props.nolrpadding ? 'nolrpadding ' : '') + 
    (props.className ? props.className : '')
  } onClick={props.onClick} style={props.style}>{props.children}</button>
));
