import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Select } from 'components/Select';
import { Input } from 'components/Input';
import Table from 'components/Table/index';
import { materialyTable, materialyData } from 'model/tables/materialy';
import { Button } from 'components/Button';
import { view } from 'react-easy-state';
import { getCosieks } from '../../model/cosiek/getCosieks';
import Loading from './../../components/Loading/index';
import { mainStore } from 'stores/store';
import Icon from './../../components/Icon/index';
import { savePart, deletePart } from '../../model/part/savePart';

class NewMaterial extends Component {

  static class = 'modal-small';

  constructor(props) {
    super(props);
    getCosieks().then(() => {
      if(this.state.oryginal==0){
        this.setState({oryginal: mainStore.cosieks[0].id})
      }
    });
    if(props.material){
      this.state = props.material;
    } else {
      this.state = {
        id: -1,
        typ: 0,
        oryginal: 0,
        nazwas: 'Nowy',
        ileplan: 0
      };
    }
  }

  updateCosiek = (e) => {
    this.setState({oryginal: e.target.value});
  }

  savePart = (e) => {
    mainStore.materialy_loading = true;
    savePart(this.state.id, mainStore.selectedPraca.id, this.state.typ, this.state.oryginal, this.state.ileplan).then(() => {
      mainStore.materialy_loading = false
      this.props.closeAction(true);
    });
  }

  deletePart = (e) => {
    if(!this.props.material) return;
    deletePart(this.props.material.id).then(() => {
      this.props.closeAction(true);
    });
  }

  render() {
    return (
      <Container fill>
        {mainStore.materialy_loading && <Loading />}
        <h1> Materiał: {this.state.nazwas}</h1>
        <Row>
          <Col span="4">
            Typ: 
          </Col>
          <Col span="8">
            <Select value={this.state.typ} options={[{key: 0, value:'Materiał eksploatacyjny'}, {key: 1, value:'Narzędzie'}]} accessor={(e) => e} onChange={(e) => this.setState({typ: e.target.value})}/>
          </Col>
        </Row>
        <Row>
          <Col span="4">
            Materiał:
          </Col>
          <Col span="8">
            <Select value={this.state.oryginal} options={mainStore.cosieks} accessor={(e) => ({key: e.id, value: e.nazwas}) } onChange={this.updateCosiek}/>
          </Col>
        </Row>
        <Row>
          <Col span="4">
            Ilość serwisowa
          </Col>
          <Col span="8">
            <Input value={this.state.ileplan} onChange={(e) => this.setState({ileplan: e.target.value}) }/>
          </Col>
        </Row>
        <Row>
          <Col span="6">
            { this.props.material ? <Button secondary block onClick={this.deletePart}><Icon icon="fal fa-trash" />Usuń</Button> : ' ' }
          </Col>
          <Col span="6">
            <Button green block onClick={this.savePart}><Icon icon="fal fa-save" /> Zapisz</Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(NewMaterial);
