const praca = {
  id: 'int',
  transaction: 'int',
  active: 'bool',
  deleted: 'bool',
  typ: 'int',
  nazwa: 'string',
  nazwas: 'string',
  parent: 'int',
  czas: 'float',
  worker: 'int',
  stawka: 'float',
  stawkas: 'int',
  instrukcja: 'string',
  repo: 'int',
  feedworker: 'int',
  feedstawkas: 'int',
  info: 'string',
  rodzaj: 'int',
  jm: 'int',
  norma: 'float',
  ile: 'float',
  nrrap: 'string',
  orgprac: 'int',
  status: 'int',
  priorytet: 'int',
  done: 'bool'
}

module.exports = praca