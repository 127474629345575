import { collateObject } from "util/collation";

import punktCollation from 'model/punkt.js'

export function transformPunktFromJSON(o, fieldFilter) {
  return collateObject(o, punktCollation, fieldFilter);      
}

export function sortPunkty(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}

export const trybyPrac = [
  {id: 1, nazwas: 'TY', nazwa: 'Co x tygodni'},
  {id: 4, nazwas: 'DT', nazwa: 'W wybrane dni tygodnia'},
  {id: 202, nazwas: 'J', nazwa: 'Jednorazowy'}
]