import stringToCode from "./stringToCode";

/*const list = [
  '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#000000'
]

export function getStructIconColor(string){
  return list[ Math.abs(stringToCode(string)) % list.length];
}*/

//typ-icon map
const map = {
  20: '#000000',
  3: '#000000',
  0: '#000000',
  1: '#000088',
  19: '#000088',
  101: '#000000',
  102: '#000088',
  103: '#000000',
  104: '#008800',
  105: '#000088',
}

export function getStructIconColor(struct){
  const r = map[struct.typ];
  if(r){
    return r;
  }
  return map[0];
}