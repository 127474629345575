import React from 'react'
import { view } from 'react-easy-state';

export default view((props) => {
  let icon = false;
  let element = false;
  if(props.onContentClick){
    element = <span className="clickable" onClick={props.onContentClick}>{props.children}</span>
  } else {
    element = <span>{props.children}</span>
  }
  if(props.onIconClick){
    if(props.color){
      icon = <i className={"clickable fa-fw "+props.icon} style={ { color: props.color } } onClick={props.onIconClick} />
    } else {
      icon = <i className={"clickable fa-fw "+props.icon} onClick={props.onIconClick} />
    }
  } else {
    if(props.onContentClick){
      if(props.color){
        icon = <i className={"clickable fa-fw "+props.icon} style={ { color: props.color } } onClick={props.onContentClick} />
      } else {
        icon = <i className={"clickable fa-fw "+props.icon} onClick={props.onContentClick} />
      }
    } else {
      if(props.color){
        icon = <i className={"fa-fw "+props.icon} style={ { color: props.color } } />
      } else {
        icon = <i className={"fa-fw "+props.icon} />
      }
    }
  }
  if(props.className){
    if(props.onClick){
      return (
        <span className={props.className} onClick={props.onClick} onContextMenu={props.onContextMenu}>
          {icon}{element}
        </span>
      ) 
    }
    return (
      <span className={props.className} onContextMenu={props.onContextMenu}>
        {icon}{element}
      </span>
    ) 
  }    
  if(props.onClick){
    return (
      <span onClick={props.onClick} onContextMenu={props.onContextMenu}>
        {icon}{element}
      </span>
    ) 
  }
  return (
    <span onContextMenu={props.onContextMenu}>
      {icon}{element}
    </span>
  ) 
})
