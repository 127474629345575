import { repeat } from "lodash";



const styles = `
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

@page {
  size: A4 portait;
}

body {
  font-size: 0.4cm;
  width: 210mm;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1cm;
  margin-bottom: 1cm;
}

table.totalTable {
  page-break-inside: auto;
}

h1, h2, h3, h4, h5 {
  page-break-after: avoid;
}

hr {
  page-break-before: avoid;
}

h2 {
  font-size: 150%;
  font-weight: bold;
}

th {
  font-weight: bold;
}

th, td {
  padding: 0.1cm;
  border: 1px solid;
}

th.border, td.border {
  border: 1px solid black;
}

tr.border {
  border: 1px solid black;
}

header {
  display: grid;
  width: 100%;
  grid-template-areas: 
    "topleft topcenter topright"
    "maintitle maintitle maintitle";
  grid-template-columns: 5cm auto 5cm;
}

.topleft {
  grid-area: topleft;
}
.topcenter {
  grid-area: topcenter;
  text-align: center;
}
.topright {
  grid-area: topright;
  text-align: right;
}

.maintitle {
  grid-area: maintitle;
  text-align: center;
}

.w3cm {
  width: 3cm;
}

.w2cm {
  width: 2cm;
}

.w1cm {
  width: 1cm;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.noBorder {
  border:none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}
`;

const exportStyles = `
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

@page {
  size: A4 portait;
}

body {
  font-size: 0.4cm;
  width: 210mm;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  font-weight: bold;
}

th, td {
  padding: 0.1cm;
}
`;

export {
    styles, 
    exportStyles
}

export function repeatThing(thing, times) {
  const result = [];
  for(let i = 0; i < times; i++){
    result.push(thing);
  }
  return result;
}