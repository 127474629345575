import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformWykonanieFromJSON } from './wykonanieModel';
import { applyParts } from '../part/getPart';
import { applyPrace } from '../praca/getPrace';
import moment from 'moment';
import { transformPozPlanFromJSON } from './pozPlanModel';
import { transformStructFromJSON } from '../struct/structModel';
import { transformPunktFromJSON } from '../punkt/punktModel';
import { transformPracaFromJSON } from '../praca/pracaModel';
import { transformPartFromJSON } from '../part/partModel';
import flattenTree from '../../util/flattenTree';

export function sortNazwas(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}

export function applyPP(json){
  const pozplans = json.pozplans;
  const pozplanMap = {};
  for(let i = 0; i < pozplans.length; i++){
    transformPozPlanFromJSON(pozplans[i], ['id', 'nazwas', 'dataplan', 'datawyk', 'raport']);
    pozplans[i].robols = [];
    pozplans[i].parts = [];
    pozplanMap[pozplans[i].id] = pozplans[i];
  }

  json.robols.map((e) => {
    pozplanMap[e.praca].robols.push(e);
  });

  json.parts.map((e) => {
    pozplanMap[e.parent].parts.push(e);
  });

  const ludziks = {};
  for(let i = 0; i < json.ludziks.length; i++){
    ludziks[json.ludziks[i].id] = json.ludziks[i];
  }

  mainStore.archiwum.data = {
    pozplans: pozplans,
    ludziks: ludziks
  };
}

export function getArchiwumPP(from, to, nazwas, cosiek, ludzik, struct){
  //console.log(struct);
  let arr = [];
  if(struct){
    arr = flattenTree(struct).map((s) => s.id);
  }
  return fetchJSON('/api/getArchiwumPP/', { 
    from: from,
    to: to,
    nazwa: nazwas,
    cosiek: cosiek,
    ludzik: ludzik,
    structs: arr
  }).then((json) => {

    applyPP(json);

    mainStore.wykonanie.loading = false;
  })
  .catch(error => {
    mainStore.wykonanie.loading = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}