import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Input } from 'components/Input';
import { Table } from 'components/Table';
import { RowSet } from 'components/Grid';
import { pracownicyTable, pracownicyData } from 'model/tables/pracownicy';
import mainStore from '../../../stores/store';
import { getLudzie } from '../../../model/ludzik/getLudzie';
import { view } from 'react-easy-state';
import { Icon } from 'components/Icon';
import { Button } from 'components/Button';
import { saveLudzik } from '../../../model/ludzik/saveLudzik';
import Text from './../../../components/Text/index';
import ChangePassword from 'modals/ChangePassword/index';
import { addModal } from 'stores/modal';

class Pracownicy extends Component {

  constructor(props){
    super(props);

    mainStore.bigLoading = true;
    getLudzie().then(() => {
      mainStore.bigLoading = false;
    })
  }

  save = () => {
    const newObject = mainStore.ludzie.selected;
    if(!newObject) return;

    mainStore.bigLoading = true;
    saveLudzik(newObject).then(() => {
      mainStore.bigLoading = false;
    });
  }

  flipDeleted = () => {
    if(this.state.showDeleted){
      this.setState({showDeleted: false}, () => {
        getLudzie(false, true).then(() => {
          mainStore.bigLoading = false;
        })
      });
    } else {
      this.setState({showDeleted: true}, () => {
        getLudzie(true, true).then(() => {
          mainStore.bigLoading = false;
        })
      });
    }
  }

  render() {
    console.log(mainStore.ludzie.selected)
    return (
      <Row>
        <Col span="6">
          <Container panel vfull vscrolled>
            <h1>Pracownicy 
              {this.state.showDeleted ? 
                <Button onClick={this.flipDeleted}><Icon icon="fa fa-trash" /><Icon icon="fa fa-eye" /></Button>
              :
                <Button onClick={this.flipDeleted}><Icon icon="fa fa-trash" /><Icon icon="fa fa-eye-slash" /></Button>
              }
            </h1>
            <Row>
              <Col span="12">
                <Table 
                  cols={pracownicyTable} 
                  data={mainStore.ludzie.sortedList} 
                  onClick={(row) => mainStore.ludzie.selected = {...row}}
                  selectedID={mainStore.ludzie.selected ? mainStore.ludzie.selected.id : 0} 
                  newRowClick={() => mainStore.ludzie.selected = {id: -1, imie: '', nazwisko: '', podpis: '', mails: '', telkoms: '', maild:''}}
                  addmore
                />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col span="6">
          <Container panel vfull vscrolled>
            <Row>
              <Col span="12">
                { mainStore.ludzie.selected && (
                  <div>
                    <h3>Dane pracownika: {mainStore.ludzie.selected.id === -1 ? <Text green>Nowy</Text> : <Text green>{mainStore.ludzie.selected.id}</Text>}</h3>
                    <RowSet caption="Imię:"><Input value={mainStore.ludzie.selected.imie} onChange={(e) => {
                      mainStore.ludzie.selected.imie = e.target.value;
                      mainStore.ludzie.selected.podpis = mainStore.ludzie.selected.nazwisko + " " + e.target.value;
                    } }/></RowSet>
                    <RowSet caption="Nazwisko:"><Input value={mainStore.ludzie.selected.nazwisko} onChange={(e) => {
                      mainStore.ludzie.selected.nazwisko = e.target.value
                      mainStore.ludzie.selected.podpis = e.target.value + " " + mainStore.ludzie.selected.imie;
                    } } /></RowSet>
                    <RowSet caption="Podpis:"><Input value={mainStore.ludzie.selected.podpis} onChange={(e) => mainStore.ludzie.selected.podpis = e.target.value} /></RowSet>
                    <RowSet caption="E-Mail:"><Input value={mainStore.ludzie.selected.mails} onChange={(e) => mainStore.ludzie.selected.mails = e.target.value} /></RowSet>
                    <RowSet caption="telefon:"><Input value={mainStore.ludzie.selected.telkoms} onChange={(e) => mainStore.ludzie.selected.telkoms = e.target.value} /></RowSet>
                    <RowSet caption="Widoczny w wykonaniu:">
                        {mainStore.ludzie.selected.typ == 1 ?
                          <Button green onClick={(e) => mainStore.ludzie.selected.typ = 0}><Icon icon="fa fa-eye"></Icon></Button>
                        :
                          <Button red onClick={(e) => mainStore.ludzie.selected.typ = 1}><Icon icon="fa fa-eye-slash"></Icon></Button>
                      }
                    </RowSet>
                    <RowSet caption="Projekty:">
                      {mainStore.projects.map((p) => {
                        console.log(mainStore.ludzie.selected);
                        if(mainStore.ludzie.selected.maild.includes('|'+p.id+'|')){
                          return <Button green block onClick={() => mainStore.ludzie.selected.maild = mainStore.ludzie.selected.maild.split(',').filter((e) => '|'+p.id+'|' != e).join(',')}>{p.nazwas} <Icon icon="fa fa-eye"/></Button>;
                        } else {
                          return <Button block onClick={() => mainStore.ludzie.selected.maild = mainStore.ludzie.selected.maild.split(',').concat('|'+p.id+'|').join(',')}>{p.nazwas} <Icon icon="fa fa-eye-cross"/></Button>;
                        }
                      })}
                    </RowSet>
                    <RowSet caption="Stan:">
                        {mainStore.ludzie.selected.deleted == 1 ?
                          <Button red onClick={(e) => mainStore.ludzie.selected.deleted = 0}><Icon icon="fa fa-trash"></Icon></Button>
                        :
                          <Button green onClick={(e) => mainStore.ludzie.selected.deleted = 1}><Icon icon="fa fa-check"></Icon></Button>
                      }
                    </RowSet>
                    <Button big block green onClick={this.save}><Icon icon="fa fa-save"/> Zapisz</Button>
                    <Button block big tetriary onClick={() => addModal(ChangePassword, {targetLudzikID: mainStore.ludzie.selected.id})}><Icon icon="fa fa-key" /> Zmiana hasła</Button>
                  </div>
                ) }
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    );
  }
}

export default view(Pracownicy);
