import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import './DateInputRange.css';

import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import moment from 'moment';
import { view } from 'react-easy-state';

class DateInputRange extends Component {
  showFromMonth() {
    const { from, to } = this.props.value;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange = (from) => {
    // Change the from date and focus the "to" input field
    if(this.props.onChange){
      this.props.onChange({
        from: from, 
        to: this.props.value.to
      });
    }
  }

  handleToChange = (to) => {
    if(this.props.onChange){
      this.props.onChange({
        from: this.props.value.from, 
        to: to
      });
    }
  }

  render() {
    const from = this.props.value.from;
    const to = this.props.value.to;
    const modifiers = { start: from, end: to };
    return (
      <div className="InputFromTo">
        <DayPickerInput
          value={from}
          placeholder="From"
          format="YYYY-MM-DD"
          formatDate={formatDate}
          parseDate={parseDate}
          hideOnDayClick={false}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            initialMonth: new Date(),
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to}
            placeholder="To"
            format="YYYY-MM-DD"
            formatDate={formatDate}
            parseDate={parseDate}
            hideOnDayClick={false}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              initialMonth: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
        </span>
      </div>
    )
  }
}

export default view(DateInputRange);