import fetchJSON from 'util/fetchJSON';
import {
  mainStore
} from 'stores/store';

export function updateCosiek(oldCosiek, newCosiek) {
  mainStore.materialy_loading = true;
  mainStore.cosieks = [];
  return fetchJSON('/api/updateCosiek/', {
      oldCosiek: oldCosiek,
      newCosiek: newCosiek
    }, 'POST')
    .then((json) => {
      mainStore.materialy_loading = false;
    })
    .catch(error => {
      mainStore.materialy_loading = false;
      mainStore.bigError = {
        message: error
      };
      console.log(error);
    });
}