import moment from 'moment'

export const wykonanieTable = [
  { caption: 'ID', get: (row) => row.id, align: 'left', mono: true },
  { caption: 'NazwaS', get: (row) => row.nazwas, align: 'left', maximize: true },
  { caption: 'Raport', get: (row) => row.raport, align: 'left' },
  { caption: '!', get: (row) => row.typ == 1 ? '!' : '', getClass: (row, index) => row.typ == 1 ? 'bg-red' : '', align: 'center', mono: true },
  { caption: 'DataWyk', get: (row) => moment.unix(row.datawyk).format('YYYY-MM-DD HH:mm'), align: 'right', mono: true },
  { caption: 'DataPlan', get: (row) => moment.unix(row.dataplan).format('YYYY-MM-DD HH:mm'), align: 'right', mono: true },
];
