import { collateObject } from "util/collation";

import cosiekCollation from 'model/cosiek.js'

export function transformCosiekFromJSON(o, fieldFilter) {
  return collateObject(o, cosiekCollation, fieldFilter);
}

export function sortCosieks(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}