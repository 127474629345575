import Container from 'components/Container';
import { Col, Row } from 'components/Grid';
import TreeView from 'components/TreeView';
import React, { Component } from 'react';
import { getStructIcon } from 'util/getStructIcon';
import Icon from 'components/Icon';
import Table from 'components/Table/Table';
import moment from 'moment';

import { view } from 'react-easy-state';
import { mainStore } from 'stores/store';
import { getStructIconColor } from 'util/getStructIconColor';
import { getWykonanieList } from 'model/wykonanie/getWykonanieList';
import { sortStructs } from 'model/struct/structModel';
import RowSet from 'components/Grid/RowSet';
import Label from 'components/Label';
import Button from 'components/Button';

import Loading from 'components/Loading';
import { Input } from 'components/Input/index';
import { newNodeSum } from './newNodeSum';
import DateInput from 'components/Input/DateInput';
import findInTree from '../../util/findInTree';
import TextArea from './../../components/TextArea/index';
import flattenTree from '../../util/flattenTree';
import { EDEADLK } from 'constants';
import { wykonajPunkty } from '../../model/wykonanie/wykonajPunkty';
import WykonanieGodzinne from './../../modals/WykonanieGodzinne/index';
import { addModal } from 'stores/modal';
import { getWykRecord } from '../../model/wykonanie/wykonanieModel';
import { wykonajWykRecords } from '../../model/wykonanie/wykonajWykRecords';
import { SmartInput } from '../../components/Input';
import { getCosieks } from '../../model/cosiek/getCosieks';
import { getPT } from '../../modals/WykonajScreen/WykonajScreen';
import idProvider from './../../util/idProvider';
import SzybkieWykonanieGodzinne from '../../modals/WykonanieGodzinne/SzybkieWykonanieGodzinne';
import { saveOSMToLS } from '../../util/localStorageSetting';

const wykonanieMaterialyTable = [
  { caption: 'Materiał', get: (row) => row.nazwas, align: 'left', maximize: true },
  { caption: 'Zużycie', get: (row) => row.ileplan.toFixed(2), align: 'right', mono: true },
];

let nodeSum = newNodeSum();

class Wykonanie extends Component {

  constructor(props){
    super(props);

    this.state = {
      loadChildren: true
    }

    mainStore.bigLoading = true;
    getCosieks().then(() => {
      this.state.newPartID = mainStore.cosieks[0].id;
      mainStore.bigLoading = false;
    }).catch((err) => {
      mainStore.bigLoading = false;
    })
  }

  nodeProcessor(sourceNode) {
    return {
      id: sourceNode.id,
      caption: sourceNode.nazwas,
      icon: getStructIcon(sourceNode),
      iconColor: getStructIconColor(sourceNode),
      children: sourceNode.children.sort(sortStructs)
    }
  }

  selectedNodeProcessor(sourceNode) {
    return {
      id: sourceNode.id,
      caption: sourceNode.nazwas,
      icon: function (sourceNode){
        if(sourceNode.type === 'struct') return getStructIcon(sourceNode);
        if(sourceNode.type === 'punkt') return 'fal fa-tools';
        if(sourceNode.type === 'praca') return 'fal fa-hand-receiving';
        if(sourceNode.type === 'part') return 'fal fa-box-alt';
        return 'fa fa-question'
      }(sourceNode),
      iconColor: getStructIconColor(sourceNode),
      children: sourceNode.children.sort(sortStructs)
    }
  }

  treeViewNodeSelect = (node) => {
    mainStore.selectedStruct = node;
  }

  treeDoWykonaniaNodeSelect = (node) => {
    mainStore.wykonanie.selectedStruct = node;
  }

  nodeExpandClick = (nodeID) => {
    if(mainStore.openStructMap[nodeID]){
      mainStore.openStructMap[nodeID] = false;
    } else {
      mainStore.openStructMap[nodeID] = true;
    }
    saveOSMToLS();
  }

  updateTotals = () => {
    return;
    nodeSum = newNodeSum();
    for(let i = 0; i < mainStore.wykonanie.treeDoWykonania.length; i++){
      this.sumNodes(mainStore.wykonanie.treeDoWykonania[i]);
    }        
    nodeSum.materialyList = [];
    const idKeys = Object.keys(nodeSum.materialNames);
    for(let i = 0; i < idKeys.length; i++){
      const key = idKeys[i];
      nodeSum.materialyList.push({
        id: key,
        nazwas: nodeSum.materialNames[key],
        ileplan: nodeSum.materials[key]
      })
    }

    nodeSum.materialyList.sort(sortStructs);
    mainStore.wykonanie.totals = nodeSum;
  }

  wykonanieNodeExpandClick = (nodeID) => {
    if(mainStore.wykonanie.openStructMap[nodeID]){
      mainStore.wykonanie.openStructMap[nodeID] = false;
    } else {
      mainStore.wykonanie.openStructMap[nodeID] = true;
    }
  }

  sumNodes(tree){
    if(tree.type==='struct'){
      nodeSum.struktur++;
    }
    if(tree.type==='punkt'){
      nodeSum.punktow++;
    }
    if(tree.type==='praca'){
      nodeSum.rh += tree.czas;
      nodeSum.prac++;
    }
    if(tree.type==='part'){
      if(nodeSum.materials[tree.oryginal]){
        nodeSum.materials[tree.oryginal] += tree.ileplan;
      } else {
        nodeSum.materials[tree.oryginal] = tree.ileplan;
        nodeSum.materialNames[tree.oryginal] = tree.nazwas;
      }
    }

    for(let i = 0; i < tree.children.length; i++){
      this.sumNodes(tree.children[i]);
    }
  }

  removeNode(tree, node) {
    for(let i = 0; i < tree.children.length; i++){
      if(tree.children[i].id===node.id){
        tree.children.splice(i, 1);
        i--;
      } else {
        this.removeNode(tree.children[i], node);
      }
    }
  }

  removeAll = () => {
    mainStore.wykonanie.treeDoWykonania = [];
    mainStore.wykonanie.openStructMap = {...mainStore.wykonanie.openStructMap};
    this.updateTotals();
  }

  removeCurrentSelection = () => {
    const snode = mainStore.wykonanie.selectedStruct;
    for(let i = 0; i < mainStore.wykonanie.treeDoWykonania.length; i++){
      const tn = mainStore.wykonanie.treeDoWykonania[i];
      if(tn===undefined) {
        console.log('tn is undefined', i)
        continue;
      }
      if(tn.id === snode.id){
        mainStore.wykonanie.treeDoWykonania.splice(i, 1);
        i--;
      } else {
        this.removeNode(tn, snode);
      }
    }
    //should force a rerender.
    mainStore.wykonanie.openStructMap = {...mainStore.wykonanie.openStructMap};
    this.updateTotals();
  }

  moveToActual = () => {
    const node = mainStore.selectedStruct;
    if(!node) {
      return;
    }

    const nodeIDs = [];
    const nodes = [];

    nodes.push(node);
    while(nodes.length > 0){
      const n = nodes.pop();
      nodeIDs.push(n.id);
      for(let i = 0; i < n.children.length; i++){
        nodes.push(n.children[i]);
      }
    }

    return getWykonanieList(nodeIDs).then(() => {
      this.updateTotals();
    });
  }

  moveToActualSolo = () => {
    const node = mainStore.selectedStruct;
    if(!node) {
      return;
    }

    const nodeIDs = [];
    nodeIDs.push(node.id);

    return getWykonanieList(nodeIDs).then(() => {
      this.updateTotals();
    });
  }  

  addPart = () => {
    const mySelectedNode = mainStore.wykonanie.selectedStruct;
    if(!mySelectedNode) return;
    const cosiek = mainStore.cosieks.find((e) => e.id == this.state.newPartID);
    if(!cosiek) return;
    mySelectedNode.children.push({
      children: [],
      id: -idProvider.getNext(),
      ilefakt: 0,
      ileplan: 0,
      nazwas: cosiek.nazwas,
      oryginal: cosiek.id,
      parent: mySelectedNode.id,
      type: "part",
      uzyto: 0
    })
    mainStore.wykonanie.openStructMap = {...mainStore.wykonanie.openStructMap};

  }

  addPraca = () => {
    const mySelectedNode = mainStore.wykonanie.selectedStruct;
    if(!mySelectedNode) return;
    //console.log(mySelectedNode, mainStore.currentProject.repoPrac);
    const praca = mainStore.currentProject.repoPrac.idMap[this.state.newPracaID];
    if(!praca) return;
    //console.log(praca);
    mainStore.bigLoading = true;
    getPT(undefined, [praca.id]).then((result) => {
      //console.log('ro', result.prace[0])
      const newPraca = result.prace[0];
      mySelectedNode.children.push({
        children: newPraca.children.map((c) => ({
          id: c.id,
          ileplan: c.ileplan,
          jm: c.jm,
          nazwas: c.nazwas,
          children: [],
          type: "part"
        })),
        id: -idProvider.getNext(),
        czas: newPraca.czas,
        extra: newPraca.czas,
        nazwas: praca.nazwas,
        parent: mySelectedNode.id,
        repo: praca.id,
        type: "praca"
      })
      mainStore.bigLoading = false;
      mainStore.wykonanie.openStructMap = {...mainStore.wykonanie.openStructMap};
    }).catch((err) => {
      //console.log('wut')
      mainStore.bigLoading = false;
    });
  }


  wykonaj = () => {
    //1. get the raport number + notatka
    const outObject = {
      punkty: [],
      raport: mainStore.wykonanie.raport,
      info: mainStore.wykonanie.info
    }
    //2. get all the punkty [id, struct, datawyk], for each get all prace [id, dodatkowy czas]

    const wyks = [];
    
    const flatTree = flattenTree(mainStore.wykonanie.treeDoWykonania);
    const punkty = flatTree.filter((el) => el.type==='punkt').map((el) => {
      el.children.map((cel) => {
        wyks.push(getWykRecord(
          -idProvider.getNext(), 
          el.parent, 
          el.id, 
          cel.id, 
          cel.repo, 
          mainStore.wykonanie.raport,
          mainStore.wykonanie.info,
          moment.unix(el.dataplan), 
          cel.extra ? parseFloat(cel.extra) : 0, 
          cel.planning ? cel.planning.people : [], 
          cel.planning ? cel.planning.start : 0, 
          cel.planning ? cel.planning.end : 0, 
          cel.children.map((mel) => ({
            id: mel.id,
            cosiek: mel.cosiek,
            ile: mel.ileplan
          })),
          false
        ));
      })
    });

    //3. send it over to the server.

    return wykonajWykRecords(wyks).then(() => {
      this.updateTotals();
    });
    //S1. Generate all execution records and modify datanast i dataost in punkty.
  }

  CosiekAccessor = (c) => ({key:c.id, value:c.nazwas});

  render() {
    const selectedNode = mainStore.selectedStruct ? mainStore.selectedStruct : {};
    const mySelectedNode = mainStore.wykonanie.selectedStruct ? mainStore.wykonanie.selectedStruct : {};
    return (
      <Container>
        {mainStore.wykonanie.loading && <Loading /> }
        <Row>
          <Col span="3">
            <Container panel vfull vscrolled>
              {findInTree(mainStore.wykonanie.treeDoWykonania, selectedNode.id) !== undefined ? (
                <Row>
                  <Col span="6"><Button big block><Icon icon="fa fa-cog" /> <Icon icon="fa fa-share" /></Button></Col>
                  <Col span="6"><Button big block><Icon icon="fa fa-cogs" /> <Icon icon="fa fa-share-all" /></Button></Col>
                </Row>
              ) : (
                <Row>
                  <Col span="6"><Button big block green onClick={this.moveToActualSolo}><Icon icon="fa fa-cog" /> <Icon icon="fa fa-share" /></Button></Col>
                  <Col span="6"><Button big block green onClick={this.moveToActual}><Icon icon="fa fa-cogs" /> <Icon icon="fa fa-share-all" /></Button></Col>
                </Row>
              )}
              {mainStore.currentProject && 
                  <TreeView data={mainStore.currentProject.structs} nodeClick={this.treeViewNodeSelect}
                        selected={selectedNode.id} nodeProcessor={this.nodeProcessor}
                        nodeExpandClick={this.nodeExpandClick} openNodeMap={mainStore.openStructMap}/>
              }
            </Container>
          </Col>
          <Col span="3">
            <Container panel vfull vscrolled>
              <Row>
                {/*<Col span="6"><Button big block green onClick={this.moveToActual}><Icon icon="fa fa-plus" /></Button></Col>*/}
                <Col span="6"><Button big block secondary onClick={this.removeAll}><Icon icon="fa fa-folders" /><Icon icon="fas fa-dumpster" /></Button></Col>
                <Col span="6"><Button big block secondary onClick={this.removeCurrentSelection}><Icon icon="fa fa-folder" /><Icon icon="fas fa-trash" /></Button></Col>
              </Row>
              <TreeView data={mainStore.wykonanie.treeDoWykonania} nodeClick={this.treeDoWykonaniaNodeSelect}
                    selected={mySelectedNode.id} nodeProcessor={this.selectedNodeProcessor}
                    nodeExpandClick={this.wykonanieNodeExpandClick} openNodeMap={mainStore.wykonanie.openStructMap} defaultExpand={true}/>
            </Container>
          </Col>
          <Col span="3">
            <Container panel vfull vscrolled>
              {mySelectedNode && (mySelectedNode.type==='struct') && 
                <div>
                  <RowSet caption="Struktura:"><Label>{mySelectedNode.nazwas}</Label></RowSet>
                </div>
              }
              {mySelectedNode && (mySelectedNode.type==='part') && 
                <div>
                  <RowSet caption="Materiał:"><Label>{mySelectedNode.nazwas}</Label></RowSet>
                  <RowSet caption="Ilość:"><Input value={mySelectedNode.ileplan} onChange={(e) => {
                      const v = parseFloat(e.target.value);
                    if(!isNaN(v)) {
                      mySelectedNode.ileplan = v;
                      this.updateTotals();
                    }
                  } }/></RowSet> 
                </div>
              }
              {mySelectedNode && (mySelectedNode.type==='punkt') && 
                <div>
                  <RowSet caption="Praca:"><Label>{mySelectedNode.nazwas}</Label></RowSet>
                  <h3>Data wykonania</h3>
                  <RowSet caption="Ostatnia:"><Label>{moment.unix(mySelectedNode.dataost).format('YYYY-MM-DD')}</Label></RowSet>
                  <RowSet caption="Wykonywana:">
                    <DateInput 
                      value={moment.unix(mySelectedNode.dataplan).toDate()} 
                      onChange={(newDay) => mySelectedNode.dataplan = moment(newDay).unix()} />
                  </RowSet>
                  <RowSet caption={(<Button primary block inputHeight onClick={this.addPraca}><Icon icon="fa fa-fw fa-plus"/> <Icon icon="fa fa-fw fa-hand-receiving"/></Button>)}>
                    <SmartInput value={this.state.newPracaID} onChange={(newValue) => this.setState({newPracaID: newValue})} options={mainStore.currentProject.repoPrac.sorted} accessor={this.CosiekAccessor} resultCount={3}/>
                  </RowSet>
                </div>
              }
              {mySelectedNode && (mySelectedNode.type==='praca') &&
                <div>
                  <RowSet caption="Czynność:"><Label>{mySelectedNode.nazwas}</Label></RowSet>
                  <RowSet caption="Czas [min]:"><Input value={mySelectedNode.extra} onChange={(e) => {
                    const v = parseFloat(e.target.value);
                    if(!isNaN(v)) {
                      mySelectedNode.extra = v;
                      this.updateTotals();
                    }
                  } }/></RowSet>
                  <RowSet caption={(<Button primary block inputHeight onClick={this.addPart}><Icon icon="fa fa-fw fa-plus"/> <Icon icon="fa fa-fw fa-box-alt"/></Button>)}>
                    <SmartInput value={this.state.newPartID} onChange={(newValue) => this.setState({newPartID: newValue})} options={mainStore.cosieks} accessor={this.CosiekAccessor} resultCount={3}/>
                  </RowSet>
                </div>
              }
            </Container>
          </Col>
          <Col span="3">
            <Container panel vfull vscrolled>
              <RowSet caption="Raport:"><Input value={mainStore.wykonanie.raport} onChange={(e) => mainStore.wykonanie.raport = e.target.value}/></RowSet> 
              <RowSet caption="Notatka:"><TextArea value={mainStore.wykonanie.info} onChange={(e) => mainStore.wykonanie.info = e.target.value}/></RowSet> 
{/*}              <RowSet caption="Sturktur:"><Label>{mainStore.wykonanie.totals.struktur}</Label></RowSet> 
              <RowSet caption="Prac:"><Label>{mainStore.wykonanie.totals.punktow}</Label></RowSet> 
              <RowSet caption="Czynności:"><Label>{mainStore.wykonanie.totals.prac}</Label></RowSet> 
              <RowSet caption="Całkowita pracochłonność:"><Label>{mainStore.wykonanie.totals.RG} RG</Label></RowSet> 
              <Row>
              <Col span="12">
                <h3>Sumaryczne zużycie materiałów:</h3>
                <Table 
                  cols={wykonanieMaterialyTable} 
                  data={mainStore.wykonanie.totals.materialyList} 
                  limit={150}
                  loadMore  
                />
                </Col>
            </Row>*/}
              <Row>
                <Col span="12"><Button big block primary onClick={() => addModal(WykonanieGodzinne)}><Icon icon="fa fa-table"/><Icon icon="fa fa-users"/> Rozplanuj</Button></Col>
              </Row>
              <Row>
                <Col span="12"><Button big block primary onClick={() => addModal(SzybkieWykonanieGodzinne)}><Icon icon="fa fa-table"/><Icon icon="fa fa-users"/> Rozplanuj Szybko</Button></Col>
              </Row>
              <Row>              
                <Col span="12"><Button big block green onClick={this.wykonaj}><Icon icon="fa fa-play"/><Icon icon="fa fa-cog"/> Wykonaj</Button></Col>
              </Row>              
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default view(Wykonanie);
