import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformPunktFromJSON } from './../punkt/punktModel';
import { transformPracaFromJSON } from './../praca/pracaModel';
import { transformPartFromJSON } from './../part/partModel';

export function sortNazwas(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}

export function applyStructChildren(json){
  const punktyMap = {};
  const praceMap = {};

  const structChildren = [];

  const punkty = json.punkty;
  for(let i = 0; i < punkty.length; i++){
    transformPunktFromJSON(punkty[i]);
    punkty[i].children = [];

    punktyMap[punkty[i].id] = punkty[i];
    structChildren.push(punkty[i]);
  }  

  const prace = json.prace;
  for(let i = 0; i < prace.length; i++){
    transformPracaFromJSON(prace[i]);
    prace[i].children = [];
    
    praceMap[prace[i].id] = prace[i];
    if(punktyMap[prace[i].parent]){
      punktyMap[prace[i].parent].children.push(prace[i]);
    }
  }  

  const parts = json.parts;
  for(let i = 0; i < parts.length; i++){
    transformPartFromJSON(parts[i]);
    
    if(praceMap[parts[i].parent]){
      praceMap[parts[i].parent].children.push(parts[i]);
    }
  }    

  structChildren.sort(sortNazwas);
  for(let i = 0; i < structChildren.length; i++){
    structChildren[i].children.sort(sortNazwas);
    for(let j = 0; j < structChildren[i].children.length; j++){
      structChildren[i].children[j].children.sort(sortNazwas);
    }
  }

  //console.log(mainStore.prace);
  mainStore.prace.structChildren = structChildren;

  if(structChildren.length > 0) {
    mainStore.selectedPunkt = structChildren[0];
  } else {
    mainStore.selectedPunkt = undefined;
  }

  if(mainStore.selectedPunkt && mainStore.selectedPunkt.children.length > 0) {
    mainStore.selectedPraca = mainStore.selectedPunkt.children[0];
  } else {
    mainStore.selectedPraca = undefined;
  }

  if(mainStore.selectedPraca && mainStore.selectedPraca.children.length > 0) {
    mainStore.selectedPart = mainStore.selectedPraca.children[0];
  } else {
    mainStore.selectedPart = undefined;
  }
}

export function getStructChildren(parentStruct){
  mainStore.prace.loading = true;
  //mainStore.prace.structChildren = [];
  return fetchJSON('/api/getStructChildren/'+parentStruct)
  .then((json) => {
    applyStructChildren(json);

    mainStore.prace.loading = false;
  })
  .catch(error => {
    mainStore.prace.loading = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}