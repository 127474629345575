import React from 'react';
import './Col.css'
import { view } from 'react-easy-state';

export default view((props) => (
  <div className={
    "col col-"+props.span+" "+ 
    (props.sticky ? 'col-sticky ' : '')+
    (props.vscrolled ? 'col-vscrolled ' : '')+
    (props.vfull ? 'col-vfull ' : '')+
    (props.relative ? 'relative ' : '')+
    (props.className ? props.className : '')
  }>{props.children}</div>
))