export const stanowiskaTable = [
  { caption: 'Nazwa', get: (row) => row.name, align: 'left', maximize: true}
]

export const stanowiskaData = [
  { id: 1, name: 'kierowca wózka', ile: 4 },
  { id: 2, name: 'mistrz serwisu', ile: 2 },
  { id: 3, name: 'mechanik konserwator urządzeń hydraulicznych', ile: 2 },
  { id: 4, name: 'monter serwisu wewnętrznego', ile: 2 },
  { id: 5, name: 'specjalista ds gospodarki sparowniczej', ile: 2 },
  { id: 6, name: 'technik laborant', ile: 2 },
  { id: 7, name: 'technik serwisu wewnętrznego', ile: 2 },
]

