import fetchJSON, { creds } from 'util/fetchJSON';
import { mainStore } from 'stores/store';

export function changePassword(oldPassword, newPassword, tuid){
  mainStore.bigLoading = true;
  mainStore.changePasswordMessage = '';
  let endpoint = '/api/changePassword';
  const payload = {old: oldPassword, new: newPassword};
  if(tuid){
    endpoint = '/api/adminChangePassword';
    payload.targetLudzikID = tuid;
  }
  return fetchJSON(endpoint, payload)
    .then((json) => {
      if(json.success == true){
        mainStore.changePasswordSuccess = true;
      } else {
        mainStore.changePasswordSuccess = false;
        mainStore.changePasswordMessage = json.message || json.content;
      }
      mainStore.bigLoading = false;
      return json;
    })
    .catch(error => {
      mainStore.changePasswordSuccess = false;
      mainStore.bigLoading = false;
      mainStore.changePasswordMessage = error.content;
      console.log(error);
      return error;
    });
}
