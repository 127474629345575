import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';

export function wykonajWykRecords(wyks){
  mainStore.wykonanie.loading = true;
  return fetchJSON('/api/wykonaj/', {wyks: wyks}).then((json) => {
    //wykonanie chyba nie ma efektów?
    mainStore.wykonanie.loading = false;
  })
  .catch(error => {
    mainStore.wykonanie.loading = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}