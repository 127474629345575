import React from 'react';
import { Context } from './Form';
import Input from 'components/Input';
import getFromObj from 'util/getFromObj';
import setInObj from 'util/setInObj';
import { view } from 'react-easy-state';

const FormInput = (props) => (
  <Context.Consumer>
    {(context) => {
      let curValue = getFromObj(context.value, props.field);
      if(curValue === undefined){
        if(props.default !== undefined) {
          setInObj(context.value, props.field, props.default);
          curValue = props.default;
        } else {
          curValue = '';
        }
      }
      return (
        <Input {...props} value={curValue} onChange={ (event) => { 
          context.updateValue(props.field, event.target.value);
          if(props.onChange) props.onChange(event.target.value);
        } } />
      )
    } }
  </Context.Consumer>
);

export default view(FormInput)