import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Input } from 'components/Input';
import Table from 'components/Table/index';
import { cennikiTable, cennikiData, cennikiPozycjeTable } from 'model/tables/cenniki';
import { TextArea } from 'components/TextArea';

export default class Cenniki extends Component {

  state = {
    currentRow: undefined
  }

  render() {
    const cennik = this.state.currentRow;
    const pozCennika = this.state.currentPozycja;
    return (
      <Container fill>
        <h1>Cenniki</h1>
        <Row>
          <Col span="8">
            <Table cols={cennikiTable} data={cennikiData} onClick={(row) => this.setState({currentRow: row})} selected={cennik} edit />
          </Col>
        { cennik && (
          <Col span="4">
            Nazwa: <Input value={cennik.name} /> 
            Status: {cennik.status} <br />
            Utworzono: {cennik.created} <br />
            Obowiązuje od: {cennik.effectiveFrom} <br />
            Uwagi: 
            <TextArea value={cennik.uwagi} />
            Zatwierdził: {cennik.zatwierdzil} <br />
            Spis cenników dla: {cennik.spisDla} 
          </Col>
        ) }
        </Row>
        <hr />
        <Row>
        { cennik && (
          <Col span="8">
            <Table cols={cennikiPozycjeTable} data={cennik.pozycje} onClick={(row) => this.setState({currentPozycja: row})} selected={pozCennika} edit />
          </Col>
        ) }
        { pozCennika && (
          <Col span="4">
            Towar: <Input value={pozCennika.towar} /> 
            Nazwa: <Input value={pozCennika.name} /> 
            Cena sprzedaży: <Input value={pozCennika.sellPrice} /> 
            Marża %: <Input value={100 * (pozCennika.sellPrice - pozCennika.buyPrice) / pozCennika.buyPrice} /> 
            Marża: <Input value={pozCennika.sellPrice - pozCennika.buyPrice} /> 
          </Col>
        ) }
        </Row>
      </Container>
    );
  }
}

