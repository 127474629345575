import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Input } from 'components/Input';
import { Table } from 'components/Table';
import { TextArea } from 'components/TextArea';
import { firmyTable, firmyData } from 'model/tables/firmy';
import { RowSet } from 'components/Grid'; 

class Kontrahenci extends Component {

  state = {
    currentRow: {
      nrKont: [],
      adres: {},
      adresKorespondencyjny: {}
    }
  }

  render() {
    const crow = this.state.currentRow;
    return (
      <Container fill>
        <h1>Kontrahenci</h1>
        <Row>
          <Col span="4">
            <Table cols={firmyTable} data={firmyData} onClick={(row) => this.setState({currentRow: row})} edit />
          </Col>
        { this.state.currentRow && (
          <Col span="8">
            <RowSet caption="Nazwa:"><Input value={crow.companyName} /></RowSet>
            <Row>
              <Col md="6" span="12">
                <RowSet caption="Imię:"><Input value={crow.name} /></RowSet>
              </Col>
              <Col md="6" span="12">
                <RowSet caption="Nazwisko:"><Input value={crow.surname} /></RowSet>
              </Col>
            </Row>
            <Row>
              <Col span="4">
                <RowSet caption="NIP:"><Input value={crow.nip} /></RowSet>
              </Col>
              <Col span="4">
                <RowSet caption="Regon:"><Input value={crow.regon} /></RowSet>
              </Col>
              <Col span="4">
                <RowSet caption="dniNaFakturze:"><Input value={crow.dniNaFakturze} /></RowSet>
              </Col>
            </Row>
            <Row>
              <Col span="4">
                <RowSet caption="www:"><Input value={crow.www} /></RowSet>
              </Col>
              <Col span="4">
                <RowSet caption="E-Mail:"><Input value={crow.email} /></RowSet>
              </Col>
              <Col span="4">
                <RowSet caption="telefon:"><Input value={crow.telefon} /></RowSet>
              </Col>
            </Row>
            <Row>
              <Col span="4">
                <RowSet caption="Nr. Akcyzowy:"><Input value={crow.nrAkcyzowy} /></RowSet>
              </Col>
              <Col span="4">
                <RowSet caption="Rodzaj kontrahenta:"><Input value={crow.rodzajKontrahenta} /></RowSet>
              </Col>
              <Col span="4">
                <RowSet caption="Uwagi:"><TextArea value={crow.uwagi} /></RowSet>
              </Col>
            </Row>
            <RowSet caption="nrKont:"><TextArea value={crow.nrKont.join('\n')} /></RowSet>
            <Row>
              <Col span="6">
                <h3>Adres</h3>
                <RowSet caption="Miasto:"><Input value={crow.adres.miasto} /></RowSet>
                <RowSet caption="Ulica:"><Input value={crow.adres.ulica} /></RowSet>
                <RowSet caption="nr:"><Input value={crow.adres.nr} /></RowSet>
                <RowSet caption="Kod pocztowy:"><Input value={crow.adres.kod} /></RowSet>
                <RowSet caption="Poczta:"><Input value={crow.adres.poczta} /></RowSet>
              </Col>
              <Col span="6">
                <h3>Adres Korespondencyjny</h3>
                <RowSet caption="Miasto:"><Input value={crow.adresKorespondencyjny.miasto} /></RowSet>
                <RowSet caption="Ulica:"><Input value={crow.adresKorespondencyjny.ulica} /></RowSet>
                <RowSet caption="nr:"><Input value={crow.adresKorespondencyjny.nr} /></RowSet>
                <RowSet caption="Kod pocztowy:"><Input value={crow.adresKorespondencyjny.kod} /></RowSet>
                <RowSet caption="Poczta:"><Input value={crow.adresKorespondencyjny.poczta} /></RowSet>
              </Col>
            </Row>
          </Col>
        ) }
        </Row>
      </Container>
    );
  }
}

export default Kontrahenci;
