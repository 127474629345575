export function newNodeSum(){
  return {
    struktur: 0,
    punktow: 0,
    prac: 0,
    rh: 0,
    materials: {},
    materialNames: {},
    materialyList: []
  }
}