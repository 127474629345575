import { store } from 'react-easy-state';
 
const modalStore = store({
  modalWindows: []
})

export function addModal(component, props, onClose){
  modalStore.modalWindows.push({
    component: component,
    props: props,
    onClose: onClose
  })
}

export default modalStore;
