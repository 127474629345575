import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformPartFromJSON } from './partModel';

function addChildren(obj){
  obj.children = [];
  return obj;
}

export function savePart(id, parent, typ, cosiekID, ileplan){
  mainStore.bigLoader = true;
  return fetchJSON('/api/savePart/', {part: {
    id: id,
    parent: parent,
    typ: typ,
    oryginal: cosiekID,
    ileplan: ileplan
  }})
  .then((json) => {
    mainStore.bigLoader = false;

    if(id==-1){
      mainStore.selectedPraca.children.push(addChildren(transformPartFromJSON(json)));
    } else {
      const i = mainStore.selectedPraca.children.findIndex((e) => e.id == json.id);
      const no = addChildren(transformPartFromJSON(json));
      if(i>=0){
        mainStore.selectedPraca.children[i] = no;
      } else {
        mainStore.selectedPraca.children.push(no);
      }
      mainStore.selectedPart = no;
    }
    return json;
  })
  .catch(error => {
    mainStore.bigLoader = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}

export function deletePart(partID){
  mainStore.bigLoader = true;
  return fetchJSON('/api/deletePart/'+partID)
  .then((json) => {
    mainStore.bigLoader = false;
    mainStore.selectedPraca.children = mainStore.selectedPraca.children.filter((v) => v.id!=partID);
    if(mainStore.selectedPart.id == partID){
      mainStore.selectedPart = undefined;
    }
    return json;
  })
  .catch(error => {
    mainStore.bigLoader = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}