import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';
import { transformCosiekFromJSON } from './cosiekModel';
import { sortNazwas } from '../struct/getStructChildren';

export function applyCosieks(json){
  const cosieks = json.cosieks;
  for(let i = 0; i < cosieks.length; i++){
    transformCosiekFromJSON(cosieks[i]);
  }

  cosieks.sort(sortNazwas);

  mainStore.cosieks = cosieks;
}

export function getCosieks(){
  if(mainStore.cosieks.length > 0){
    return Promise.resolve();
  }
  mainStore.materialy_loading= true;
  mainStore.cosieks = [];
  return fetchJSON('/api/getAllCosiek/')
    .then((json) => {

    applyCosieks(json);
    mainStore.materialy_loading = false;
  })
  .catch(error => {
    mainStore.materialy_loading = false;
    mainStore.bigError = {
      message: error
    };
    console.log(error);
  });
}