import fetchJSON from 'util/fetchJSON';
import { transformStructFromJSON } from 'model/struct/structModel';
import { mainStore } from 'stores/store';
import { selectProject } from 'model/struct/selectProject';

export function loadBaseData(){
  if(mainStore.bigLoading) return;
  mainStore.bigLoading = true;
  return fetchJSON('/api/loadBaseData')
  .then((json) => {
    mainStore.baseData = {
      a: 'asd'
    }
    for(let i = 0; i < json.projects.length; i++){
      transformStructFromJSON(json.projects[i]);
    }

    mainStore.projects = json.projects;
    mainStore.loadedBaseData = true;
    mainStore.bigLoading = false;
  }).then(() => {
    if(mainStore.projects.length > 0){
      selectProject(mainStore.projects[0]);
    }
  }).catch(error => {
    mainStore.bigLoading = false;
    mainStore.bigError = {
      message: error
    };
  });
}

