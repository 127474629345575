import React, { Component } from 'react';
import { view } from 'react-easy-state';
import Button from 'components/Button/Button';
import { mainStore } from 'stores/store';

import "./LoginScreen.css"
import Label from 'components/Label/index';
import { Input } from 'components/Input';
import Container from '../../components/Container/Container';
import { Icon } from 'components/Icon';
import { verifyLogin } from '../../model/login/verifyLogin';

class LoginScreen extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: '',
      password: ''
    }
  }

  render() {
    return (
      <div className="login">
        <div className="login-panel">
          <Container panel>
            <div className="login-header">
              G·M·M·S
              <div className="login-subtext">Gensys Maintenance Management System</div>
            </div>
            <div className="login-body">
              <center>Proszę się zalogować</center>
              <Label>Użytkownik:</Label><Input value={this.state.user} onChange={(e) => this.setState({user: e.target.value})}/>
              <Label>Hasło:</Label><Input value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} onSubmit={() => { verifyLogin(this.state.user, this.state.password) }} password />
              { mainStore.login.message && 
                <h2><br />{typeof(mainStore.login.message) === 'string' ? mainStore.login.message : mainStore.login.message.toString()}</h2>
              }
            </div>
            <div className="login-footer">
              <Button primary onClick={() => { verifyLogin(this.state.user, this.state.password) } } ><Icon icon="fal fa-key"> Zaloguj</Icon></Button>
            </div>
            <div className="login-bg-cogs">
              <i className="fas fa-cog fa-spin"></i>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default view(LoginScreen);
