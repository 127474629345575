import { collateObject } from "util/collation";

import partCollation from 'model/part.js'

export function transformPartFromJSON(o, fieldFilter) {
  return collateObject(o, partCollation, fieldFilter);      
}

export function sortPart(sa, sb){
  return sa.nazwas.localeCompare(sb.nazwas, 'pl', {sensitivity: 'accent'});
}