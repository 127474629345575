import fetchJSON from 'util/fetchJSON';
import { mainStore } from 'stores/store';

export function getFullTree(punktIDs, pracaIDs, partIDs){
  return fetchJSON('/api/getFullTree/', { 
    punktIDs: punktIDs,
    pracaIDs: pracaIDs,
    partIDs: partIDs
  } ).then((json) => {
    return json;
  })
  .catch(error => {
    mainStore.bigError = {
      message: error
    };
    console.log(error);
    throw error;
  });
}