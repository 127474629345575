import React, { Component } from 'react';
import Icon from 'components/Icon';
import TreeChevron from './TreeChevron';
import { view } from 'react-easy-state';

class TreeViewNode extends Component {
  nodeClick = () => {
    if(this.props.settings.nodeClick) {
      this.props.settings.nodeClick(this.props.node)
    }
  }

  iconClick = () => { 
    if(this.props.settings.iconClick) {
      this.props.settings.iconClick(this.props.node)
    }
  }

  flipState = () => {
    if(this.props.settings.nodeExpandClick) {
      const node = this.props.settings.nodeProcessor ? this.props.settings.nodeProcessor(this.props.node) : this.props.node;
      this.props.settings.nodeExpandClick(node.id);
    }
    this.forceUpdate();
  }

  onContextMenu = (e) => {
    if(this.props.settings.rightClick) {
      this.props.settings.rightClick(e, this.props.node)
    }
    e.preventDefault();
    return false;
  }

  render() {
    const node = this.props.settings.nodeProcessor ? this.props.settings.nodeProcessor(this.props.node) : this.props.node;
    this._nodeId = node.id;
    const open = this.props.settings.openNodeMap[node.id] === undefined ? this.props.settings.defaultExpand : this.props.settings.openNodeMap[node.id];
    return (
      <li key={node.id}>
        {(node.children) && (node.children.length > 0) && <TreeChevron open={open} onClick={this.flipState} /> }
        { this.props.iconClick ? 
          <Icon onContextMenu={this.onContextMenu} onContentClick={this.nodeClick} onIconClick={this.iconClick} className={(this.props.settings.selected === node.id) ? "selected clickable" : "not-selected clickable"} icon={node.icon} color={node.iconColor}> {node.caption}</Icon>
          :
          <Icon onContextMenu={this.onContextMenu} onContentClick={this.nodeClick} className={(this.props.settings.selected === node.id) ? "selected clickable" : "not-selected clickable"} icon={node.icon} color={node.iconColor}> {node.caption}</Icon>
        }
        {open && (node.children) && (node.children.length > 0) &&  (
          <ul>
            {node.children.map( (node) => (
              <TreeViewNode node={node} key={node.id} settings={this.props.settings}/>
            ))}
          </ul>
        )}
      </li>
    )
  }
}

export default view(TreeViewNode);