import Container from 'components/Container';
import React, { Component } from 'react';
import { Row, Col } from 'components/Grid';
import { Table } from 'components/Table';
import { cosiekTable } from 'model/tables/cosieks';
import { mainStore } from 'stores/store';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { RowSet } from 'components/Grid';
import { Form, FormInput, FormTextArea } from 'components/Form';
import { getCosieks } from '../../../model/cosiek/getCosieks';
import { updateCosiek } from '../../../model/cosiek/updateCosiek';
import { view } from 'react-easy-state';
import { Input } from 'components/Input/index';
import FormSelect from '../../../components/Form/FormSelect';
import SmartInput from '../../../components/Input/SmartInput';
import { addCosiek } from '../../../model/cosiek/addCosiek';

class Towary extends Component {

  state = {
    currentRow: undefined,
    originalRow: undefined,
    filteredCosieks:[],
    filter: ''
  }

  constructor(props) {
    super(props);
    mainStore.bigLoading = true;
    getCosieks().then(() => {
      mainStore.bigLoading = false;
      this.filterCosieks();
    });
  }

  selectRow = (row) => {
    this.setState({ originalRow: row, currentRow: { ...row } })
  }

  resetRow = () => {
    this.setState({ originalRow: this.state.originalRow, currentRow: { ...this.state.originalRow } })
  }

  saveRow = () => {
    if(this.state.originalRow === undefined){
      addCosiek(this.state.currentRow);
    } else {
      updateCosiek(this.state.originalRow, this.state.currentRow);
    }
  }

  addNew = () => {
    const newRow = {};
    this.setState({
      originalRow: undefined,
      currentRow: newRow
    })
  }

  filterCosieks = (newValue) => {
    if(newValue === undefined) {
      newValue = this.state.filter;
    }

    newValue = newValue.toLowerCase();

    let fc;

    if(newValue){
      fc = mainStore.cosieks.filter((row) => row.nazwas.toLowerCase().includes(newValue)).slice(0,64);
    } else {
      fc = mainStore.cosieks.slice(0,64);
    }

    this.setState({
      filter: newValue,
      filteredCosieks: fc
    });
  
  }


  render() {
    return (
      <Row>
        <Col span="4">
          <Container panel vfull vscrolled>
            <h1>Towary</h1>
            <Row>
              <Col span="12">
                <Input value={this.state.filter} onChange={(newValue) => this.filterCosieks(newValue.target.value) } placeholder="Wyszukaj"/><br />
                <Table cols={cosiekTable} data={this.state.filteredCosieks}  onClick={this.selectRow} selectedID={this.state.currentRow ? this.state.currentRow.id : -1 }/>
                <Button green onClick={this.addNew}><Icon icon="fa fa-plus" /></Button>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col span="8">
          <Container panel vfull vscrolled>
            <Row>
              {this.state.currentRow && (
                <Form value={this.state.currentRow}>
                  <Col span="12">
                    <Button green onClick={this.saveRow}><Icon icon="fa fa-save" /></Button>
                    <Button tetriary onClick={this.resetRow}><Icon icon="fa fa-undo" /></Button>

                    <RowSet caption="Nazwa Pełna:"><FormInput field={["nazwa"]} /></RowSet>
                    <RowSet caption="Skrót nazwy:"><FormInput field={["nazwas"]} /></RowSet>
                    <RowSet caption="Model/Oznacz.:"><FormInput field={["model"]} /></RowSet>
                    <RowSet caption="PKWiU:"><FormInput field={["pkwiu"]} /></RowSet>

                    <RowSet caption="Grupa:"><FormInput field={["grupa"]} /></RowSet>
                    <RowSet caption="Kod CN (rodzaj):"> <FormInput field={["rodzaj"]} /></RowSet>
                    <RowSet caption="Oznaczenie wg. norm(grewid):"> <FormInput field={["grewid"]} /></RowSet>
                    <RowSet caption="Grupa rozliczeniowa(podgrupa):"> <FormInput field={["podgrupa"]} /></RowSet>

                    <RowSet caption="Gęstość(masa):"> <FormInput field={["masa"]} /></RowSet>
                    <RowSet caption="Pojemność:"> <FormInput field={["volume"]} /></RowSet>
                    <RowSet caption="Jednostka:"> <FormSelect options={[9, 8, 2916]} optionLabels={['kg', 'L', 'szt']} field={["volumetyp"]} /></RowSet>

                    <RowSet caption="Uwagi:"> <FormTextArea field={["uwagi"]} /></RowSet>
                    <Button><Icon icon="fa fa-print" /> Drukuj kartę materiału (bez cen) </Button>
                    <Button><Icon icon="fa fa-print" /> Drukuj kartę materiału (z cenami) </Button>
                  </Col>
                </Form>
              )}
            </Row>
          </Container>
        </Col>
      </Row>
    );
  }
}

export default view(Towary);