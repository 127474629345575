import React from 'react';

import oneOf from 'util/oneOf';
import { Icon } from 'components/Icon';

export const firmyTable = [
  { caption: 'Nazwa', get: (row) => row.companyname ? <Icon icon="fa fa-industry"> {row.companyname}</Icon> : <Icon icon="fa fa-user"> {row.surname + ' ' + row.name}</Icon>, align: 'left', maximize: true },
  { caption: 'NIP', get: (row) => row.nip, align: 'right', mono: true },
]

let iid = 0;
function newFirma() {
  iid++;
  let cname = false;
  if(Math.random() > 0.5){
    cname = oneOf(['Anga', 'Chemtech', 'EFAR', 'Flexible']) + ' ' + 
      oneOf(['Industries', 'Factory', 'Business', 'Technologies', 'LTD']) + ' ' +
      oneOf(['inc.', 'project', '', '', 'boring'])
  }
  return {
    id: iid,
    companyName: cname,
    surname: oneOf(['Braun', 'Musk', 'Branderson', 'Gates', 'Peterson', 'Wagner', 'Bush', 'Clinton']),
    name: oneOf(['Adam', 'Celine', 'Delia', 'Eliza', 'Anna', 'Brock', 'Elon', 'Robert']),
    nip: '123-123-12-12',
    regon: '10981230-231',
    nrKont: ["123123123123123123123123123123123","123123123123123123123123123123123","123123123123123123123123123123123"],
    dniNaFakture: 24,
    adres: {
      miasto: 'Rzeszów',
      ulica: 'Uliczna',
      nr: 12,
      kod: '33-100',
      poczta: 'Tarnów'
    },
    adresKorespondencyjny: {
      miasto: 'Kraków',
      ulica: 'Moczna',
      nr: 33,
      kod: '30-100',
      poczta: 'Krakó'
    },
    www: 'strona.pl',
    email: 'admin@strona.pl',
    telefon: '123-123-123',
    nrAkcyzowy: '12983hn123t',
    rodzajKontrahenta: 'rodzaj',
    uwagi: 'uwaga!'
  }
}

export const firmyData = []
for(let i = 0; i < 100; i++){
  firmyData.push(newFirma());
}
