import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import { BrowserRouter } from "react-router-dom";

//import registerServiceWorker from './registerServiceWorker';

//main styles:
import 'styles/reset.css';
import 'styles/fontAwesome/scss/fontawesome.css';
import 'styles/fontAwesome/scss/solid.css';
import 'styles/fontAwesome/scss/regular.css';
import 'styles/fontAwesome/scss/light.css';
import 'styles/fontAwesome/scss/brands.css';
import 'styles/ubuntuFont/stylesheet.css';
import 'styles/base.css';
import 'react-day-picker/lib/style.css';

import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault('Europe/Warsaw');

moment.locale('pl', {
  months : ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
  monthsShort : ['Sty', 'Luty', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
  monthsParseExact : true,
  weekdays : ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
  weekdaysShort : ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
  weekdaysMin : ['N', 'Pn', 'W', 'Ś', 'Cz', 'Pt', 'S'],
  weekdaysParseExact : true,
  longDateFormat : {
      LT : 'HH:mm',
      LTS : 'HH:mm:ss',
      L : 'YYYY-DD-MM',
      LL : 'YYYY MMMM D',
      LLL : 'D MMMM YYYY HH:mm',
      LLLL : 'dddd D MMMM YYYY HH:mm'
  },
  calendar : {
      sameDay : 'LT',
      nextDay : 'LT',
      nextWeek : 'dddd LT',
      lastDay : 'LT',
      lastWeek : 'dddd LT',
      sameElse : 'L'
  },
  relativeTime : {
      future : '%s',
      past : 'il y a %s',
      s : 'sekund',
      m : 'minuta',
      mm : '%d minut',
      h : 'godzina',
      hh : '%d godizn',
      d : 'dzień',
      dd : '%d dni',
      M : 'miesiąc',
      MM : '%d miesięcy',
      y : 'rok',
      yy : '%d lat'
  },
  dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
  ordinal : function (number) {
      return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse : /PD|MD/,
  isPM : function (input) {
      return input.charAt(0) === 'M';
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12  ;
  // },
  meridiem : function (hours, minutes, isLower) {
      return hours < 12 ? 'PD' : 'MD';
  },
  week : {
      dow : 1, // Monday is the first day of the week.
      doy : 4  // Used to determine first week of the year.
  }
});

const rootEl = document.getElementById('root');

//registerServiceWorker();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootEl
)

if (module.hot) {
  module.hot.accept('App', () => {
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      rootEl
    )
  })
}
